import { isSuccess } from "@devexperts/remote-data-ts";
import React from "react";
import { Button } from "react-bootstrap";
import { NcaishCorporateApiService } from "../../../api/corporate/api/ncaishCorporateApi.service";
import { CorporateCardWalletResponse } from "../../../api/corporate/model/corporateCardWalletResponse";
import SpinnerComponent from "../../common/loader/spinner";
import WhiteCard from "../../white-card/white-card";
import BalanceAmount from "./balance-amount";

const DetaxCardServiceDetails: React.FC<{
  walletInfo?: CorporateCardWalletResponse;
  cid: string;
}> = ({ walletInfo, cid }) => {
  const [wallet, setWallet] = React.useState(walletInfo);
  const [isLoading, setLoader] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const handleActivate = async () => {
    if (cid) {
      setLoader(true);
      setError("");
      const result = await NcaishCorporateApiService.activateWallet(cid);
      console.log("Activate now: ", result);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setLoader(true);
          setWallet(result.value);
        } else {
          setLoader(false);
          setError(result.value.message || "");
        }
      } else {
        setLoader(false);
        setError("Error");
      }
    }
  };

  if (!wallet) {
    return null;
  }

  return (
    <WhiteCard className="p-3">
      <div className="d-flex justify-content-between">
        <h6 className="text-secondary fw-bold">Detax Card Service Details</h6>
        <p></p>
      </div>

      <div className="row mx-1">
        <div className="col-12">
          <div className="row">
            <div className="col-3 col-md-3 ps-0 text-left  align-items-center">
              <p className="mb-0 ncash_fs-12 text-muted">Status</p>
              <p className="fs-small fw-bold mb-0">{wallet.status || "N/A"}</p>
            </div>
            <div className="col-3 col-md-3 ps-0 text-left align-items-center">
              <p className="mb-0 ncash_fs-12 text-muted">Account Id</p>
              <p className="fs-small fw-bold mb-0">
                {wallet.accountNumber || "N/A"}
              </p>
            </div>
            <div className="col-2 col-md-2 ps-0 text-left align-items-center">
              <p className="mb-0 ncash_fs-12 text-muted">Current Balance</p>
              <p className="fs-small fw-bold mb-0">
                {wallet.status !==
                  CorporateCardWalletResponse.StatusEnum.PENDING && (
                  <BalanceAmount cid={cid} />
                )}
              </p>
            </div>
            <div className="col-2 col-md-2 ps-0 text-left align-items-center">
              <p className="mb-0 ncash_fs-12 text-muted">Kit Number</p>
              <p className="fs-small fw-bold mb-0">
                {wallet.kitNumber || "N/A"}
              </p>
            </div>
            <div className="col-2 col-md-2 ps-0 text-left d-flex align-items-center">
              {wallet.status ===
                CorporateCardWalletResponse.StatusEnum.PENDING && (
                <>
                  <Button variant="success" onClick={() => handleActivate()}>
                    {isLoading ? <SpinnerComponent /> : <>Activate Now</>}
                  </Button>
                  {error && <div className="invalid-feedback">{error}</div>}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default DetaxCardServiceDetails;
