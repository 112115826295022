//@ts-ignore
import { ApiClient } from "./api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "./errors";
import { DashboardResponse } from "../model/dashboard/dashboardResponse";

type TQueryParam = {
    key: string;
    value: string | string[] | boolean | number | Date | undefined;
};

export namespace DashboardApiService {

    export const dashboardData = async (
        request?: any,
        customHeaders: AxiosRequestHeaders = {}
    ): Promise<RemoteData<APIError, DashboardResponse>> => {
        const path = `/api/secure/dashboard`.replace(/^\//, "");
        let headers = customHeaders;
        const axiosRequestConfig: AxiosRequestConfig = {
            url: path,
            method: "get",
            headers: { ...headers },
        };

        return await ApiClient.request(axiosRequestConfig);
    };

}