import FormikInputGroup from "../common/formik/formik-input-group/formik-input-group";

function Login() {
  return (
    <>
      <div className="mb-0">
        <FormikInputGroup
          icon="phone"
          type="text"
          name="mobile"
          maxLength={10}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your Mobile No"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}
export function LoginWithUsernameAndPassword() {
  return (
    <>
      <div className="mb-3">
        <FormikInputGroup
          icon="user"
          type="text"
          name="username"
          className="form-control input-field p-2 border-0"
          placeholder="Enter your username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="mb-0">
        <FormikInputGroup
          icon="lock"
          type="password"
          name="password"
          maxLength={50}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your Password"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}

export default Login;
