import { useEffect, useState } from "react";
import { fromPublic } from "../../../utils/common.utils";
import { LocalStorageItemKeys } from "../../../utils/local-storage.utils";
const NcashLogo = fromPublic("/images/icons/Brand-logo.svg");
const DashboardPageLoader = () => {
  const [sidebarWidth, setSidebarWidth] = useState(
    localStorage.getItem(LocalStorageItemKeys.dashSideBarWidth) || 102
  );
  useEffect(() => {
    if (document) {
      let el = document.querySelector(
        ".dashboard-sidebar-container .primary-gradient"
      );
      if (el) {
        setSidebarWidth(el.clientWidth);
        localStorage.setItem(
          LocalStorageItemKeys.dashSideBarWidth,
          el.clientWidth.toString()
        );
      }
    }
  });

  return (
    <div
      className="vh-100  d-flex justify-content-center align-items-center"
      style={{
        position: "fixed",
        zIndex: "99999999999",
        background: "#fff",
        top: "0",
        bottom: "0",
        right: "0",
        width: "calc(100% - " + sidebarWidth + "px)",
      }}
    >
      <div
        className="spinner-border"
        style={{ width: "8rem", height: "8rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default DashboardPageLoader;
