import classNames from "classnames";
import { Option, fromNullable, isNone, isSome } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { CorporateUser } from "../../../../api/admin/model/corporateUser";
import { unWrapOption } from "../../../../utils/option.utils";
import ReactSwitch from "../../../common/switch/react-switch";
import WhiteCard from "../../../white-card/white-card";

interface BtnState {
  updateClaimBtnState: boolean;
  updateBusinessBtnState: boolean;
}

interface props {
  setDefaultApprover: (data: Option<CorporateUser>) => void;
  setAdminMaker: (data: Option<CorporateUser>) => void;
  setCorporateAdmin: (data: Option<CorporateUser>) => void;
  setBusinessUnit: (data: string[]) => void;
  businessUnit: string[];
  setDefaultApproverList: (defaultApproverList: Array<CorporateUser>) => void;
  setAdminMakerList: (adminMakerList: Array<CorporateUser>) => void;
  setCorporateAdminList: (corporateAdminList: Array<CorporateUser>) => void;
  setShowUpdateClaimFlowPopup: () => void;
  setShowUpdateBusinessUnitPopup: () => void;
  defaultApproverList: Array<CorporateUser>;
  adminMakerList: Array<CorporateUser>;
  corporateAdminList: Array<CorporateUser>;
  defaultApprover: Option<CorporateUser>;
  adminMaker: Option<CorporateUser>;
  corporateAdmin: Option<CorporateUser>;
  isNewClaimFlow: boolean;
  setNewClaimFlow: (isNewClaimFlow: boolean) => void;
  optedNewClaimFlow: Option<boolean>;
  claimsUserInfo: Option<CorporateUser[]>;
  defaultBUCount: number;
}

const PostApprovalClaimSelection: React.FC<props> = ({
  setAdminMaker,
  setCorporateAdmin,
  setBusinessUnit,
  setDefaultApprover,
  setDefaultApproverList,
  setAdminMakerList,
  setCorporateAdminList,
  businessUnit,
  defaultApproverList,
  adminMakerList,
  corporateAdminList,
  defaultApprover,
  adminMaker,
  corporateAdmin,
  isNewClaimFlow,
  setNewClaimFlow,
  optedNewClaimFlow,
  setShowUpdateBusinessUnitPopup,
  setShowUpdateClaimFlowPopup,
  claimsUserInfo,
  defaultBUCount,
}) => {
  const [name, setName] = useState<string>("");

  const [buttonEnable, setButtonEnable] = useState<BtnState>({
    updateClaimBtnState: false,
    updateBusinessBtnState: false,
  });
  useEffect(() => {
    let state =
      isSome(adminMaker) && isSome(defaultApprover) && isSome(corporateAdmin);
    let btnState: BtnState = { ...buttonEnable, updateClaimBtnState: state };
    setButtonEnable((perv) => btnState);
  }, [adminMaker, defaultApprover, isNewClaimFlow, corporateAdmin]);

  useEffect(() => {
    let state = (businessUnit ?? []).length > defaultBUCount;
    let btnState: BtnState = { ...buttonEnable, updateBusinessBtnState: state };
    setButtonEnable((perv) => btnState);
  }, [businessUnit]);

  const handleDefaultApprover = (value: string) => {
    const selectedMobile = value;
    const selectedObj = defaultApproverList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      setDefaultApprover(
        fromNullable({ ...selectedObj, role: CorporateUser.RoleEnum.APPROVER })
      );
    }
  };
  const handleAdminMaker = (value: string) => {
    const selectedMobile = value;
    const selectedObj = adminMakerList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      const filteredCorporateAdminList = defaultApproverList.filter(
        (corpAdmin) => corpAdmin.mobile != selectedObj.mobile
      );
      setCorporateAdminList(filteredCorporateAdminList);
      setAdminMaker(
        fromNullable({
          ...selectedObj,
          role: CorporateUser.RoleEnum.CORPORATE_ADMIN_MAKER,
        })
      );
    }
  };
  const handleCorporateAdmin = (value: string) => {
    const selectedMobile = value;
    const selectedObj = corporateAdminList.find(
      (obj) => obj.mobile === selectedMobile
    );

    if (selectedObj) {
      const filteredAdminMakerList = defaultApproverList.filter(
        (adminMaker) => adminMaker.mobile != selectedObj.mobile
      );
      setAdminMakerList(filteredAdminMakerList);
      setCorporateAdmin(
        fromNullable({
          ...selectedObj,
          role: CorporateUser.RoleEnum.CORPORATE_ADMIN,
        })
      );
    }

    handleDefaultApprover(value);
  };

  const handleAdd = (): void => {
    if (name !== "") {
      const val = name.trim();
      setBusinessUnit([...businessUnit, val]);
      setName("");
    }
  };

  const handleDelete = (index: number): void => {
    const newData = [...businessUnit];
    newData.splice(index, 1);
    setBusinessUnit(newData);
  };

  const setClaimApproverName = (role?: CorporateUser.RoleEnum): string => {
    switch (role) {
      case "ROLE_APPROVER":
        return "Default Approver";
      case "ROLE_CORPORATE_ADMIN":
        return "Corporate Admin";
      case "ROLE_CORPORATE_ADMIN_MAKER":
        return "Corporate Admin Maker";
      default:
        return "";
    }
  };

  if (isNone(optedNewClaimFlow)) {
    return (
      <>
        <p>something went wrong, couldn't show the claim.. please refresh!!</p>
      </>
    );
  }

  return (
    <>
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">Set Business Units</h6>
          <p></p>
        </div>
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <span>
                <p className="d-inline fs-small fw-bold">
                  Enter the Business Unit name
                </p>
              </span>
              <span className="ms-2">
                <input
                  placeholder="Enter Business Unit"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAdd();
                    }
                  }}
                />
                <button
                  type="submit"
                  className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary ms-2"
                  onClick={handleAdd}
                >
                  Add
                </button>

                <div className="mt-2">
                  {businessUnit.map((item: string, index: number) => (
                    <WhiteCard className="mb-1 p-1 w-25 rounded">
                      <div
                        key={index}
                        className="d-flex flex-row justify-content-between ps-2"
                      >
                        {index + 1}. {item}{" "}
                        {index >= defaultBUCount && (
                          <div
                            onClick={() => handleDelete(index)}
                            className="pe-2 pe-auto"
                          >
                            <img src="/images/icons/Close.svg" />
                          </div>
                        )}
                      </div>
                    </WhiteCard>
                  ))}
                </div>
              </span>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col d-flex justify-content-end">
              <button
                disabled={!buttonEnable.updateBusinessBtnState}
                onClick={(e) => setShowUpdateBusinessUnitPopup()}
                className={`btn border-0 px-4 text-white  ms-2 ${classNames({
                  ["bg-success"]: buttonEnable.updateBusinessBtnState,
                  ["bg-secondary"]: !buttonEnable.updateBusinessBtnState,
                })}`}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </WhiteCard>

      {isSome(optedNewClaimFlow) && optedNewClaimFlow.value === false && (
        <>
          {/* <WhiteCard className="p-3">
            <div className="d-flex justify-content-between">
              <h6 className="text-secondary fw-bold">Set Business Units</h6>
              <p></p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <span>
                    <p className="d-inline fs-small fw-bold">
                      Enter the Business Unit name
                    </p>
                  </span>
                  <span className="ms-2">
                    <input
                      placeholder="Enter Business Unit"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAdd();
                        }
                      }}
                    />
                    <button
                      type="submit"
                      className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary ms-2"
                      onClick={handleAdd}
                    >
                      Add
                    </button>

                    <div className="mt-2">
                      {businessUnit.map((item: string, index: number) => (
                        <WhiteCard className="mb-1 p-1 w-25 rounded">
                          <div
                            key={index}
                            className="d-flex flex-row justify-content-between ps-2"
                          >
                            {index + 1}. {item}{" "}
                            <div
                              onClick={() => handleDelete(index)}
                              className="pe-2 pe-auto"
                            >
                              <img src="/images/icons/Close.svg" />
                            </div>
                          </div>
                        </WhiteCard>
                      ))}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </WhiteCard> */}
          <WhiteCard className="p-3">
            <div className="d-flex justify-content-between">
              <h6 className="text-secondary fw-bold">Set Claims Flow</h6>
              <p></p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col d-flex align-items-center">
                  <span>
                    <p className="d-inline fs-small fw-bold">Old Claim Flow</p>
                  </span>
                  <span className="ms-2 me-2">
                    <ReactSwitch
                      switchState={isNewClaimFlow}
                      handleSwitch={(data: boolean) => {
                        setNewClaimFlow(data);
                      }}
                    />
                  </span>
                  <span>
                    <p className="d-inline fs-small fw-bold">New Claim Flow</p>
                  </span>
                </div>
              </div>
            </div>
            {isNewClaimFlow && (
              <div className="container">
                <div className="row mt-2">
                  <h6 className="text-danger fw-bold">
                    Please ensure that department is updated for all the
                    existing employees.
                  </h6>
                </div>
              </div>
            )}
          </WhiteCard>
          {/* {!isNewClaimFlow && (
            <div className="container mt-3 mb-3">
              <div className="row">
                <div className="col d-flex justify-content-end">
                  <button
                    disabled={!buttonEnable}
                    onClick={(e) => setShowUpdatePopup()}
                    className={`btn border-0 px-4 text-white  ms-2 ${classNames(
                      {
                        ["bg-success"]: buttonEnable,
                        ["bg-secondary"]: !buttonEnable,
                      }
                    )}`}
                  >
                    Update Business Unit
                  </button>
                </div>
              </div>
            </div>
          )} */}
          {isNewClaimFlow && (
            <>
              <WhiteCard className="p-3">
                <div className="d-flex justify-content-between">
                  <h6 className="text-secondary fw-bold">Select User</h6>
                  <p></p>
                </div>
                <div className="container">
                  <div className="row mb-4">
                    <div className="col">
                      <span>
                        <p className="d-inline fs-small fw-bold">
                          Select Corporate Admin Maker
                        </p>
                      </span>
                      <span className="ms-2">
                        <select
                          defaultValue={
                            isSome(adminMaker) ? adminMaker.value.name : ""
                          }
                          onChange={(e) => handleAdminMaker(e.target.value)}
                          style={cardStyle.txtStyle1}
                        >
                          <option
                            value={
                              isSome(adminMaker) ? adminMaker.value.name : ""
                            }
                            disabled
                            hidden
                          >
                            {/* {isSome(defaultApprover)
                        ? defaultApprover.value.name
                        : "Select Approver"} */}
                            {
                              unWrapOption(adminMaker, () => {
                                return { name: "Select Admin Maker" };
                              }).name
                            }
                          </option>
                          {adminMakerList.map((item) => (
                            <option value={item?.mobile} key={item?.mobile}>
                              {item?.name ?? "No Name"}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <span className="me-5">
                        <p className="d-inline fs-small fw-bold">
                          Select Corporate Admin
                        </p>
                      </span>
                      <span className="ms-2">
                        <select
                          defaultValue={""}
                          onChange={(e) => handleCorporateAdmin(e.target.value)}
                          style={cardStyle.txtStyle1}
                        >
                          <option
                            value={
                              isSome(corporateAdmin)
                                ? corporateAdmin.value.name
                                : ""
                            }
                            disabled
                            hidden
                          >
                            {
                              unWrapOption(corporateAdmin, () => {
                                return { name: "Select Corporate Admin" };
                              }).name
                            }
                          </option>
                          {corporateAdminList.map((item) => (
                            <option value={item?.mobile} key={item?.mobile}>
                              {item?.name ?? "No Name"}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>

                  {isSome(defaultApprover) && (
                    <div className="row mb-4">
                      <div className="col">
                        <span className="me-5">
                          <p className="d-inline fs-small fw-bold">
                            Default Approver
                          </p>
                        </span>
                        <span className="ms-2">
                          <p className="d-inline fs-small fw-bold">
                            {defaultApprover.value?.name}
                          </p>
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col d-flex justify-content-end">
                      <button
                        disabled={!buttonEnable.updateClaimBtnState}
                        onClick={(e) => setShowUpdateClaimFlowPopup()}
                        className={`btn border-0 px-4 text-white  ms-2 ${classNames(
                          {
                            ["bg-success"]: buttonEnable.updateClaimBtnState,
                            ["bg-secondary"]: !buttonEnable.updateClaimBtnState,
                          }
                        )}`}
                      >
                        Update Users
                      </button>
                    </div>
                  </div>
                </div>
              </WhiteCard>
            </>
          )}
        </>
      )}

      {isSome(optedNewClaimFlow) && optedNewClaimFlow.value === true && (
        <WhiteCard className="p-3">
          <div className="d-flex justify-content-between">
            <h6 className="text-secondary fw-bold">Claim Flow</h6>
            <p></p>
          </div>
          <div className="container">
            <div className="row mb-3">
              <span>
                <p className="d-inline fs-small fw-bold">
                  Opted for New Claim Flow
                </p>
              </span>
            </div>
            {isSome(claimsUserInfo) &&
              claimsUserInfo.value.map((item, index) => (
                <div key={index} className="row mb-3">
                  <div className="col-3">
                    <span>
                      <p className="d-inline fs-small fw-bold text-wrap">
                        {setClaimApproverName(item.role)}
                      </p>
                    </span>
                  </div>
                  <div className="col">
                    <span>
                      <p className="d-inline fs-medium fw-bold text-wrap">
                        {item.name}
                      </p>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </WhiteCard>
      )}

      {/* {(<>
        <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
              <h6 className="text-secondary fw-bold">Selected User</h6>
              <p></p>
            </div>
            <div className="container">
            
                    <div className="row">
                    <div className="col-3">
                      <span>
                        <p className="d-inline fs-small fw-bold text-wrap">
                          Corporate Admin Maker
                        </p>
                      </span>

                    </div>
                    <div className="col">
                      
                      <span>
                      <p className="d-inline fs-medium fw-bold text-wrap">
                          testing 
                        </p>
                      </span>
                    </div>
                    </div>
            </div>
          </WhiteCard>
      </>)} */}
    </>
  );
};

const cardStyle = {
  textAreaContainer: {
    height: 200,
    width: 460,
  },
  txtStyle1: {
    width: 300,
    height: 40,
    borderRadius: 3,
  },
};

export default PostApprovalClaimSelection;
