import classNames from "classnames";
import React from "react";
import { CampaignTabKind, MTab, tabData } from "./campaign.utils";

type TabProps = {
  tabs?: MTab[];
  activeTab: CampaignTabKind;
  setActiveTab: (tabType: CampaignTabKind) => void;
};

const CampaignTab: React.FC<TabProps> = ({
  tabs = tabData,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div id="exTab2" className="p-0 border-bottom mb-3">
        <ul className="nav">
          {tabs.map((tab, index) => {
            return (
              <li className="nav-item cursor-pointer" key={index}>
                <a
                  className={classNames(
                    "nav-link text-secondary fw-bold fs-6 ",
                    {
                      active: tab.tabValue === activeTab,
                    }
                  )}
                  data-toggle="tab"
                  onClick={() => {
                    setActiveTab(tab.tabValue);
                  }}
                >
                  {tab.tabLabel}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CampaignTab;
