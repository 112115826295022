import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable } from "fp-ts/lib/Option";
import { NCaishCaptureLeadApiService } from "../api/lead/api/nCaishCaptureLeadApi.service";
import { CreateLeadApiRequest } from "../api/lead/model/createLeadApiRequest";
import { LeadApiInfo } from "../api/lead/model/leadApiInfo";
import { NcaishMyLeadApiResponse } from "../api/lead/model/ncaishMyLeadApiResponse";
import { NcaishRegisterLeadApiResponse } from "../api/lead/model/ncaishRegisterLeadApiResponse";
import { UserVerificationResponse } from "../api/lead/model/userVerificationResponse";
import { VerifyLeadRequest } from "../api/lead/model/verifyLeadRequest";
import { TLeadApiInfo } from "../types/lead/lead-api-info.type";
import { ConvertToRoutePath } from "../utils/route.utils";

export namespace LeadService {
  export const registerLead = async (req: Required<CreateLeadApiRequest>) => {
    const result = await NCaishCaptureLeadApiService.registerLead({ ...req });
    return remoteData.map(result, (response: NcaishRegisterLeadApiResponse) => {
      return {
        nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
        message: fromNullable(response.message),
        successful: fromNullable(response.successful),
        verificationId: fromNullable(response.verificationId),
        data: fromNullable(response.data),
      };
    });
  };

  export const verifySignup = async (
    body: Required<VerifyLeadRequest>,
    leadId: string
  ) => {
    const result = await NCaishCaptureLeadApiService.verifyLead(
      { ...body },
      leadId
    );
    return remoteData.map(result, (response: UserVerificationResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      status: fromNullable(response.status),
      accessToken: fromNullable(response.accessToken),
      refreshToken: fromNullable(response.refreshToken),
      data: fromNullable(response.data),
      scope: fromNullable(response.scope),
      expiry: fromNullable(response.expiry),
      nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
    }));
  };

  export const getLeadReview = async () => {
    const result = await NCaishCaptureLeadApiService.reviewLead();
    return remoteData.map(result, (result: NcaishMyLeadApiResponse) => ({
      successful: fromNullable(result.successful),
      message: fromNullable(result.message),
      data: fromNullable(
        result.data
          ? parseLeadApiInfo(result.data)
          : {
              leadId: "",
              companyName: "",
              applicantInfo: {
                name: "",
                email: "",
                mobile: "",
                verification: { mobile: false, email: false, aadhar: false },
              },
              remarks: "",
              trialStart: "",
              trialEnd: "",
              status: "",
            }
      ),
    }));
  };

  const parseLeadApiInfo = (response: LeadApiInfo): TLeadApiInfo => ({
    leadId: response.leadId || "",
    companyName: response.companyName || "",
    applicantInfo: {
      name: response.applicantInfo?.name || "",
      email: response.applicantInfo?.email || "",
      mobile: response.applicantInfo?.mobile || "",
      verification: {
        mobile: response.applicantInfo?.verification?.mobile || false,
        email: response.applicantInfo?.verification?.email || false,
        aadhar: response.applicantInfo?.verification?.aadhar || false,
      },
    },
    remarks: response.remarks || "",
    trialStart: response.trialStart || "",
    trialEnd: response.trialEnd || "",
    status: response.status || "",
  });
}
