import classNames from "classnames";
import {
  TOnBoardingApplicationCountInfo,
  TOnBoardStateEnum,
} from "../../../types/admin/onboarding-application-count.type";
import { unWrapOption } from "../../../utils/option.utils";
import WhiteCard from "../../white-card/white-card";
import "../card-count.scss";

const OnboardStatus: React.FC<{
  onSelectState: (state: string) => void;
  counts?: TOnBoardingApplicationCountInfo[];
  selectedState: string;
}> = ({ onSelectState, counts = [], selectedState = "ALL" }) => {
  return (
    <div className="card-counts mt-4">
      {counts.map((item, key) => (
        <div
          key={key}
          onClick={() => onSelectState(unWrapOption(item.state, () => ""))}
          className="card-count"
        >
          <WhiteCard
            className={classNames("p-2 w-75 status-dot-card", {
              color0:
                unWrapOption(item.state, () => "") == "ALL" ||
                unWrapOption(item.state, () => "") == "",
              color1: unWrapOption(item.state, () => "") == "CIN_VERIFIED",
              color2: unWrapOption(item.state, () => "") == "SIGNUP_COMPLETED",
              color3: unWrapOption(item.state, () => "") == "SIGNATORY_UPDATED",
              color4:
                unWrapOption(item.state, () => "") ==
                "SERVICE_SELECTION_COMPLETED",
              color7: unWrapOption(item.state, () => "") == "LIVE",
              color5: unWrapOption(item.state, () => "") == "BR_SENT",
              color9: unWrapOption(item.state, () => "") == "REVIEW_PENDING",
              color6: unWrapOption(item.state, () => "") == "REJECTED",
              color8: unWrapOption(item.state, () => "") == "EXPIRED",
              active: selectedState == unWrapOption(item.state, () => ""),
            })}
          >
            <div className="status-dot"></div>

            <h2 className="text-black text-center mb-0 fw-bold">
              {unWrapOption(item.count, () => 0)}
            </h2>
            <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
              {unWrapOption(item.label, () => "")}
            </p>
          </WhiteCard>
        </div>
      ))}
    </div>
  );
};

export default OnboardStatus;
