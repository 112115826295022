/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApplicantInfo } from "./applicantInfo";

export interface AdminApplicationBasicInfo {
  id?: string;
  appCode?: string;
  companyName?: string;
  cin?: string;
  applicantInfo?: ApplicantInfo;
  state?: AdminApplicationBasicInfo.StateEnum;
  createdAt?: string;
  updatedAt?: string;
}
export namespace AdminApplicationBasicInfo {
  export type StateEnum =
    | "CIN_VERIFIED"
    | "SIGNUP_COMPLETED"
    | "SIGNATORY_UPDATED"
    | "SERVICE_SELECTION_COMPLETED"
    | "BR_SENT"
    | "REVIEW_PENDING"
    | "LIVE"
    | "REJECTED"
    | "EXPIRED";
  export const StateEnum = {
    CINVERIFIED: "CIN_VERIFIED" as StateEnum,
    SIGNUPCOMPLETED: "SIGNUP_COMPLETED" as StateEnum,
    SIGNATORYUPDATED: "SIGNATORY_UPDATED" as StateEnum,
    SERVICESELECTIONCOMPLETED: "SERVICE_SELECTION_COMPLETED" as StateEnum,
    BRSENT: "BR_SENT" as StateEnum,
    REVIEWPENDING: "REVIEW_PENDING" as StateEnum,
    LIVE: "LIVE" as StateEnum,
    REJECTED: "REJECTED" as StateEnum,
    EXPIRED: "EXPIRED" as StateEnum,
  };
}
