import { isSuccess, RemoteData } from "@devexperts/remote-data-ts";
import { Option, isNone, isSome, none, fold } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { TDetaxBatchInfo } from "../../../../../models/batches.models";
import { BatchesService } from "../../../../../services/batches.service";
import { unWrapOption } from "../../../../../utils/option.utils";
import WhiteCard from "../../../../white-card/white-card";
import TableLoader from "../../common/table-loader";
import { useGetBatchDetailByBatchId } from "../hooks/transaction.hooks.";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../../state/slice/detax.slice";
import { useAppSelector } from "../../../../../hooks/hooks";
import {
  fetchBalance,
  fetchBatchDetail,
  fetchBatchNpsDetail,
} from "../txn.utils";
import {
  DetaxAdminBatchInfo,
  NpsBatchListInfo,
} from "../../../../../api/detax/model/detaxAdminBatchInfo";
import BalanceAmount from "../../../../admin/common/balance-amount";
import { Dispatch } from "../../../../../state/dispatch";
import ContributionTable from "./contribution-table";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const EmpCodeIcon = "/images/icons/emp_code.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";

const TransactionDetailCard: React.FC<{
  batchId: string;
  activeTab: string;
  setReportEnableBtn: (val: boolean) => void;
}> = ({ batchId, activeTab, setReportEnableBtn }) => {
  const batchInfoOpt: Option<DetaxAdminBatchInfo> = useAppSelector(
    DetaxReduxStore.selectBatchInfo
  );
  const batchNpsInfoOpt: Option<DetaxAdminBatchInfo> = useAppSelector(
    DetaxReduxStore.selectNpsBatchInfo
  );
  const [listData, setListData] = useState<NpsBatchListInfo[]>([]);
  useEffect(() => {
    if (activeTab === "NPS") {
      fetchBatchNpsDetail(batchId);
    } else {
      fetchBatchDetail(batchId);
    }

    // const run = async () => {
    //   const result = await BatchesService.getNpsBatchList(batchId);
    //   // console.log("batch Details ::: ", result);
    //   if (isSuccess(result)) {
    //     console.log("Test result", result);
    //     setListData(result.value?.data || []);
    //   } else {
    //     console.log("Error BatchDetailsByBatchId: ", result);
    //     // Dispatch.App.setCommonError({
    //     //   showError: true,
    //     //   errorText: "Something went wrong!",
    //     // });
    //   }
    // };
    // if (activeTab === "NPS") {
    //   run();
    // }
  }, [batchId]);

  useEffect(() => {
    if (isSome(batchInfoOpt)) {
      const corpInfo = batchInfoOpt.value.corpInfo;
      if (corpInfo) {
        //fetchBalance(corpInfo.cid || "");
      }
    }
    // if (isSome(batchNpsInfoOpt)) {
    //   const corpInfo = batchNpsInfoOpt.value.corpInfo;
    //   if (corpInfo) {
    //     //fetchBalance(corpInfo.cid || "");
    //   }
    // }
    //fetchBatchDetail(batchId);
  }, [batchInfoOpt, batchNpsInfoOpt]);

  const downloadReport = async (sheet: number, name: string) => {
    const result = await BatchesService.getNpsContributionReport(
      batchId,
      sheet
    );
    // console.log("batch Details ::: ", result);
    if (isSuccess(result)) {
      const url = window.URL.createObjectURL(new Blob([result.value]));

      const link = document.createElement("a");
      link.href = url;
      const fileName = `${name} ${+new Date()}.csv`; // whatever your file name .
      link.setAttribute("download", fileName);
      setReportEnableBtn(false);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      console.log("Error BatchDetailsByBatchId: ", result);
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
    }
  };

  if (activeTab !== "NPS" && isNone(batchInfoOpt)) {
    return <TableLoader />;
  }
  if (activeTab === "NPS" && isNone(batchNpsInfoOpt)) {
    return <TableLoader />;
  }

  return (
    <div>
      {pipe(
        activeTab === "NPS" ? batchNpsInfoOpt : batchInfoOpt,
        fold(
          () => <div>No details found</div>,
          (data) => (
            <>
              <WhiteCard className="p-3">
                <div className="row">
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Created By{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.createdBy ? data.createdBy.name || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Mobile No.{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.createdBy ? data.createdBy.mobile || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> E-mail </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.createdBy ? data.createdBy.email || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={CalendarIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Create Date{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {data.createdAt ? data.createdAt || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmpStatusIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Batch Code{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.batchCode ? data.batchCode || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmpDesgIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> Status </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.status ? data.status || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightred rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Number of employee{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data.txnCount ? data.txnCount || 0 : 0}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={process.env.PUBLIC_URL + "/salary.svg"}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> Amount </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          ₹ {data.amount ? data.amount || 0 : 0}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </WhiteCard>
              {data.paymentInfo && (
                <WhiteCard className="p-3">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-secondary fw-bold">Payment Info</h6>
                  </div>
                  <div className="row">
                    <div className=" col-md-2">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto mt-1">
                            <img
                              src={process.env.PUBLIC_URL + "/salary.svg"}
                              className="img-fluid ml-auto mr-auto w-100"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted"> Mode </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data.paymentInfo
                              ? data.paymentInfo.mode || ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-2">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto  mt-1">
                            <img
                              src={process.env.PUBLIC_URL + "/salary.svg"}
                              className="img-fluid ml-auto mr-auto w-100"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">Amount </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            ₹
                            {data.paymentInfo
                              ? data.paymentInfo.amount || 0
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-2">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-purple rounded-6_i text-center mx-auto my-auto mt-1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/calendar-solid.svg"
                              }
                              className="img-fluid ml-auto mr-auto w-100"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            {" "}
                            Paid At{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data.paymentInfo
                              ? data.paymentInfo.paidAt || ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-2">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto mt-1">
                            <img
                              src={EmpStatusIcon}
                              className="img-fluid ml-auto mr-auto w-100"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            {" "}
                            Reference ID{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {data.paymentInfo
                              ? data.paymentInfo.referenceId || ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className=" col-md-2">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={LocationIcon}
                              className="img-fluid ml-auto mr-auto w-100"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Balance </p>
                          <h5 className="font-14 fw-bold mb-0">
                            {data.paymentInfo
                              ? data.paymentInfo.referenceId || ""
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </WhiteCard>
              )}
              {data.corpInfo && (
                <WhiteCard className="p-3">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-secondary fw-bold">Corporate Info</h6>
                  </div>
                  <div className="row">
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={UserIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted"> Name </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data.corpInfo ? data.corpInfo.name || "" : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/pen_card.svg"
                              }
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            CIN Number{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data.corpInfo ? data.corpInfo.cin || 0 : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/calendar-solid.svg"
                              }
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            {" "}
                            Register At{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data.corpInfo
                              ? data.corpInfo.registedAt || ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={process.env.PUBLIC_URL + "/salary.svg"}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            {" "}
                            Balance{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            <BalanceAmount cid={data.corpInfo.cid || ""} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </WhiteCard>
              )}
              {activeTab === "NPS" && (
                <div>
                  <div className="d-flex col justify-content-between mb-2">
                    <div
                      style={{ fontSize: 18, fontWeight: "bold" }}
                      className="d-flex align-items-center"
                    >
                      Contribution Details
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary me-2"
                        onClick={() => {
                          downloadReport(1, "NPS Calculation");
                        }}
                      >
                        Download NPS Calculation
                      </button>
                      <button
                        type="submit"
                        className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary"
                        onClick={() => {
                          downloadReport(2, "NPS Fund Upload");
                        }}
                      >
                        Download NPS Fund Upload
                      </button>
                    </div>
                  </div>
                  <div>
                    <ContributionTable listData={listData} batchId={batchId} />
                  </div>
                </div>
              )}
            </>
          )
        )
      )}
    </div>
  );
};

export default TransactionDetailCard;
