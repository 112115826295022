import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  GetEmployeesDataEmployeesRes,
  GetMaxLimitData,
} from "../../../../api/admin/model/maxLimitResponse";
import { TAllAvailableCategories } from "../../../../models/onboarding.models";
import { OnboardingService } from "../../../../services/onboarding.service";
import { unWrapOption } from "../../../../utils/option.utils";
import SuccessPopup from "../../../pages/dashboard/common/succes-popup";
import WhiteCard from "../../../white-card/white-card";
import { OptionDrop } from "../onboard-details";

const SetUserCategory: React.FC<{
  listOfCat: TAllAvailableCategories[];
  cid: string;
  setProceed: (val: boolean) => void;
  proceed: boolean | null;
  employeeList: GetEmployeesDataEmployeesRes[];
}> = ({ listOfCat, cid, setProceed, proceed, employeeList }) => {
  const animatedComponents = makeAnimated();
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState(0);
  const [stateData, setStateData] = useState<GetMaxLimitData[]>([]);
  const [empDrop, setEmpDrop] = useState<OptionDrop[]>([]);
  const [catData, setCatData] = useState(
    listOfCat
      .filter((obj) => !obj.isEligibleToAdd)
      .map((obj) => ({
        ...obj,
        userCat: [
          {
            maxLimit: 0,
            type: { value: "", label: "" },
            employees: [
              {
                uid: "",
                empName: "",
                taxRegime: "",
              },
            ],
          },
        ],
      }))
  );

  const approvesoption = [
    { value: "specific", label: "for specific employees" },
    // { value: "BUSINESS_UNIT", label: "for the business unit" },
    { value: "all", label: "for the entire organization" },
  ];
  useEffect(() => {
    setEmpDrop(
      employeeList.map((obj) => ({ value: obj.uid, label: obj.empName }))
    );
  }, [employeeList]);

  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getUserCategoryData(
        cid,
        unWrapOption(catData[activeTab]?.fbaCategoryId, () => "")
      );
      if (isSuccess(result)) {
        if (result.value.successful) {
          setStateData(result.value.data);
          if (!proceed) {
            setProceed(result.value.specific);
          }
          const updatedUserCat = catData.map((cat) => {
            if (
              unWrapOption(cat.fbaCategoryId, () => "") ===
              unWrapOption(catData[activeTab]?.fbaCategoryId, () => "")
            ) {
              setValid([]);
              if (
                result.value.data[0].employees &&
                result.value.data[0].employees.length > 0
              ) {
                validUser(result.value.data[0].employees.map((obj) => obj.uid));
              }

              // If the category name matches the category being edited
              return {
                ...cat,
                userCat:
                  result.value.data.length > 0
                    ? result.value.data.map((item) => ({
                        ...item,
                        type: {
                          value: item.type,
                          label:
                            item.type === "all"
                              ? "for the entire organization"
                              : "for specific employees",
                        },
                        employees:
                          item.employees !== null
                            ? item.employees.filter((obj) =>
                                empDrop.map((val) => obj.uid === val.value)
                              )
                            : [{ empName: "", uid: "", taxRegime: "" }],

                        // Other properties from the item
                      }))
                    : [],
              };
            }
            return cat; // Return unchanged for other categories
          });

          setCatData(updatedUserCat);
        } else {
          alert(result.value.message);
        }
      } else {
        // setAppState(initialState);
      }
    };
    run();
  }, [cid, activeTab, successPopup.show]);

  const deleteUserCatData = async (type: string, body: any) => {
    // let reqBody: DeleteReqBody = { data: body };
    // const result = await OnboardingService.deleteMaxLimit(cid, type, reqBody);
    // if (isSuccess(result)) {
    //   setSuccessPopup({ show: true, message: result.value.message });
    // }
  };

  const createUserCategory = async (type: string, uid: Array<string>) => {
    const uidString = uid.join();
    const result = await OnboardingService.updateUserCategoryData(
      cid,
      unWrapOption(catData[activeTab].fbaCategoryId, () => ""),
      type,
      uidString
    );
    if (isSuccess(result)) {
      setSuccessPopup({ show: true, message: result.value.message });
    }
  };

  const [valid, setValid] = useState<GetEmployeesDataEmployeesRes[] | []>([]);

  const handleChange = (
    data: string,
    userCatIndex: number,
    name: string,
    value: any
  ) => {
    const updatedUserCat = catData.map((cat) => {
      if (unWrapOption(cat.name, () => "") === data) {
        if (name !== "employees") {
          return {
            ...cat,
            userCat: cat.userCat.map((userCat, index) => {
              if (index === userCatIndex) {
                return { ...userCat, [name]: value };
              }
              return userCat;
            }),
          };
        } else if (name === "employees") {
          return {
            ...cat,
            userCat: cat.userCat.map((userCat, index) => {
              if (index === userCatIndex) {
                return {
                  ...userCat,
                  [name]: value.map((obj: OptionDrop) => ({
                    uid: obj.value,
                    empName: obj.label,
                  })),
                };
              }

              return userCat;
            }),
          };
        }
      }
      return cat;
    });

    setCatData(updatedUserCat);
  };

  const handleAddUserCat = (data: string) => {
    const categoryToAdd = catData.filter(
      (cat) => unWrapOption(cat.name, () => "") === data
    );
    if (categoryToAdd) {
      const newUserCat = {
        maxLimit: 0,
        type: { value: "", label: "" },
        employees: [],
      };

      const updatedUserCat = [...catData];
      const index = updatedUserCat.findIndex(
        (cat) => unWrapOption(cat.name, () => "") === data
      );

      if (index !== -1) {
        if (updatedUserCat[index].userCat.length < 5) {
          updatedUserCat[index].userCat.push(newUserCat);
          setCatData(updatedUserCat);
        }
      }
    }
  };

  const handleDeleteUser = (data: string, userCatIndex: number) => {
    const indexToUpdate = catData.findIndex(
      (cat) => unWrapOption(cat.name, () => "") === data
    );

    if (indexToUpdate !== -1) {
      const updatedUserCat = [...catData];
      const userCatArray = updatedUserCat[indexToUpdate].userCat;

      if (userCatArray.length > 1) {
        userCatArray.splice(userCatIndex, 1);
        setCatData(updatedUserCat);
      }
    }
  };

  const validUser = (uids: Array<string>) => {
    setValid([]);
    const isValid = employeeList.filter(
      (obj) => obj.taxRegime === "NEW_REGIME"
    );

    const cat = listOfCat.find(
      (obj) =>
        unWrapOption(obj.fbaCategoryId, () => "") ===
        unWrapOption(catData[activeTab].fbaCategoryId, () => "")
    );

    if (!cat) {
      alert("No category found");
      return [];
    } // Category not found

    const data = unWrapOption(cat.newRegime, () => false);

    if (!data) {
      setValid(
        isValid.filter((obj) => {
          return uids.includes(obj.uid);
        })
      );
    }
  };

  return (
    <div style={{ display: proceed === true ? undefined : "none" }}>
      <SuccessPopup
        message={successPopup.message}
        show={successPopup.show}
        onClose={(flag) => setSuccessPopup({ show: false, message: "" })}
      />
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">
            Customize Category at Employee Level
          </h6>
          <p></p>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <>
            <div style={{ width: "17%", border: "1px solid #E2E2E2" }}>
              {catData &&
                catData.map((obj, index) => (
                  <div
                    style={{
                      paddingTop: 36.5,
                      paddingBottom: 36.5,
                      paddingLeft: 15,
                      borderBottom: "1px solid #E2E2E2",
                      cursor: "pointer",
                      borderRight:
                        activeTab === index ? "5px solid #157FE1" : 0,
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setActiveTab(index);
                    }}
                  >
                    {unWrapOption(obj.name, () => "")}
                  </div>
                ))}
            </div>
            <div style={{ width: "83%", border: "1px solid #E2E2E2" }}>
              <>
                {catData[activeTab]?.userCat &&
                catData[activeTab].userCat.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <p>No max limit is set for this category</p>
                    <button
                      onClick={() => {
                        handleAddUserCat(
                          unWrapOption(catData[activeTab].name, () => "")
                        );
                      }}
                      className="btn bg-primary border-0 px-4 text-white  ms-2 mt-2"
                    >
                      Add Limit
                    </button>
                  </div>
                ) : (
                  catData &&
                  catData.map(
                    (obj, index) =>
                      activeTab === index &&
                      obj.userCat.map((val, index1) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "1px solid #E2E2E2",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              // width: "95%",
                            }}
                            className="p-3"
                          >
                            <div style={{ width: "30%" }}>
                              <label>Employees</label>
                              <Select
                                className="align-self-stretch"
                                options={approvesoption}
                                value={val.type}
                                onChange={(value) => {
                                  handleChange(
                                    unWrapOption(obj.name, () => ""),
                                    index1,
                                    "type",
                                    value
                                  );
                                }}
                                // isDisabled={index1 < stateData.length}
                              />
                            </div>
                            <div style={{ width: "50%" }}>
                              {val.type.value === "specific" && (
                                <>
                                  <label> Select Employees</label>
                                  <Select
                                    options={empDrop}
                                    // isDisabled={index1 < stateData.length}
                                    value={val.employees
                                      .filter((obj) => obj.uid !== "")
                                      .map((obj) => ({
                                        value: obj.uid,
                                        label: obj.empName,
                                      }))}
                                    onChange={(value: any) => {
                                      validUser(
                                        value.map(
                                          (obj: OptionDrop) => obj.value
                                        )
                                      );
                                      handleChange(
                                        unWrapOption(obj.name, () => ""),
                                        index1,
                                        "employees",
                                        value
                                      );
                                    }}
                                    isMulti
                                    components={animatedComponents}
                                  />
                                  {valid.length > 0 && (
                                    <p
                                      style={{
                                        color: "#dc3545",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {valid
                                        .map((obj) => obj.empName)
                                        .join(", ")}{" "}
                                      <span style={{ fontWeight: "normal" }}>
                                        has opted for New Regime. This category
                                        is not applicable for them.
                                      </span>
                                    </p>
                                  )}
                                </>
                              )}
                            </div>

                            <div
                              style={{
                                // border: "1px solid black",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "10%",
                              }}
                            >
                              {/* <button
                                className="btn btn-sm btn-danger mt-4"
                                onClick={() => {
                                  index1 < stateData.length
                                    ? deleteUserCatData(
                                        val.type.value,
                                        val.type.value === "all"
                                          ? [
                                              {
                                                uid: null,
                                                categoryId: unWrapOption(
                                                  obj.fbaCategoryId,
                                                  () => ""
                                                ),
                                                maxLimit: val.maxLimit,
                                              },
                                            ]
                                          : val.employees.map((obj1) => ({
                                              uid: obj1.uid,
                                              categoryId: unWrapOption(
                                                obj.fbaCategoryId,
                                                () => ""
                                              ),
                                              maxLimit: val.maxLimit,
                                            }))
                                      )
                                    : handleDeleteUser(
                                        unWrapOption(obj.name, () => ""),
                                        index1
                                      );
                                }}
                              >
                                <MdDelete />
                              </button> */}
                              {
                                // index1 + 1 > stateData.length &&
                                <button
                                  className="btn btn-sm btn-primary mt-4 ms-2"
                                  onClick={() => {
                                    createUserCategory(
                                      val.type.value,
                                      val.type.value === "all"
                                        ? [""]
                                        : val.employees.map((obj) => obj.uid)
                                    );
                                  }}
                                  disabled={
                                    valid.length > 0 ||
                                    (val.type.value === "all"
                                      ? false
                                      : val.employees.filter(
                                          (obj) => obj.uid !== ""
                                        ).length > 0
                                      ? false
                                      : true)
                                  }
                                >
                                  <MdSave />
                                </button>
                              }
                            </div>
                          </div>
                        </>
                      ))
                  )
                )}
              </>
              {/* {catData[activeTab].userCat.length > 0 &&
                catData[activeTab].userCat.length < 5 && (
                  <div>
                    <button
                      onClick={() => {
                        handleAddUserCat(
                          unWrapOption(catData[activeTab].name, () => "")
                        );
                      }}
                      className="btn bg-primary border-0 px-4 text-white  ms-2 mt-2"
                    >
                      Add Limit
                    </button>
                  </div>
                )} */}
            </div>
          </>
        </div>
      </WhiteCard>
    </div>
  );
};

export default SetUserCategory;
