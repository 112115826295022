import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishAdminCorporateApiService } from "../api/admin/api/ncaishAdminCorporateApi.service";
import { AdminCorpCountApiResponse } from "../api/admin/model/adminCorpCountApiResponse";
import { AdminCorporateListApiResponse } from "../api/admin/model/adminCorporateListApiResponse";
import { AdminCorporateSubscriptionListApiResponse } from "../api/admin/model/adminCorporateSubscriptionListApiResponse";
import { AddSubscriptionPayload } from "../api/admin/model/corporate/addSubscriptionPayload";
import { AdminCorporateUpdateSubscriptionApiResponse } from "../api/admin/model/corporate/adminCorporateUpdateSubscriptionApiResponse";
import { NcaishCorporateApiService } from "../api/corporate/api/ncaishCorporateApi.service";
import {
  AddRemoveUserResponse,
  AddUserRequest,
} from "../api/corporate/model/addremoveuserresponse";
import { CorporateDetailApiResponse } from "../api/corporate/model/corporateDetailApiResponse";
import { NcaishAdminApiService } from "../api/employee/api/ncaishAdminApi.service";
import { AddAdminUserApiRequest } from "../api/employee/model/addAdminUserApiRequest";
import { AdminUserDetailApiResponse } from "../api/employee/model/adminUserDetailApiResponse";
import { BaseResponse } from "../model/baseResponse";

export namespace CorporateService {
  export const corporateCounts = async () => {
    const result = await NcaishAdminCorporateApiService.applicationCounts();
    return remoteData.map(
      result,
      (response: AdminCorpCountApiResponse) => response
    );
  };
  export const fetchByState = async (
    status: string,
    pn: number,
    rc: number
  ) => {
    const statusString = status === "ALL" ? "" : status;
    const result = await NcaishCorporateApiService.fetchByState(
      statusString,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: AdminCorporateListApiResponse) => response
    );
  };

  export const fetchByStateSearch = async (
    keyword: string,
    status: string,
    pn: number,
    rc: number
  ) => {
    const statusString = status === "ALL" ? "" : status;
    const result = await NcaishCorporateApiService.fetchByStateSearch(
      keyword,
      statusString,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: AdminCorporateListApiResponse) => response
    );
  };

  export const getCorporateSubscriptionList = async (cId?: String) => {
    const result = await NcaishCorporateApiService.getCorporateSubscriptionList(
      cId
    );
    return remoteData.map(
      result,
      (response: AdminCorporateSubscriptionListApiResponse) => response
    );
  };
  export const updateCorporateSubscriptionList = async (
    subscriptionList: AddSubscriptionPayload
  ) => {
    const result =
      await NcaishCorporateApiService.updateCorporateSubscriptionList(
        subscriptionList
      );
    return remoteData.map(
      result,
      (response: AdminCorporateUpdateSubscriptionApiResponse) => response
    );
  };

  export const detail = async (id: string) => {
    const result = await NcaishCorporateApiService.detail(id);
    return remoteData.map(
      result,
      (response: CorporateDetailApiResponse) => response
    );
  };
  export const myProfile2 = async () => {
    const result = await NcaishAdminApiService.myProfile2();
    return remoteData.map(
      result,
      (response: AdminUserDetailApiResponse) => response
    );
  };
  export const update2 = async (body: AddAdminUserApiRequest, id: string) => {
    const result = await NcaishAdminApiService.update2(body, id);
    return remoteData.map(
      result,
      (response: AdminUserDetailApiResponse) => response
    );
  };

  // Add or remove user
  export const getlistOfUser = async (id: string) => {
    const result = await NcaishCorporateApiService.addremoveUser(id);
    return remoteData.map(
      result,
      (response: AddRemoveUserResponse) => response
    );
  };

  export const getaddUser = async (request: AddUserRequest) => {
    const result = await NcaishCorporateApiService.addUser(request);
    return remoteData.map(
      result,
      (response: AddRemoveUserResponse) => response
    );
  };
  export const getremoveUser = async (id: string) => {
    const result = await NcaishCorporateApiService.removeUser(id);
    return remoteData.map(
      result,
      (response: AddRemoveUserResponse) => response
    );
  };

  export const sendNotification = async (cid: string, body: FormData) => {
    const result = await NcaishAdminApiService.sendNotification(cid, body);
    return remoteData.map(result, (response: BaseResponse) => response);
  };
}
