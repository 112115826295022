import { fromNullable } from "fp-ts/lib/Option";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "./components/app/app-routes";
import {
  endLoad,
  selectCurrentScreen,
  setCurrentScreen,
} from "./components/app/app.slice";
import { IdleTimeout } from "./components/idle-timeout/Idle-timeout";

import { useAppSelector } from "./hooks/hooks";
import { Scope } from "./models/enum-scope";
import PageLoader from "./page-loader";
import { Dispatch } from "./state/dispatch";
import { setToken } from "./state/slice/token.slice";
import { store } from "./state/store";
import { useStoreWithInitializer } from "./state/storeHooks";
import { LocalStorageItemKeys, MyStorage } from "./utils/local-storage.utils";
import { toast } from "react-toastify";
import ToastError from "./components/common/error-toast";

/*
  @Dashboard Router
*/

export const allowedUids = [
  "627b76caaccc3bef17df80c0", //prod sathis
  "629482-3373-213-31283", // prod sampath
  // "626cd0c48be5cdffad95b0b3", //uat internal
  "626cd0c48be5cdffad95b0b3",
];

const App = () => {
  const notify = (err: string[]) =>
    toast.error(<ToastError errArray={err} />, {
      onClose: () =>
        Dispatch.App.setBadRequestError({ showError: false, errorText: [] }),
    });
  const location = useLocation();

  const navigate = useNavigate();
  const currentRoute = useAppSelector(selectCurrentScreen);
  const { badRequestError, loading, isAuthenticated } = useStoreWithInitializer(
    ({ app }) => app,
    () => load(location, navigate)
  ); // load localstorage data into redux store

  // route to the current screen
  // useEffect(() => {
  //   navigate(currentRoute);
  // }, [currentRoute]);

  useEffect(() => {
    if (badRequestError) {
      if (badRequestError.showError && badRequestError.errorText.length) {
        notify(badRequestError.errorText);
      }
    }
  }, [badRequestError]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      {isAuthenticated && <IdleTimeout />}
      <AppRoutes />
    </>
  );
};

async function load(location: any, navigate: any) {
  try {
    const loginId = localStorage.getItem(LocalStorageItemKeys.loginId);
    const authToken = MyStorage.AuthToken.LoadData();

    Dispatch.App.setLoginId(fromNullable(loginId));

    if (authToken) {
      const { accessToken, refreshToken, tokenExpiry, scope } = authToken;
      if (accessToken && refreshToken && tokenExpiry) {
        store.dispatch(
          setToken({ accessToken, refreshToken, tokenExpiry, scope })
        );
      }
      if (scope === Scope.FX_TRIAL_APPLICANT) {
        //await getLeadInfo();
      }
      navigate(location.pathname); // on hard refresh send to location
    }

    store.dispatch(endLoad());
  } catch {
    store.dispatch(endLoad());
  }
}

export default App;
