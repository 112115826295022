import classNames from "classnames";
import React from "react";
import { AllEmployeesDispatch } from "../../../../state/slice/allEmployee.slice";
import WhiteCard from "../../../white-card/white-card";

type Props = {
  isActive: boolean;
  count: number;
  label: string;
  status: string;
};

const CountCard: React.FC<Props> = (props) => {
  return (
    <div>
      <div
        onClick={() => {
          AllEmployeesDispatch.setFilterTypeStatus({
            status: props.status || "ALL",
            label: props.label || "All",
          });
        }}
        className="card-count"
      >
        <WhiteCard
          className={classNames("cursor-pointer p-2 w-80 status-dot-card", {
            color0: props.status == "ALL",
            color1: props.status == "ACCEPTANCE_PENDING",
            color3: props.status == "KYC_PENDING",
            color14: props.status == "KYC_INITIATED",
            color7: props.status == "KYC_UNDER_REVIEW",
            color16: props.status == "KYC_REJECTED",
            color2: props.status == "ACTIVATION_PENDING",
            color4: props.status == "ACTIVATED",
            color5: props.status == "DEACTIVATED",
            color6: props.status == "BLOCKED",
            color8: props.status == "EXPIRED",
            active: props.isActive,
          })}
        >
          <div className="status-dot"></div>
          <h2 className="text-black text-center mb-0 fw-bold">{props.count}</h2>
          <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
            {props.label}
          </p>
        </WhiteCard>
      </div>
    </div>
  );
};

export default CountCard;
