import React from "react";
import Switch from "react-switch";

const ReactSwitch: React.FC<{
  switchState: boolean;
  handleSwitch: (data: boolean) => void;
  disabled?: boolean;
  height?: number;
  width?: number;
}> = ({ switchState, handleSwitch, disabled = false, height, width }) => {
  return (
    <>
      <Switch
        onChange={(data) => handleSwitch(data)}
        checked={switchState}
        uncheckedIcon={false}
        checkedIcon={false}
        disabled={disabled}
        height={height}
        width={width}
      />
      {height && width && (
        <style>{`.react-switch-handle { width: ${25}px; height: 25px; }`}</style>
      )}
    </>
  );
};

export default ReactSwitch;
