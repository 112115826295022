/**
 * Ncaish Corporate apis
 * Api to manage corporate onboarding ,application and employee management
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdminCorporateCountInfo {
  label?: string;
  status?: AdminCorporateCountInfo.StatusEnum;
  count?: number;
}
export namespace AdminCorporateCountInfo {
  export type StatusEnum = "ALL" | "ACTIVE" | "INACTIVE" | "BLOCKED";
  export const StatusEnum = {
    ALL: "ALL" as StatusEnum,
    ACTIVE: "ACTIVE" as StatusEnum,
    INACTIVE: "INACTIVE" as StatusEnum,
    BLOCKED: "BLOCKED" as StatusEnum,
  };
}
