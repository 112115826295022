import { pipe } from "fp-ts/lib/function";
import { fold, isNone, isSome } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { MembershipReduxStore } from "../../../state/slice/membership.slice";
import TableLoader from "../../pages/dashboard/common/table-loader";
import WhiteCard from "../../white-card/white-card";
import BalanceAmount from "../common/balance-amount";
import { Stage } from "../../../api/detax/model/stage";

import { fetchMembershipBatchDetail } from "./utilies/membership.utils";
import MembershipTimelineHorizontal from "./membership-timeline-horizontal";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const EmpCodeIcon = "/images/icons/emp_code.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";
const CINIcon = "/images/pen_card.svg";
const BuildIcon = "/images/icons/building.svg";
const EmployeesIcon = "/images/icons/menus/employees-icon.svg";
const RequestId = "/images/request.png";
const TransactionId = "/images/transaction-id.png";
const DetaxPlus = "/images/detaxPlus.png";

const MembershipDetailCard: React.FC<{ membershipId: string; cId: string }> = ({
  cId,
  membershipId,
}) => {
  const membershipInfoOpt = useAppSelector(
    MembershipReduxStore.selectBatchInfo
  );
  const [stage, setStage] = useState<Stage[] | []>([]);

  useEffect(() => {
    fetchMembershipBatchDetail(cId, membershipId);
  }, [membershipId, cId]);

  useEffect(() => {
    if (isSome(membershipInfoOpt)) {
      if (membershipInfoOpt.value.batchStatus === "Requested") {
        const val: Stage[] = [
          {
            displayName: "Requested",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Initiated",
            executed: false,
            color: "GREY",
          },
          {
            displayName: "Executed",
            executed: false,
            color: "GREY",
          },
          {
            displayName: "Approved",
            executed: false,
            color: "GREY",
          },
        ];
        setStage(val);
      } else if (membershipInfoOpt.value.batchStatus === "Initiated") {
        const val: Stage[] = [
          {
            displayName: "Requested",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Initiated",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Executed",
            executed: false,
            color: "GREY",
          },
          {
            displayName: "Approved",
            executed: false,
            color: "GREY",
          },
        ];
        setStage(val);
      } else if (membershipInfoOpt.value.batchStatus === "Executed") {
        const val: Stage[] = [
          {
            displayName: "Requested",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Initiated",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Executed",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Approved",
            executed: false,
            color: "GREY",
          },
        ];
        setStage(val);
      } else if (membershipInfoOpt.value.batchStatus === "Approved") {
        const val: Stage[] = [
          {
            displayName: "Requested",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Initiated",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Executed",
            executed: true,
            color: "GREEN",
          },
          {
            displayName: "Approved",
            executed: true,
            color: "GREEN",
          },
        ];
        setStage(val);
      }
    }
  }, [membershipInfoOpt]);

  if (isNone(membershipInfoOpt)) {
    return <TableLoader />;
  }
  return (
    <div>
      {pipe(
        membershipInfoOpt,
        fold(
          () => <div>No details found</div>,
          (data) => (
            <>
              {membershipInfoOpt.value.batchStatus !== "Canceled" ? (
                <WhiteCard className="p-3">
                  <div className="row p-2" id="ExpenseDetails">
                    <div className="col-12">
                      <MembershipTimelineHorizontal stages={stage} />
                    </div>
                  </div>
                </WhiteCard>
              ) : null}

              <WhiteCard className="p-3">
                <div className="row">
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          Created By
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {data?.createdBy ? data?.createdBy || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Mobile No.{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.mobile ? data?.mobile || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> E-mail </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.email ? data?.email || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={CalendarIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Created Date{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {data?.createdAt ? data?.createdAt || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={RequestId}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Membership Request No{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {membershipId ? membershipId || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmpDesgIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> Status </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.batchStatus
                            ? data?.batchStatus === "Canceled"
                              ? "Cancelled"
                              : data?.batchStatus
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </WhiteCard>
              <WhiteCard className="p-3">
                <div className="d-flex justify-content-between">
                  <h6 className="text-secondary fw-bold">Request Details</h6>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={DetaxPlus}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Detax + Employees{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.users?.length || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={SalaryIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          Total Amount{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          ₹ {data?.batchAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                  {(data?.batchStatus === "Initiated" ||
                    data?.batchStatus === "Executed" ||
                    data?.batchStatus === "Approved") && (
                    <>
                      <div className="col-12 col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={SalaryIcon}
                                className="img-fluid ml-auto mr-auto"
                                alt="icon"
                                width="18"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 ncash_fs-12 text-muted">
                              {" "}
                              Amount Received{" "}
                            </p>
                            <p className="fs-small fw-bold mb-0">
                              {" "}
                              ₹ {data?.amountRecieved}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={CalendarIcon}
                                className="img-fluid ml-auto mr-auto"
                                alt="icon"
                                width="18"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 ncash_fs-12 text-muted">
                              {" "}
                              Transaction Date{" "}
                            </p>
                            <p className="fs-small fw-bold mb-0">
                              {" "}
                              {data?.transactionDate
                                ? data?.transactionDate || ""
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {(data?.batchStatus === "Initiated" ||
                  data?.batchStatus === "Executed" ||
                  data?.batchStatus === "Approved") && (
                  <div className="row mt-3">
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={TransactionId}
                              className="img-fluid ml-auto mr-auto"
                              alt="icon"
                              width="18"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 ncash_fs-12 text-muted">
                            {" "}
                            Transaction Ref Id{" "}
                          </p>
                          <p className="fs-small fw-bold mb-0">
                            {" "}
                            {data?.transactionId}
                          </p>
                        </div>
                      </div>
                    </div>
                    {(data?.batchStatus === "Executed" ||
                      data?.batchStatus === "Approved") && (
                      <div className="col-12 col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={CalendarIcon}
                                className="img-fluid ml-auto mr-auto"
                                alt="icon"
                                width="18"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 ncash_fs-12 text-muted">
                              {" "}
                              Amount Transfered to Partners on{" "}
                            </p>
                            <p className="fs-small fw-bold mb-0">
                              {" "}
                              {data?.dateOfPaymentToProvider
                                ? data?.dateOfPaymentToProvider || ""
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </WhiteCard>
              <WhiteCard className="p-3">
                <div className="d-flex justify-content-between">
                  <h6 className="text-secondary fw-bold">Corporate Details</h6>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={BuildIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> Name </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.corpName ? data?.corpName || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={CINIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          {" "}
                          CIN Number{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {" "}
                          {data?.cinNumber ? data?.cinNumber || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={CalendarIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted">
                          Registered At{" "}
                        </p>
                        <p className="fs-small fw-bold mb-0">
                          {data?.registeredAt ? data?.registeredAt || "" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={SalaryIcon}
                            className="img-fluid ml-auto mr-auto"
                            alt="icon"
                            width="18"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 ncash_fs-12 text-muted"> Balance </p>
                        <p className="fs-small fw-bold mb-0">
                          <BalanceAmount cid={cId || ""} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </WhiteCard>
            </>
          )
        )
      )}
    </div>
  );
};

export default MembershipDetailCard;
