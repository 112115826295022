import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, isSome, none, some } from "fp-ts/lib/Option";

import { CorporateOnboardingApiService } from "../api/corporateOnboardingApi.service";

//import { AccountHolder } from "../model/accountHolder";
import { ApiBrInfo } from "../model/apiBrInfo";

import { CorporateApiInfo } from "../model/corporateApiInfo";
import { CorporateServiceInfo } from "../model/corporateServiceInfo";
import { CorporateUser } from "../model/corporateUser";
import { CorpReviewApiResponse } from "../model/corpReviewApiResponse";
import { CorpServicesApiResponse } from "../model/corpServicesApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { GetContractReceipientApiResponse } from "../model/getContractReceipientApiResponse";

import { SendBrResponse } from "../model/sendBrResponse";

import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { ValidateCorpApiResponse } from "../model/validateCorpApiResponse";

//import { TAccountHolder } from "../types/account-holder.type";
import { TApiBrInfo } from "../types/api-br-info";
import { TBrReceipient } from "../types/br-receipient.type";
import { TContractReceipient } from "../types/contract-receipient.type";
import { TCorporateInfo } from "../types/corporate-info.type";
import { TCorporateReviewResponse } from "../types/corporate-review-response.type";
import { TCorporateService } from "../types/corporate-service.type";
import { TCorporateUser } from "../types/corporate-user.type";

import { TReceipientDto } from "../types/update-receipient-request.type";

import { NcaishAdminApplicationApiService } from "../api/admin/api/ncaishAdminApplicationApi.service";
import { AdminApplicationBasicInfo } from "../api/admin/model/adminApplicationBasicInfo";
import { AdminApplicationListResponse } from "../api/admin/model/adminApplicationListResponse";
import { AdminCorpApplicationCountInfo } from "../api/admin/model/adminCorpApplicationCountInfo";
import { ApplicantInfo } from "../api/admin/model/applicantInfo";
import { ApplicationApprovalRequest } from "../api/admin/model/applicationApprovalRequest";
import { ApplicationCountApiResponse } from "../api/admin/model/applicationCountApiResponse";
import { ApplicationDetailApiResponse } from "../api/admin/model/applicationDetailApiResponse";
import {
  CreateMaxLimitResponse,
  CreateReqBody,
  DeleteMaxLimitResponse,
  DeleteReqBody,
  GetEmployeesRes,
  GetMaxLimitResponse,
} from "../api/admin/model/maxLimitResponse";
import {
  SelectedServicesApiResponse,
  SuccessApiResponse,
} from "../api/admin/model/models";
import { AdminListOfCategoriesApiResopnse } from "../api/admin/model/onboarding/adminListOfCategoriesApiResponse";
import { AdminListOfSchemesApiResponse } from "../api/admin/model/onboarding/adminListOfSchemes";
import { ListOfSelectedCategoriesApiReq } from "../api/admin/model/onboarding/listOfSelectedCategoryApiReq";
import { UpdateClaimsFlowApiReq } from "../api/admin/model/onboarding/updateClaimsFlowApiReq";
import { NcaishCorporateApplicationApiService } from "../api/corporate/api/ncaishCorporateApplicationApi.service";
import { BrReceipientInfo } from "../model/brReceipientInfo";
import { TAllAvailableCategories } from "../models/onboarding.models";
import { TOnBoardingApplicationCountInfo } from "../types/admin/onboarding-application-count.type";
import { TOnboardingItem } from "../types/admin/onboarding-item.type";
import { TCorpServicesApiResponse } from "../types/corp-services-api-response.type";
import { TCorpUpdateServiceApiResponse } from "../types/corp-update-service-api-response.type";
import { TValidateCorpApiResponse } from "../types/validate-corp-api-response.type";
import { unWrapOption } from "../utils/option.utils";
import { ConvertToRoutePath } from "../utils/route.utils";
import { GetUserCategoryDataResponse } from "../model/getUserCategoryResponse";

// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace OnboardingService {
  export const validateCompanyCin = async (cin: string) => {
    const result = await CorporateOnboardingApiService.validateCin({ cin });
    return remoteData.map(result, parseCompanyCin);
  };

  export const getUserCategoryData = async (cid: string, catId: string) => {
    const result = await CorporateOnboardingApiService.getUserCategoryData(
      cid,
      catId
    );

    return remoteData.map(
      result,
      (response: GetUserCategoryDataResponse) => response
    );
  };

  export const updateUserCategoryData = async (
    cid: string,
    catId: string,
    type: string,
    uid: string
  ) => {
    const result = await CorporateOnboardingApiService.updateUserCategoryData(
      cid,
      catId,
      type,
      uid
    );

    return remoteData.map(
      result,
      (response: GetUserCategoryDataResponse) => response
    );
  };

  export const getContractReceipient = async (draftId: string) => {
    const result = await CorporateOnboardingApiService.getContractReceipient();
    return remoteData.map(result, parseContractReceipient);
  };

  export const getReview = async () => {
    const result = await CorporateOnboardingApiService.myApplication();
    return remoteData.map(result, parseReview);
  };

  export const updateSignatories = async (
    receipients: Array<TReceipientDto>,
    draftId: string
  ) => {
    const result = await CorporateOnboardingApiService.updateSignatories({
      data: receipients.map((r) => ({
        type: r.type,
        info: {
          userId: r.userId,
          name: r.name,
          email: r.email,
          mobile: r.mobile,
        },
      })),
    });

    return remoteData.map(result, (br: UpdateSignatoriesApiResponse) => ({
      successful: fromNullable(br.successful),
      message: fromNullable(br.message),
      applicationId: fromNullable(br.applicationId),
      nextAction: fromNullable(ConvertToRoutePath(br.nextAction)),
    }));
  };

  export const setCorporateCategory = async (
    categories: ListOfSelectedCategoriesApiReq
  ) => {
    const result = await CorporateOnboardingApiService.setCorporateCategory(
      categories
    );
    return remoteData.map(result, (data: SuccessApiResponse) => ({
      successful: fromNullable(data.successful),
      message: fromNullable(data.message),
    }));
  };

  export const updateAddCorporateCategory = async (
    id: string,
    categories: ListOfSelectedCategoriesApiReq
  ) => {
    const result =
      await CorporateOnboardingApiService.updateAddCorporateCategory(
        id,
        categories
      );
    return remoteData.map(result, (data: SuccessApiResponse) => ({
      successful: fromNullable(data.successful),
      message: fromNullable(data.message),
    }));
  };

  export const updateDeleteCorporateCategory = async (
    id: string,
    categories: ListOfSelectedCategoriesApiReq
  ) => {
    const result =
      await CorporateOnboardingApiService.updateDeleteCorporateCategory(
        id,
        categories
      );
    return remoteData.map(result, (data: SuccessApiResponse) => ({
      successful: fromNullable(data.successful),
      message: fromNullable(data.message),
    }));
  };

  export const updateBusinessUnit = async (
    cid: string,
    businessUnit: string
  ) => {
    const result = await CorporateOnboardingApiService.updateBusinessUnit(
      cid,
      businessUnit
    );
    return remoteData.map(result, (data: SuccessApiResponse) => ({
      successful: fromNullable(data.successful),
      message: fromNullable(data.message),
    }));
  };

  export const updateClaimsFlow = async (
    cid: string,
    claimFlowData: UpdateClaimsFlowApiReq
  ) => {
    const result = await CorporateOnboardingApiService.updateClaimsFlow(
      cid,
      claimFlowData
    );
    return remoteData.map(result, (data: SuccessApiResponse) => ({
      successful: fromNullable(data.successful),
      message: fromNullable(data.message),
    }));
  };

  export const getServices = async () => {
    const result = await CorporateOnboardingApiService.services1();
    return remoteData.map(result, parseCorpServices);
  };

  export const updateServices = async (
    services: Array<string>,
    draftId: string
  ) => {
    const result = await CorporateOnboardingApiService.services({
      data: services,
    });
    return remoteData.map(result, parseServicesResponse);
  };

  export const sendBrApplication = async (accountId: string) => {
    const result = await CorporateOnboardingApiService.sendBr();
    return remoteData.map(result, (br: SendBrResponse) => ({
      successful: fromNullable(br.successful),
      message: fromNullable(br.message),
      nextAction: fromNullable(ConvertToRoutePath(br.nextAction)),
      //draftId: fromNullable(br.draftId),
    }));
  };

  const parseServicesResponse = (
    response: CorpUpdateServiceApiResponse
  ): TCorpUpdateServiceApiResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextScreen: fromNullable(ConvertToRoutePath(response.nextScreen)),
      draftId: fromNullable(response.draftId),
    };
  };

  const parseCorpServices = (
    corp: CorpServicesApiResponse
  ): TCorpServicesApiResponse => {
    return {
      services: corp.services ? corp.services.map(parseService) : [],
      successful: fromNullable(corp.successful),
      message: fromNullable(corp.message),
    };
  };

  const parseContractReceipient = (
    response: GetContractReceipientApiResponse
  ): TContractReceipient => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      receipients: response.receipients
        ? response.receipients.map(parseReceipients)
        : [],
      directors: response.directors
        ? response.directors.map(parseDirectors)
        : [],
    };
  };

  const parseReview = (
    response: CorpReviewApiResponse
  ): TCorporateReviewResponse => {
    const data = fromNullable(response.data);
    const corporateInfo = isSome(data)
      ? some(parseCorporateInfo(data.value))
      : none;
    return {
      data: corporateInfo,
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
    };
  };

  const parseCompanyCin = (
    response: ValidateCorpApiResponse
  ): TValidateCorpApiResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
      applicationId: fromNullable(response.applicationId),
    };
  };

  // const parseAccountHolder = (acc?: AccountHolder): TAccountHolder => {
  //   return acc
  //     ? {
  //         name: fromNullable(acc.name),
  //         email: fromNullable(acc.email),
  //         mobile: fromNullable(acc.mobile),
  //         emailVerified: fromNullable(acc.emailVerified),
  //         mobileVerified: fromNullable(acc.mobileVerified),
  //       }
  //     : {
  //         name: none,
  //         email: none,
  //         mobile: none,
  //         emailVerified: none,
  //         mobileVerified: none,
  //       };
  // };

  export const parseDirectors = (user: CorporateUser): TCorporateUser => {
    return {
      userId: fromNullable(user.userId),
      name: fromNullable(user.name),
      email: fromNullable(user.email),
      mobile: fromNullable(user.mobile),
    };
  };

  export const parseReceipients = (
    receipient: BrReceipientInfo
  ): TBrReceipient => {
    return {
      type: fromNullable(receipient.type),
      status: fromNullable(receipient.status),
      name: fromNullable(receipient.info?.name),
      email: fromNullable(receipient.info?.email),
      mobile: fromNullable(receipient.info?.mobile),
      userId: fromNullable(receipient.info?.userId),
    };
  };

  export const parseService = (
    service: CorporateServiceInfo
  ): TCorporateService => {
    return {
      type: fromNullable(service.type),
      name: fromNullable(service.name),
      img: fromNullable(service.img),
      description: fromNullable(service.description),
      selected: fromNullable(service.selected),
    };
  };

  const parseBrInfo = (acc?: ApiBrInfo): TApiBrInfo => {
    return acc
      ? {
          status: fromNullable(acc.status),
          document: fromNullable(acc.document),
          sentOn: fromNullable(acc.sentOn),
          receipients: acc.receipients
            ? acc.receipients.map(parseReceipients)
            : [],
        }
      : {
          status: none,
          document: none,
          sentOn: none,
          receipients: [],
        };
  };

  const parseCorporateInfo = (corp: CorporateApiInfo): TCorporateInfo => {
    //const accountHolder = fromNullable(corp.accountHolder);
    //const directors = fromNullable(corp.directors);
    const brInfo = parseBrInfo(corp.brInfo);
    const directors: TCorporateUser[] = brInfo.receipients
      .filter((rec) => unWrapOption(rec.type, () => "") === "DIRECTOR")
      .map((d) => ({
        userId: d.userId,
        name: d.name,
        email: d.email,
        mobile: d.mobile,
      }));

    return {
      applicationId: fromNullable(corp.applicationId),
      name: fromNullable(corp.name),
      cin: fromNullable(corp.cin),
      roc: fromNullable(corp.roc),
      doi: fromNullable(corp.doi),
      regNo: fromNullable(corp.regNo),
      address: fromNullable(corp.address),
      //accountHolder: parseAccountHolder(corp.accountHolder),
      directors: corp.directors ? corp.directors.map(parseDirectors) : [],
      brInfo: brInfo,
      services: corp.services ? corp.services.map(parseService) : [],
    };
  };

  // admin related apis call

  export const onboardingCounts = async () => {
    const result = await NcaishAdminApplicationApiService.applicationCounts1();
    return remoteData.map(result, (response: ApplicationCountApiResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationCount) : [],
    }));
  };

  const parseApplicationCount = (
    app: AdminCorpApplicationCountInfo
  ): TOnBoardingApplicationCountInfo => {
    return {
      label: fromNullable(app.label),
      state: fromNullable(app.state),
      count: fromNullable(app.count),
    };
  };

  export const onboardingListByState = async (
    state: string,
    pn: number,
    rc: number
  ) => {
    const filter = state === "ALL" ? "" : state;
    const result = await NcaishCorporateApplicationApiService.fetchByState1(
      filter,
      pn,
      rc
    );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfo) : [],
      pageCount: response.pageCount,
      recordCount: response.recordCount,
    }));
  };

  export const onboardingListByStateSearch = async (
    keyword: string,
    state: string,
    pn: number,
    rc: number
  ) => {
    const filter = state === "ALL" ? "" : state;
    const result =
      await NcaishCorporateApplicationApiService.fetchByStateSearch(
        keyword,
        filter,
        pn,
        rc
      );
    return remoteData.map(result, (response: AdminApplicationListResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: response.data ? response.data.map(parseApplicationInfo) : [],
      pageCount: response.pageCount,
      recordCount: response.recordCount,
    }));
  };

  export const detail = async (id: string) => {
    const result = await NcaishAdminApplicationApiService.detail2(id);

    return remoteData.map(
      result,
      (response: ApplicationDetailApiResponse) => response
    );
  };

  export const getAllAvailableCategories = async () => {
    const result =
      await NcaishAdminApplicationApiService.getAllAvailableCategories();
    return remoteData.map(
      result,
      (
        response: AdminListOfCategoriesApiResopnse
      ): TAllAvailableCategories[] => {
        const catList: TAllAvailableCategories[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                fbaCategoryId: fromNullable(d.fbaCategoryId),
                name: fromNullable(d.name),
                rule: fromNullable(d.rule),
                newRegime: fromNullable(d.newRegime),
                isEligibleToAdd: true,
                isSelected: false,
              }))
            : []
          : [];

        return catList;
      }
    );
  };

  export const getAllCorporateCategories = async (id: string) => {
    const result =
      await NcaishAdminApplicationApiService.getAllCorporateCategories(id);
    return remoteData.map(
      result,
      (
        response: AdminListOfCategoriesApiResopnse
      ): TAllAvailableCategories[] => {
        const catList: TAllAvailableCategories[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                fbaCategoryId: fromNullable(d.fbaCategoryId),
                name: fromNullable(d.name),
                rule: fromNullable(d.rule),
                newRegime: fromNullable(d.newRegime),
                isEligibleToAdd: false,
                isSelected: false,
              }))
            : []
          : [];

        return catList;
      }
    );
  };

  export const getSelectedServices = async (id: string) => {
    const result = await NcaishAdminApplicationApiService.getSelectedServices(
      id
    );
    return remoteData.map(
      result,
      (response: SelectedServicesApiResponse) => response
    );
  };

  const parseApplicationInfo = (
    app: AdminApplicationBasicInfo
  ): TOnboardingItem => {
    return {
      id: app.id || "",
      appCode: app.appCode || "",
      companyName: app.companyName || "",
      cin: app.cin || "",
      applicantInfo: {
        name: app.applicantInfo?.name || "",
        mobile: app.applicantInfo?.mobile || "",
        email: app.applicantInfo?.email || "",
        emailVerified: app.applicantInfo?.emailVerified || false,
        mobileVerified: app.applicantInfo?.mobileVerified || false,
      } as Required<ApplicantInfo>,
      state: fromNullable(app.state),
      createdAt: app.createdAt || "",
      updatedAt: app.updatedAt || "",
    };
  };

  export const approveOrReject = async (
    body: ApplicationApprovalRequest,
    id: string
  ) => {
    const result = await NcaishAdminApplicationApiService.handleApproval(
      body,
      id
    );
    return remoteData.map(
      result,
      (response: ApplicationDetailApiResponse) => response
    );
  };

  export const updateService = async (body: string[], id: string) => {
    const result = await NcaishAdminApplicationApiService.updateService(
      body,
      id
    );
    return remoteData.map(
      result,
      (response: ApplicationDetailApiResponse) => response
    );
  };

  export const updateCorpFund = async (
    corpFund: string,
    cbo: string,
    cho: string,
    cid: string
  ) => {
    const result = await NcaishAdminApplicationApiService.updateCorpFund(
      corpFund,
      cbo,
      cho,
      cid
    );
    return remoteData.map(
      result,
      (response: ApplicationDetailApiResponse) => response
    );
  };

  export const getAllSchemes = async () => {
    const result = await NcaishAdminApplicationApiService.getAllSchemes();
    return remoteData.map(
      result,
      (response: AdminListOfSchemesApiResponse) => response
    );
  };

  export const getMaxLimitData = async (cid: string, catid: string) => {
    const result = await NcaishAdminApplicationApiService.getMaxLimitData(
      cid,
      catid
    );
    return remoteData.map(result, (response: GetMaxLimitResponse) => response);
  };

  export const createMaxLimit = async (
    cid: string,
    type: string,
    body: CreateReqBody
  ) => {
    const result = await NcaishAdminApplicationApiService.createMaxLimit(
      cid,
      type,
      body
    );
    return remoteData.map(
      result,
      (response: CreateMaxLimitResponse) => response
    );
  };

  export const deleteMaxLimit = async (
    cid: string,
    type: string,
    body: DeleteReqBody
  ) => {
    const result = await NcaishAdminApplicationApiService.deleteMaxLimit(
      cid,
      type,
      body
    );
    return remoteData.map(
      result,
      (response: DeleteMaxLimitResponse) => response
    );
  };

  export const enableAnnualLimit = async (cid: string) => {
    const result = await NcaishAdminApplicationApiService.enableAnnualLimit(
      cid
    );
    return remoteData.map(
      result,
      (response: DeleteMaxLimitResponse) => response
    );
  };

  export const getEmployeesWithCid = async (cid: string) => {
    const result = await NcaishAdminApplicationApiService.getEmployeesWithCid(
      cid
    );
    return remoteData.map(result, (response: GetEmployeesRes) => response);
  };
}
