export enum CampaignTabKind {
  LIVE = "LIVE",
  DRAFT = "DRAFT",
  DEACTIVATE = "DEACTIVATE",
}

export type MTab = { tabLabel: string; tabValue: CampaignTabKind };

export const tabData: MTab[] = [
  {
    tabLabel: "Live",
    tabValue: CampaignTabKind.LIVE,
  },
  {
    tabLabel: "Draft",
    tabValue: CampaignTabKind.DRAFT,
  },
  {
    tabLabel: "Archive",
    tabValue: CampaignTabKind.DEACTIVATE,
  },
];

export const CampaignLabel = (data: string) => {
  const final = tabData.find((obj) => obj.tabValue === data);

  return final?.tabLabel ?? "";
};
