import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, isSome, Option } from "fp-ts/lib/Option";
import { AdminAllEmployeeListApiResponse } from "../api/admin/model/all-employees/adminAllEmployeeListApiResponse";
import { AdminAllEmployeesListInfo } from "../api/admin/model/all-employees/adminAllEmployeesListInfo";
import { AdminAllEmployeeStatuscountApiResponse } from "../api/admin/model/all-employees/adminAllEmployeesStatusCountApiResponse";
import { NcaishAllEmployeeApiService } from "../api/detax/api/ncaishAllEmployeesApi.service";
import {
  TAdminAllEmployeesListInfo,
  TAdminAllEmployeeStatuscountApiResponse,
  TAllEmployeeListApiResponse,
} from "../models/allEmployees.models";
import { BaseResponse } from "../model/baseResponse";

interface params {
  key: string;
  value: string;
}

type filterVal = [params] | [];

export namespace AllEmployeesService {
  export const getAllEmployeeList = async (
    filter: string,
    pn: number,
    rc: number,
    from: string,
    to: string
  ) => {
    let status = filter.toLowerCase() === "all" ? "" : filter;
    const result = await NcaishAllEmployeeApiService.getAllEmployeeList(
      status,
      pn,
      rc,
      from,
      to
    );

    return remoteData.map(
      result,
      (
        response: AdminAllEmployeeListApiResponse
      ): TAllEmployeeListApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
          pageCount: fromNullable(response.pageCount),
          recordCount: fromNullable(response.recordCount),
        };
      }
    );
  };

  export const getAllEmployeeListSearch = async (
    keyword: string,
    filter: string,
    pn: number,
    rc: number
  ) => {
    let status = filter.toLocaleUpperCase() === "ALL" ? "" : filter;
    const result = await NcaishAllEmployeeApiService.getAllEmployeeListSearch(
      keyword,
      status,
      pn,
      rc
    );

    return remoteData.map(
      result,
      (
        response: AdminAllEmployeeListApiResponse
      ): TAllEmployeeListApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
          pageCount: fromNullable(response.pageCount),
          recordCount: fromNullable(response.recordCount),
        };
      }
    );
  };

  export const getAllEmployeesStatusCounts = async () => {
    const result =
      await NcaishAllEmployeeApiService.getAllEmployeesStatusCounts();
    return remoteData.map(
      result,
      (
        response: AdminAllEmployeeStatuscountApiResponse
      ): TAdminAllEmployeeStatuscountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const parseAdminAllEmployeeListInfo = (
    data: Option<AdminAllEmployeesListInfo[]>
  ): TAdminAllEmployeesListInfo[] => {
    const parseData: TAdminAllEmployeesListInfo[] = isSome(data)
      ? data.value.map((d) => ({
          uid: fromNullable(d.uid),
          personId: fromNullable(d.personId),
          corpName: fromNullable(d.corpName),
          name: fromNullable(d.name),
          email: fromNullable(d.email),
          mobile: fromNullable(d.mobile),
          salary: fromNullable(d.salary),
          status: fromNullable(d.status),
          monthlyFba: fromNullable(d.monthlyFba),
          fbaBalance: fromNullable(d.fbaBalance),
          acceptedAt: fromNullable(d.acceptedAt),
          activatedAt: fromNullable(d.activatedAt),
          taxRegime: fromNullable(d.taxRegime),
        }))
      : [];
    return parseData;
  };

  export const resetUserPassword = async (uid: string) => {
    const result = await NcaishAllEmployeeApiService.resetUserPassword(uid);
    return remoteData.map(result, (response: BaseResponse) => response);
  };
}
