import { format, parseISO } from "date-fns";
import toDate from "date-fns/toDate/index.js";
import { toError } from "fp-ts/lib/Either";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";

import {
  isSuccess,
  RemoteData,
  RemoteInitial,
} from "@devexperts/remote-data-ts";
import { SelectedServicesInfo } from "../../../api/admin/model/models";
import WhiteCard from "../../white-card/white-card";
import { OnboardingService } from "../../../services/onboarding.service";
import { AdminListOfScheme } from "../../../api/admin/model/onboarding/adminListOfSchemes";
import Select from "react-select";
import OptionsType from "react-select";
import { object } from "yup";
// this popup is for select date range
const TickIcon = "/images/tick.svg";
const TaxIcon = "/images/tax.png";
const FXIcon = "/images/fx.png";
const NpsIcon = "/images/nps.png";

interface CustomOptionType {
  value: string;
  label: string;
}

const ServicePopup: React.FC<{
  actionState1: boolean;
  show: boolean;
  closeModal: () => void;
  selectedServices: SelectedServicesInfo[];
  setSelectedServices: (val: SelectedServicesInfo[]) => void;
  id: string;
  close: (val: boolean) => void;
  fundName: string;
  cboCode: string;
  choCode: string;
}> = ({
  show,
  closeModal,
  actionState1,
  selectedServices,
  setSelectedServices,
  id,
  close,
  fundName,
  cboCode,
  choCode,
}) => {
  const Image = (data: string) => {
    if (data === "DETAX_CARD") {
      return TaxIcon;
    } else if (data === "FX") {
      return FXIcon;
    } else if (data === "NPS") {
      return NpsIcon;
    }
  };

  const [service, setService] = useState<SelectedServicesInfo[]>([]);
  const [schemes, setSchemes] = useState<AdminListOfScheme[]>([]);
  const [selectedScheme, setSelectedScheme] = useState<CustomOptionType>();
  const [values, setValues] = useState({ cbo: "", cho: "" });
  useEffect(() => {
    setService(selectedServices);
    const run = async () => {
      const result = await OnboardingService.getAllSchemes();
      if (isSuccess(result)) {
        setSchemes(result.value.data ?? []);
      } else {
        // setAppState(initialState);
      }
    };
    run();
    setSelectedScheme({ value: fundName, label: fundName });
    setValues({ cbo: cboCode, cho: choCode });
  }, [show]);
  const handleSelectScheme = (selectedOption: any) => {
    setSelectedScheme(selectedOption);
  };
  const schemeOptions: CustomOptionType[] = schemes?.map((obj) => ({
    label: obj.fundName,
    value: obj.fundName,
  }));
  let body = service
    .filter((service) => service.selected)
    .map((service) => service.type);
  const updateSelectServices = async () => {
    const resultselectservices = await OnboardingService.updateService(
      body,
      id
    );
    if (isSuccess(resultselectservices)) {
      close(false);
      //   setSelectedServices(resultselectservices.value.data);
    } else {
      // setAppState(initialState);
    }
  };

  const updateCorpFund = async () => {
    const resultselectservices = await OnboardingService.updateCorpFund(
      selectedScheme?.value || "",
      values.cbo,
      values.cho,
      id
    );
    if (isSuccess(resultselectservices)) {
      close(false);
      //   setSelectedServices(resultselectservices.value.data);
    } else {
      // setAppState(initialState);
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal();
          close(false);
          // setSelectedServices(selectedServices);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select the Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {service.map((obj, index) => (
              <div
                style={{
                  border: obj.selected
                    ? "1.5px solid #2294E5"
                    : "1.5px solid #A9A9A9",
                  boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: 8,
                  cursor: "pointer",
                }}
                className="m-2 d-flex col justify-content-between p-2 align-items-center"
                onClick={() => {
                  const updatedServices = [...service]; // Create a copy of the array
                  updatedServices[index] = {
                    ...updatedServices[index],
                    selected: !obj.selected,
                  }; // Update the selected property
                  setService(updatedServices); // Update the state
                }}
              >
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="p-2"
                >
                  <img
                    src={Image(obj.type)}
                    className="img-fluid ml-auto mr-auto"
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    width: "90%",
                  }}
                >
                  <div>
                    <b>{obj.name}</b>
                  </div>
                  <div style={{ fontSize: 12, color: "#929292" }}>
                    {obj.description}
                  </div>
                </div>
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {obj.selected ? <img src={TickIcon} /> : null}
                </div>
              </div>
            ))}
          </div>
          {service.find(
            (obj) => obj.name === "NPS" && obj.selected === true
          ) ? (
            <>
              <p className="m-2 mt-3" style={{ fontWeight: "600" }}>
                PFM Name <span className="text-danger">*</span>
              </p>
              <Select
                options={[
                  ...[{ label: "No Preference", value: "No Preference" }],
                  ...schemeOptions,
                ]}
                value={selectedScheme}
                onChange={handleSelectScheme}
                className="m-2"
              />
              <div className="m-2">
                <p className="m-0" style={{ fontWeight: "600" }}>
                  CBO No. <span className="text-danger">*</span>
                </p>
                <input
                  className="form-control"
                  type="text"
                  value={values.cbo}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      cbo: isNaN(parseInt(e.target.value))
                        ? ""
                        : parseInt(e.target.value).toString(),
                    })
                  }
                />
              </div>
              <div className="m-2">
                <p className="m-0" style={{ fontWeight: "600" }}>
                  CHO No. <span className="text-danger">*</span>
                </p>
                <input
                  className="form-control"
                  type="text"
                  value={values.cho}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      cho: isNaN(parseInt(e.target.value))
                        ? ""
                        : parseInt(e.target.value).toString(),
                    })
                  }
                />
              </div>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white"
            size="sm"
            type="button"
            onClick={() => {
              updateSelectServices();

              const npsSelected = service.find(
                (obj) => obj.name === "NPS" && obj.selected === true
              )
                ? true
                : false;

              if (npsSelected) {
                updateCorpFund();
              }
            }}
            disabled={
              service.find((obj) => obj.name === "NPS" && obj.selected === true)
                ? selectedScheme?.value.length !== 0 &&
                  values.cbo.length > 0 &&
                  values.cho.length > 0
                  ? false
                  : true
                : false
            }
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServicePopup;
