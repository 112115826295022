import classNames from "classnames";
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import { adminTableHeight } from "../../../../../utils/common.utils";
import "./style.scss";
export interface ITableData {
  total: number;
  page: number;
  limit: number;
}
interface ITable {
  data?: ITableData;
  changePage: (num: number) => void;
  changeLimit: (num: number) => void;
}

export const TablePagination: React.FC<ITable> = ({
  data = { total: 0, page: 1, limit: 10 },
  changePage,
  changeLimit,
  children,
}) => {
  let num = Math.ceil(data.total / data.limit);
  let arr = [];
  for (let i = 0; i < num; i++) arr.push(i);
  let pagesList = arr.map((item) => item + 1);

  let showPageNos = [
    data.page - 1,
    data.page - 2,
    data.page,
    data.page + 1,
    data.page + 2,
  ];
  showPageNos = showPageNos.filter(
    (item) => item > 0 && pagesList.includes(item)
  );
  if (showPageNos.length < 6) {
    if (pagesList.includes(data.page + 3)) showPageNos.push(data.page + 3);
  }
  if (showPageNos.length < 6) {
    if (pagesList.includes(data.page + 4)) showPageNos.push(data.page + 4);
  }
  showPageNos = showPageNos.sort((a, b) => a - b);
  showPageNos = showPageNos.slice(0, 5);

  let startFrom = (data.page - 1) * data.limit + 1;
  let endAt =
    startFrom + (data.limit - 1) > data.total
      ? data.total
      : startFrom + (data.limit - 1);

  return (
    <>
      <div
        className="custom-table"
        style={{ maxHeight: adminTableHeight(pagesList.length > 1) }}
      >
        {children}
      </div>
      <div className="tbl-pagination">
        <div className="info">
          {data.total > 0
            ? `Showing ${startFrom} to ${endAt} of ${data.total} entries`
            : ``}
        </div>
        <div className="paging me-4">
          {/* {data.total > data.limit && ( */}
          <select
            value={data.limit}
            onChange={(e) => changeLimit(parseInt(e.target.value))}
            className="form-control"
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          {/* )} */}
          {pagesList.length > 1 && (
            <ul>
              {data.page != 1 && (
                <li onClick={() => changePage(1)}>
                  <AiOutlineDoubleLeft />
                </li>
              )}
              {data.page != 1 && (
                <li onClick={() => changePage(data.page - 1)}>
                  <AiOutlineLeft />
                </li>
              )}
              {showPageNos.map((item, key) => {
                return (
                  <li
                    key={key}
                    className={classNames({ active: data.page == item })}
                    onClick={() => changePage(item)}
                  >
                    {item}
                  </li>
                );
              })}
              {data.page < Math.ceil(data.total / data.limit) && (
                <li onClick={() => changePage(data.page + 1)}>
                  <AiOutlineRight />
                </li>
              )}
              {data.page < Math.ceil(data.total / data.limit) && (
                <li
                  onClick={() => changePage(Math.ceil(data.total / data.limit))}
                >
                  <AiOutlineDoubleRight />
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
