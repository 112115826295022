import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { unWrapOption } from "../../../../utils/option.utils";
import CustomSearchBar from "../../../custom-search/custom-search";
import { adminTableHeight } from "../../../../utils/common.utils";

// this popup is for search memeber and add to batch
const SearchMemberPopup: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSubmit?: () => void;
  users?: TEligibleUserinfo[];
}> = ({ show, closeModal, onSubmit = () => { }, users = [] }) => {
  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setUsers(
    //   users.map((user) => {
    //     user.isChecked = e.target.checked;
    //     return user;
    //   })
    // );
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: TEligibleUserinfo
  ) => {
    // let copyUsers = [...users];
    // copyUsers.forEach((user) => {
    //   if (E.equals(user.uid, item.uid)) {
    //     user.isChecked = e.target.checked;
    //   }
    // });
    // setUsers(copyUsers);
  };

  const rows = (data: TEligibleUserinfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
        <tr key={index}>
          <td>
            <input
              className="form-check-input  cardCheckbox"
              type="checkbox"
              value={unWrapOption(item.uid, () => "")}
              aria-label="..."
              name={`checkbox-${unWrapOption(item.uid, () => "")}`}
              onChange={(e) => handleCheck(e, item)}
              checked={item.isChecked}
            />
          </td>
          <td>{unWrapOption(item.uid, () => "")}</td>
          <td>{unWrapOption(item.email, () => "")}</td>
          <td>{unWrapOption(item.mobile, () => "")}</td>
          <td>{"-NA-"}</td>
          <td>{unWrapOption(item.limit, () => 0)}</td>
          <td>{unWrapOption(item.amountToAdd, () => 0)}</td>
        </tr>
      ))
      : [
        <tr>
          <td className="text-secondary" colSpan={6}>
            <img src={process.env.PUBLIC_URL + '/No-data-found.png'} alt="no data found" className="img-fluid" />
          </td>
        </tr>,
      ];
  };

  return (
    <>
      <Modal show={show} onHide={() => closeModal()} centered size="xl">
        <Modal.Header>
          <Modal.Title> Search & add member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <CustomSearchBar
              boxShadow="none"
              height="auto"
              width="70%"
              text="Search by Name/ Mobile "
            />

            <div
              className="custom-table"
              style={{ maxHeight: adminTableHeight() }}
            >
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        className="form-check-input  cardCheckbox"
                        type="checkbox"
                        value="checkall"
                        aria-label="..."
                        name={`checkall`}
                        onChange={handleAllCheck}
                      />
                    </th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Card No.</th>
                    <th scope="col">Card Limit</th>
                    <th scope="col">Amount To be Added</th>
                  </tr>
                </thead>
                <tbody>{users && rows(users)}</tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={onSubmit}
          >
            Select Member
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchMemberPopup;
