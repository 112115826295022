import React, { useEffect, useState } from "react";
import { titleCase } from "../../../utils/common.utils";
import { RoutePaths } from "../../../utils/route.utils";
import Heading from "../heading";
import { CorporateUser } from "../../../api/admin/model/corporateUser";
import { Option, isSome } from "fp-ts/lib/Option";
import { TAllAvailableCategories } from "../../../models/onboarding.models";
import classNames from "classnames";
import { AdminListOfCategories } from "../../../api/admin/model/onboarding/adminListOfCategories";
import { data } from "./onboard-table";

const OnboardHeader: React.FC<{
  detail?: string;
  status?: string;
  setShowRejectPopup?: (show: boolean) => void;
  setShowApprovePopup?: (show: boolean) => void;
  listOfCat: TAllAvailableCategories[];
  businessUnit: string[];
  adminMaker: Option<CorporateUser>;
  defaultApprover: Option<CorporateUser>;
  alias: string;
  isNewClaimFlow: boolean;
  corporateAdmin: Option<CorporateUser>;
  selectedCategory: (
    catList: TAllAvailableCategories[]
  ) => AdminListOfCategories[];
}> = ({
  detail,
  status,
  setShowRejectPopup = (show: boolean) => {},
  setShowApprovePopup = (show: boolean) => {},
  listOfCat,
  businessUnit,
  adminMaker,
  defaultApprover,
  alias,
  isNewClaimFlow,
  corporateAdmin,
  selectedCategory,
}) => {
  const [buttonEnable, setButtonEnable] = useState(false);
  useEffect(() => {
    let selectedCategories = selectedCategory(listOfCat);
    if (isNewClaimFlow) {
      if (
        (alias ?? "").length > 0 &&
        selectedCategories.length > 0 &&
        (businessUnit ?? []).length > 0 &&
        isSome(adminMaker) &&
        isSome(defaultApprover) &&
        isSome(corporateAdmin)
      ) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    } else {
      if (
        (alias ?? "").length > 0 &&
        selectedCategories.length > 0 &&
        (businessUnit ?? []).length > 0
      ) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    }
  }, [
    alias,
    listOfCat,
    businessUnit,
    adminMaker,
    defaultApprover,
    isNewClaimFlow,
    corporateAdmin,
  ]);
  return (
    <div className="d-flex justify-content-between my-2" id="sub-header">
      <Heading
        title="Onboarding"
        detail={detail}
        status={data.find((obj) => obj.state === status)?.label ?? "Error"}
        links={[RoutePaths.NavigateToOnboarding]}
      />
      <div className="d-flex justify-content-center align-items-center">
        {status && status === "REVIEW_PENDING" && (
          <>
            <button
              disabled={!buttonEnable}
              onClick={(e) => setShowApprovePopup(true)}
              className={`btn border-0 px-4 text-white  ms-2 ${classNames({
                ["bg-success"]: buttonEnable,
                ["bg-secondary"]: !buttonEnable,
              })}`}
            >
              Approve
            </button>
            <button
              onClick={(e) => setShowRejectPopup(true)}
              className="btn bg-danger border-0 px-4 text-white  ms-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
            >
              Reject
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardHeader;
