import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import CustomSearchBar from "../../../custom-search/custom-search";
import CorporateSubscriptionTable from "../corporate-subscription-table";

const ShowExistingUserPopup: React.FC<{
  cId: String;
  show?: boolean;
  onHide: () => void;
}> = ({ cId, show, onHide }) => {
  return (
    <Modal show={show} onHide={() => onHide()} size="xl" scrollable centered>
      <ModalHeader closeButton>
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>View Existing Subscriptions</h4>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row row-cols-1 gy-3">
            <div className="col d-flex justify-content-center">
              <CorporateSubscriptionTable cId={cId} />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShowExistingUserPopup;
