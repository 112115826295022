import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/hooks";
import { fromPublic } from "../../../utils/common.utils";
import { setCurrentScreen } from "../../app/app.slice";
import s from "./button.module.scss";

const ArrowLeft = fromPublic("/images/back.svg");

interface Props {
  text: string;
  path?: string;
  canGoBack?: boolean;
}

const BackButton: React.FC<Props> = ({ text, path = "", canGoBack = true }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    canGoBack && navigate(path);
  };
  return (
    <button className={s.backContainer} onClick={goBack}>
      {canGoBack && (
        <img className="img-fluid arrowLeft" src={ArrowLeft} alt="Back" />
      )}
      <span>{text}</span>
    </button>
  );
};

export default BackButton;
