const building = "/images/building.svg";
const edit = "/images/edit.svg";
const mail = "/images/mail.svg";
const phone = "/images/phone.svg";
const user = "/images/user.svg";
const lock = "/images/lock.svg";

export const images = {
  building,
  user,
  mail,
  phone,
  edit,
  lock,
};
