import * as success from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import { isSome, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { AdminAllEmployeesListInfo } from "../../../api/admin/model/all-employees/adminAllEmployeesListInfo";
import { useAppSelector } from "../../../hooks/hooks";
import { TAdminAllEmployeesListInfo } from "../../../models/allEmployees.models";
import { MakeOption } from "../../../models/make-option";
import { AllEmployeesService } from "../../../services/allEmployees.service";
import { AllEmployeesReduxStore } from "../../../state/slice/allEmployee.slice";
import { currencyFormat } from "../../../utils/format.utils";
import { unWrapOption } from "../../../utils/option.utils";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import SuccessPopup from "../../pages/dashboard/common/succes-popup";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
import ErrorPopup from "../common/ErrorPopup";
import ResetUserPassword from "../common/reset-user-password";
import TableLoader from "../common/table-loader";
import StatusText from "../status-text";
import { IDateRange } from "./all-employees";

const AllEmployeesTable: React.FC<{
  searchVal: string;
  filterDate: IDateRange;
  search: boolean;
}> = ({ searchVal, filterDate, search }) => {
  const filter = useAppSelector(AllEmployeesReduxStore.selectFilter);
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    show: false,
    name: "",
    uid: "",
  });
  const [items, setItems] =
    useState<Option<MakeOption<Required<AdminAllEmployeesListInfo>>[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<MakeOption<Required<AdminAllEmployeesListInfo>>[]>>(none);
  const [paging, setPaging] = React.useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  // const {
  //   data: allEmplyeesBasicInfos,
  //   isLoading,
  //   isSuccess,
  //   isFetching,
  //   isError,
  // } = useGetAllEmployeesList(
  //   filter.status,
  //   paging.page,
  //   paging.limit,
  //   format(
  //     new Date(filterDate.startDate ?? new Date("01-01-2020")),
  //     "dd-MM-yyyy"
  //   ),
  //   format(new Date(filterDate.endDate ?? new Date()), "dd-MM-yyyy")
  // );

  // const { data: allEmplyeesBasicInfos1 } = useGetAllEmployeesListPageCount(
  //   filter.status,
  //   paging.page,
  //   paging.limit,
  //   format(
  //     new Date(filterDate.startDate ?? new Date("01-01-2020")),
  //     "dd-MM-yyyy"
  //   ),
  //   format(new Date(filterDate.endDate ?? new Date()), "dd-MM-yyyy")
  // );
  // format(new Date(filterDate.startDate), "dd-MM-yyyy")
  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 10000,
      page: 1,
      limit: 10,
    }));
  }, [search, filter.status, filterDate]);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const result = await AllEmployeesService.getAllEmployeeList(
          filter.status,
          paging.page,
          paging.limit,
          format(
            new Date(filterDate.startDate ?? new Date("01-01-1960")),
            "dd-MM-yyyy"
          ),
          format(new Date(filterDate.endDate ?? new Date()), "dd-MM-yyyy")
        );

        // Handle the result here
        if (success.isSuccess(result)) {
          if (unWrapOption(result.value.successful, () => false)) {
            setLoading(false);
            const parsedData =
              AllEmployeesService.parseAdminAllEmployeeListInfo(
                result.value.data
              );

            // Update state with the parsed data
            // setItems(parsedData);
            setFilteredData(some(parsedData));
            setPaging((p) => ({
              ...p,
              total: unWrapOption(result.value.recordCount, () => 10),
              page: paging.page,
              limit: paging.limit,
            }));
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // Handle any unexpected errors here
        // You may want to set state accordingly or show an error message
      }
    }

    async function fetchDataSearch() {
      try {
        setLoading(true);
        const result = await AllEmployeesService.getAllEmployeeListSearch(
          searchVal,
          filter.status,
          paging.page,
          paging.limit
        );

        // Handle the result here
        if (success.isSuccess(result)) {
          if (unWrapOption(result.value.successful, () => false)) {
            setLoading(false);
            const parsedData =
              AllEmployeesService.parseAdminAllEmployeeListInfo(
                result.value.data
              );

            // Update state with the parsed data
            // setItems(parsedData);
            setFilteredData(some(parsedData));
            setPaging((p) => ({
              ...p,
              total: unWrapOption(result.value.recordCount, () => 10),
              page: paging.page,
              limit: paging.limit,
            }));
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // Handle any unexpected errors here
        // You may want to set state accordingly or show an error message
      }
    }

    if (searchVal.length === 0) {
      fetchData();
    } else {
      fetchDataSearch();
    }
  }, [paging.page, paging.limit, paging.total]);

  const ResetPassword = async (uid: string) => {
    const result = await AllEmployeesService.resetUserPassword(uid);
    if (success.isSuccess(result)) {
      if (result.value.successful === true) {
        setSuccessPopup({ show: true, message: result.value.message ?? "" });
        setResetPassword({ show: false, name: "", uid: "" });
      } else {
        setError({ show: true, message: result.value.message ?? "" });
      }
    } else {
      setError({ show: true, message: "Reset Failed" });
    }
  };

  // useEffect(() => {
  //   filterData(items);
  // }, [searchVal]);

  // const filterData = (
  //   allData: Option<MakeOption<Required<AdminAllEmployeesListInfo>>[]>
  // ) => {
  //   if (isSome(allData)) {
  //     let data = pipe(
  //       allData,
  //       fold(
  //         () => [],
  //         (d) => (d.length > 0 ? d : [])
  //       )
  //     );
  //     if (searchVal) {
  //       let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //       let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //       let filteredData = data.filter(
  //         (item) =>
  //           nameRegEx.test(unWrapOption(item?.name, () => "")) ||
  //           nameRegEx.test(unWrapOption(item?.email, () => "")) ||
  //           nameRegEx.test(unWrapOption(item?.corpName, () => "")) ||
  //           mobRegEx.test(unWrapOption(item?.mobile, () => ""))
  //       );
  //       setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //       setFilteredData(some(filteredData));
  //     } else {
  //       setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //       setFilteredData(allData);
  //     }
  //   }
  // };

  const rows = (data: TAdminAllEmployeesListInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td className="text-start">{unWrapOption(item?.name, () => "")}</td>
            <td className="text-start">
              {unWrapOption(item?.corpName, () => "-")}
            </td>
            <td className="text-start">
              {unWrapOption(item?.email, () => "-")}
            </td>
            <td className="text-center">
              {unWrapOption(item?.mobile, () => "-")}
            </td>
            <td className="text-start">
              {currencyFormat(unWrapOption(item?.salary, () => 0))}
            </td>
            <td className="text-start">
              {currencyFormat(unWrapOption(item?.monthlyFba, () => 0))}
            </td>
            <td className="text-start">
              {currencyFormat(unWrapOption(item?.fbaBalance, () => 0))}
            </td>

            {/* <td className="text-center">
              {unWrapOption(item?.taxRegime, () => "") === ""
                ? "-"
                : unWrapOption(item?.taxRegime, () => "") === "OLD_REGIME"
                ? "Old Regime"
                : "New Regime"}
            </td> */}
            <td className="text-center">
              {unWrapOption(item?.activatedAt, () => "-")}
            </td>
            <td
              className="text-center text-nowrap"
              style={{
                zIndex: "500",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{ width: filter.label === "All" ? 160 : undefined }}
                >
                  <StatusText
                    text={unWrapOption(item?.status, () => "")}
                    className={classNames({
                      color1:
                        unWrapOption(item?.status, () => "") ===
                        "Acceptance Pending",
                      color3:
                        unWrapOption(item?.status, () => "") === "Kyc Pending",
                      color14:
                        unWrapOption(item?.status, () => "") ===
                        "Kyc Initiated",
                      color7:
                        unWrapOption(item?.status, () => "") ===
                        "Kyc Submitted",
                      color16:
                        unWrapOption(item?.status, () => "") === "Kyc Rejected",
                      color2:
                        unWrapOption(item?.status, () => "") ===
                        "Activation Pending",
                      color4:
                        unWrapOption(item?.status, () => "") === "Activated",
                      color5:
                        unWrapOption(item?.status, () => "") === "Deactivated",
                      color6:
                        unWrapOption(item?.status, () => "") === "Blocked",
                      color8:
                        unWrapOption(item?.status, () => "") === "Expired",
                    })}
                  />
                </span>
                {unWrapOption(item?.status, () => "") !== "Deactivated" && (
                  <div
                    className="dropdown"
                    style={{
                      marginLeft: filter.label === "All" ? 10 : undefined,
                      border: 0,
                    }}
                  >
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "transparent",
                        backgroundImage: "none",

                        appearance: "none",
                        MozAppearance: "none",
                        WebkitAppearance: "none",
                        width: 1,
                        border: 0,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="black"
                        className="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16"
                        style={{ marginLeft: -8 }}
                      >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      </svg>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() =>
                            setResetPassword({
                              show: true,
                              name: unWrapOption(item.name, () => ""),
                              uid: unWrapOption(item.uid, () => ""),
                            })
                          }
                          style={{ fontSize: 14 }}
                        >
                          Reset Password
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={7}>
              <img
                src={process.env.PUBLIC_URL + "/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TAdminAllEmployeesListInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  if (loading) {
    return <TableLoader />;
  }

  // if (isError) {
  //   return <div>Error</div>;
  // }

  return (
    <div className="mt-3">
      {isSome(filteredData) && filteredData.value.length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          <table>
            <thead>
              <tr>
                <th scope="col">Employee Name</th>
                <th scope="col">Corporate Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Salary</th>
                <th scope="col">Monthly FBA</th>
                <th scope="col">FBA Balance</th>
                {/* <th scope="col">Tax Regime</th> */}
                <th scope="col">Activated At</th>

                <th scope="col" style={{ zIndex: "100" }}>
                  Status
                </th>
              </tr>
            </thead>

            <tbody>
              {isSome(filteredData) && rows(tblData(filteredData.value))}
            </tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}
      <ResetUserPassword
        data={{ name: resetPassword.name, uid: resetPassword.uid }}
        show={resetPassword.show}
        onHide={() => {
          setResetPassword({ show: false, name: "", uid: "" });
        }}
        onApprove={(val: string) => {
          ResetPassword(val);
        }}
      />
      <SuccessPopup
        message={successPopup.message}
        show={successPopup.show}
        onClose={(flag) => setSuccessPopup({ show: false, message: "" })}
      />
      <ErrorPopup
        errorText={error.message}
        show={error.show}
        onHide={() => setError({ show: false, message: "" })}
      />
    </div>
  );
};

export default AllEmployeesTable;
