import React, { useState } from "react";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import AllEmployeesSearch from "./all-employees-search";
import AllEmployeesStatusCount from "./all-employees-status-count";
import AllEmployeesTable from "./all-employees-table";
import DateRangeDropdown from "./date-range-dropdown";
export interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const AllEmployees: React.FC = () => {
  const [searchVal, setSearchVal] = useState<string>("");
  const [filterDate, setFilterDate] = useState<IDateRange>({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  return (
    <>
      <PageTitle title="Employees" />
      <Heading title="Employees" />
      <AllEmployeesSearch
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        search={search}
        setSearch={(val: boolean) => setSearch(val)}
        filterDate={filterDate}
        setFilterDate={(data: IDateRange) => setFilterDate(data)}
        onSubmit={onSubmit}
        setOnSubmit={(data: boolean) => setOnSubmit(data)}
      />
      <AllEmployeesStatusCount />
      <AllEmployeesTable
        searchVal={searchVal}
        filterDate={filterDate}
        search={search}
      />
    </>
  );
};

export default AllEmployees;
