import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { MembershipReduxStore } from "../../../state/slice/membership.slice";
import {
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../utils/common.utils";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
import { useGetMembershipBatchList } from "./hooks/membership.hooks";
import classNames from "classnames";
import { isSome } from "fp-ts/lib/Option";
import { Link } from "react-router-dom";
import { unWrapOption } from "../../../utils/option.utils";
import { TAdminMembershipBatchListInfo } from "../../../models/membership.models";
import { RoutePaths } from "../../../utils/route.utils";
import StatusText from "../status-text";
import TableLoader from "../../pages/dashboard/common/table-loader";
import NoTableData from "../../pages/dashboard/common/no-table-data";

const MembershipTable = () => {
  const filter = useAppSelector(MembershipReduxStore.selectFilter);
  const [paging, setPaging] = React.useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const {
    data: detaxBasicInfos,
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = useGetMembershipBatchList(filter.label);

  useEffect(() => {
    if (isSuccess && detaxBasicInfos)
      setPaging((p) => ({ ...p, total: detaxBasicInfos.length, page: 1 }));
  }, [detaxBasicInfos]);

  const rows = (data: TAdminMembershipBatchListInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td>
              <Link
                className="text-secondary ncash_nav-link"
                to={
                  RoutePaths.NavigateToMembershipDetails +
                  "/" +
                  unWrapOption(item?.cid, () => "") +
                  "/" +
                  unWrapOption(item?.batchCode, () => "")
                }
              >
                {unWrapOption(item?.batchCode, () => "")}
              </Link>
            </td>
            <td className="text-start">
              {unWrapOption(item?.corpName, () => "-")}
            </td>
            <td>{unWrapOption(item?.createdAt, () => "-")}</td>
            <td className="text-start">
              {unWrapOption(item?.batchAmount, () => 0)}
            </td>
            <td className="text-start text-nowrap" style={{ zIndex: "500" }}>
              <StatusText
                text={getTitleCase(unWrapOption(item?.batchStatus, () => ""))}
                className={classNames({
                  color1:
                    unWrapOption(item?.batchStatus, () => "") == "Requested",
                  color3:
                    unWrapOption(item?.batchStatus, () => "") == "Initiated",
                  color14:
                    unWrapOption(item?.batchStatus, () => "") == "Executed",
                  color7:
                    unWrapOption(item?.batchStatus, () => "") == "Approved",
                  color16:
                    unWrapOption(item?.batchStatus, () => "") == "Canceled",
                })}
              />
            </td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={7}>
              <img
                src={process.env.PUBLIC_URL + "/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TAdminMembershipBatchListInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  if (isLoading || isFetching) {
    return <TableLoader />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    // <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
    <div>
      {isSuccess && detaxBasicInfos && detaxBasicInfos.length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          <table>
            <thead>
              <tr>
                <th scope="col">Membership Request Code</th>
                <th scope="col">Corporate Name</th>
                <th scope="col">Created At</th>
                <th scope="col">Total Amount</th>
                <th scope="col" style={{ zIndex: "100" }}>
                  Status
                </th>
              </tr>
            </thead>

            <tbody>{detaxBasicInfos && rows(tblData(detaxBasicInfos))}</tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}
    </div>
  );
};

export default MembershipTable;
