import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { none, Option } from "fp-ts/lib/Option";
import { State } from "../../state/store";
import { TCorporateInfo } from "../../types/corporate-info.type";
import { TLeadApiInfo } from "../../types/lead/lead-api-info.type";

export interface CompanyState {
  companyInfo: Option<TCorporateInfo>;
  leadApiInfo: Option<TLeadApiInfo>;
}

const initialState: CompanyState = {
  companyInfo: none,
  leadApiInfo: none,
};

const slice = createSlice({
  name: "sa-company",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setCompanyInfo: (
      state,
      { payload: company }: PayloadAction<Option<TCorporateInfo>>
    ) => {
      state.companyInfo = company;
    },

    setLeadApiInfo: (
      state,
      { payload: lead }: PayloadAction<Option<TLeadApiInfo>>
    ) => {
      state.leadApiInfo = lead;
    },
  },
});

export const { setCompanyInfo, setLeadApiInfo } = slice.actions;

export const selectCompanyInfo = (state: State) => state.company.companyInfo;
export const selectLeadApiInfo = (state: State) => state.company.leadApiInfo;

export default slice.reducer;
