import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setIsAuthenticated } from "../../components/app/app.slice";
import { State } from "../../state/store";
import { CalculateTokenExpiryTime } from "../../utils/auth.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { none, Option } from "fp-ts/lib/Option";

export interface tokenPayload {
  accessToken: string;
  refreshToken: string;
  tokenExpiry: number;
  scope: string;
}

export interface TokenState {
  accessToken: string;
  refreshToken: string;
  tokenExpiry: number;
  expiresAt: number;
  scope: string;
  appToken: Option<string>; // this will use only when signup endpoint call
}

export const initialTokenState: TokenState = {
  accessToken: "",
  refreshToken: "",
  tokenExpiry: 0,
  expiresAt: 0,
  scope: "",
  appToken: none,
};

const slice = createSlice({
  name: "sa-token",
  initialState: initialTokenState,
  reducers: {
    initializeApp: () => initialTokenState,
    setToken: (state, { payload: token }: PayloadAction<tokenPayload>) => {
      state.accessToken = token.accessToken;
      state.refreshToken = token.refreshToken;
      state.tokenExpiry = token.tokenExpiry;
      state.scope = token.scope;
      state.expiresAt = CalculateTokenExpiryTime(token.tokenExpiry);
      MyStorage.AuthToken.StoreData({ ...token, expiresAt: state.expiresAt });
      setIsAuthenticated(true);
    },
    setAppToken: (state, { payload: token }: PayloadAction<Option<string>>) => {
      state.appToken = token;
    },
  },
});

export const { setToken, setAppToken } = slice.actions;

export const selectAccessToken = (state: State) => state.token.accessToken;
export const selectRefreshToken = (state: State) => state.token.refreshToken;
export const selectTokenExpiry = (state: State) => state.token.tokenExpiry;
export const selectAppToken = (state: State): Option<string> =>
  state.token.appToken;

export default slice.reducer;
