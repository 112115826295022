import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminTrialInfo } from "../../../api/admin/model/adminTrialInfo";
import { LeadRequestFilter } from "../../../api/admin/model/leadRequestFilter";
import { TrialCountInfo } from "../../../api/admin/model/trialCountInfo";
import { TrialService } from "../../../services/trial.service";
import { adminTableHeight, getTitleCase } from "../../../utils/common.utils";
import { RoutePaths } from "../../../utils/route.utils";
import TableLoader from "../../pages/dashboard/common/table-loader";
import StatusText from "../status-text";

const TrialTable: React.FC<{ curTbl?: TrialCountInfo | null }> = ({
  curTbl,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<Option<AdminTrialInfo[]>>(none);

  useEffect(() => {
    const run = async () => {
      const filter: LeadRequestFilter = {
        type: "FX",
        mobile: "",
        from: 0,
        to: 100,
        state: curTbl?.state,
      };

      setItems(none);
      const tblResult = await TrialService.fetchByState(filter);
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful)
          setItems(some(tblResult.value.data || []));
      }
    };
    run();
  }, [curTbl]);

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
        <table>
          <thead>
            <tr>
              <th scope="col">User ID</th>
              <th scope="col">Company Name</th>
              <th scope="col">User Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">Email ID</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {pipe(
              items,
              fold(
                () => [<div>No record found</div>],
                (items) =>
                  items.length > 0
                    ? items.map((item, key) => (
                        <tr key={key}>
                          <td>
                            <Link
                              className="text-secondary ncash_nav-link"
                              to={
                                RoutePaths.NavigateToTrialDetails +
                                "/" +
                                item.id
                              }
                            >
                              {item.id === "" ? "-" : item.id}
                            </Link>
                          </td>
                          <td>
                            {item.companyName === "" ? "-" : item.companyName}
                          </td>
                          <td>{item.name === "" ? "-" : item.name} </td>
                          <td>{item.mobile === "" ? "-" : item.mobile}</td>
                          <td>{item.email === "" ? "-" : item.email}</td>
                          <td className="text-center text-nowrap">
                            <StatusText
                              text={getTitleCase(item.state || "")}
                              className={classNames({
                                color6: item.state == "PENDING",
                                color7: item.state == "LIVE",
                                color8: item.state == "EXPIRED",
                              })}
                            />
                          </td>
                        </tr>
                      ))
                    : [
                        <tr>
                          <td className="text-secondary" colSpan={6}>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/No-data-found.png"
                              }
                              alt="no data found"
                              className="img-fluid"
                            />
                          </td>
                        </tr>,
                      ]
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrialTable;
