import { isSuccess } from "@devexperts/remote-data-ts";
import { getEq } from "fp-ts/Option";
import * as S from "fp-ts/string";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { BatchesService } from "../../../../services/batches.service";
import { Dispatch } from "../../../../state/dispatch";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import { adminTableHeight } from "../../../../utils/common.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import TableLoader from "../common/table-loader";
import TrasactionPopup from "./detail/transaction-popup";
import { useCreateTransactionBatchMutation } from "./hooks/transaction.hooks.";
import { selectedUsers } from "./txn.utils";

const E = getEq(S.Eq);

const EmployeeDataTable = () => {
  const filter = useAppSelector(DetaxReduxStore.selectFilter);

  //   const {
  //     data: users,
  //     isLoading,
  //     isSuccess,
  //     isFetching,
  //     isError,
  //   } = useGetEligibleUser(filter.type);
  const [show, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<TEligibleUserinfo[]>([]);

  const {
    mutateAsync: createTransactionBatch,
    isLoading: isUploadTransactionLoading,
  } = useCreateTransactionBatchMutation();

  useEffect(() => {
    const run = async () => {
      // no need to call eligible user from here add user from popup search selection
      // setIsLoading(true);
      // const result = await BatchesService.getEligibleUsers(filter.type);
      // if (isSuccess(result)) {
      //   setUsers(result.value);
      //   setIsLoading(false);
      // } else {
      //   setIsLoading(false);
      // }
    };
    run();
  }, [filter.type]);

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsers(
      users.map((user) => {
        user.isChecked = e.target.checked;
        return user;
      })
    );
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: TEligibleUserinfo
  ) => {
    //console.log("chiled :  ", e.target.checked, e.target.value);
    let copyUsers = [...users];
    copyUsers.forEach((user) => {
      if (E.equals(user.uid, item.uid)) {
        user.isChecked = e.target.checked;
      }
    });
    setUsers(copyUsers);
  };

  const handleUploadTransaction = async () => {
    const requestPayload = {
      data: users
        .filter((user) => user.isChecked)
        .map((user) => ({
          uid: unWrapOption(user.uid, () => ""),
          amount: unWrapOption(user.amountToAdd, () => 0),
        })),
      type: filter.type,
    };

    const result = await createTransactionBatch(requestPayload);
    if (isSuccess(result)) {
      setShowPopup(false);
      Dispatch.Detax.hideUploadTransaction();
    }
  };

  const rows = (data: TEligibleUserinfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td>
              <input
                className="form-check-input  cardCheckbox"
                type="checkbox"
                value={unWrapOption(item.uid, () => "")}
                aria-label="..."
                name={`checkbox-${unWrapOption(item.uid, () => "")}`}
                onChange={(e) => handleCheck(e, item)}
                checked={item.isChecked}
              />
            </td>
            <td>{unWrapOption(item.name, () => "")}</td>
            <td>{unWrapOption(item.email, () => "")}</td>
            <td>{unWrapOption(item.mobile, () => "")}</td>
            <td>{"-NA-"}</td>
            <td>{unWrapOption(item.limit, () => 0)}</td>
            <td>{unWrapOption(item.amountToAdd, () => 0)}</td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={6}>
              <img
                src={process.env.PUBLIC_URL + "/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
              />
            </td>
          </tr>,
        ];
  };

  if (isLoading) {
    return <TableLoader />;
  }

  const isChecked = selectedUsers(users).length;
  return (
    <>
      <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
        <table>
          <thead>
            <tr>
              <th scope="col">
                <input
                  className="form-check-input  cardCheckbox"
                  type="checkbox"
                  value="checkall"
                  aria-label="..."
                  name={`checkall`}
                  onChange={handleAllCheck}
                />
              </th>
              <th scope="col">Employee Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">E-mail</th>
              <th scope="col">Card No.</th>
              <th scope="col">Card Limit</th>
              <th scope="col">Amount To be Added</th>
            </tr>
          </thead>
          <tbody>{users && rows(users)}</tbody>
        </table>
      </div>
      {users && users.length > 0 && (
        <div className="mt-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => Dispatch.Detax.hideUploadTransaction()}
          >
            Remove
          </button>
          <button
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => setShowPopup(true)}
            disabled={!isChecked ? true : false}
          >
            Submit
          </button>
        </div>
      )}
      <TrasactionPopup
        show={show}
        closeModal={() => setShowPopup(false)}
        onSubmit={handleUploadTransaction}
        users={selectedUsers(users)}
      />
    </>
  );
};

export default EmployeeDataTable;
