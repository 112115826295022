import { useEffect, useState } from "react";
import logoLoader from "../../../output_Idbo5p.gif";

const CardLoader = () => {
  const [divHeight, setDivHeight] = useState(0);
  useEffect(() => {
    if (document) {
      if (document != null) {
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );
        var c = document
          ?.getElementById("init-loader")
          ?.getBoundingClientRect();
        var top = c?.top;
        setDivHeight(vh - (top || 0));
      }
    }
  });

  return (
    <>
      {divHeight > 0 && (
        <div
          id="card-loader"
          className="d-flex justify-content-center align-items-center"
          style={{ height: "-" + divHeight + "px" }}
        >
          <img src={logoLoader} alt="Loading.." width="100" height="100" />
        </div>
      )}
    </>
  );
};
export default CardLoader;
