import React from "react";
import { Stage } from "../../../api/detax/model/stage";

const MembershipTimelineHorizontal: React.FC<{ stages?: Array<Stage> }> = ({
  stages = [],
}) => {
  return (
    <>
      {stages.length > 0 ? (
        <div className="card">
          <div className="card-body py-0" id="horizontalTimeline">
            <div className="d-flex justify-content-between">
              {stages.map((stage, index) => (
                <div
                  key={index}
                  className={
                    stage.color === "GREEN"
                      ? "step w-25 completed"
                      : stage.color === "RED"
                      ? "step w-25 rejected"
                      : "step w-25"
                  }
                >
                  <h6 className="mb-0 text-capitalize fs-small">
                    {stage.displayName?.replace(/^\//, "").toLowerCase()}
                  </h6>
                  <div className="v-stepper text-start ms-4 mt-1">
                    <div className="circle"></div>

                    <div className="line"></div>
                  </div>
                  <p className="mb-0 opacity-75 fs-small">{stage.executedAt}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </>
  );
};

export default MembershipTimelineHorizontal;
