import classNames from "classnames";
import { Option, fromNullable, isSome, none } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { TAllAvailableCategories } from "../../../../models/onboarding.models";
import { unWrapOption } from "../../../../utils/option.utils";
import WhiteCard from "../../../white-card/white-card";
import { Button, Modal } from "react-bootstrap";
import SetUserCategory from "./set-user-category";
import { show } from "fp-ts";
import { GetEmployeesDataEmployeesRes } from "../../../../api/admin/model/maxLimitResponse";
import { useAppSelector } from "../../../../hooks/hooks";
import { ProfileSelect } from "../../../../state/slice/profile.slice";

const Pencil = "/images/icons/edit.svg";

type UpdateCategoryType = "Delete" | "Add";

const CategorySelection: React.FC<{
  proceed: boolean;
  setProceed: (val: boolean) => void;
  applicationStatus: String;
  catList: TAllAvailableCategories[];
  handleAddCategorySelection: (index: number) => void;
  handleDeleteCategorySelection: (index: number) => void;
  alias: string;
  setAlias: (val: string) => void;
  unSelectAllCategory: (val: TAllAvailableCategories[]) => void;
  updateAddCorporateCategory: () => void;
  updateDeleteCorporateCategory: () => void;
  cid: string;
  employeeList: GetEmployeesDataEmployeesRes[];
}> = ({
  proceed,
  setProceed,
  applicationStatus,
  catList,
  alias,
  handleAddCategorySelection,
  handleDeleteCategorySelection,
  setAlias,
  unSelectAllCategory,
  updateAddCorporateCategory,
  updateDeleteCorporateCategory,
  cid,
  employeeList,
}) => {
  const [updateType, setUpdateType] =
    useState<Option<UpdateCategoryType>>(none);

  const approvedSuperAdmin = useAppSelector(
    ProfileSelect.selectApprovedSuperAdmin
  );

  const handleSelectionByUpdateType = (index: number) => {
    if (isSome(updateType)) {
      if (updateType.value == "Add") {
        handleAddCategorySelection(index);
      } else {
        handleDeleteCategorySelection(index);
      }
    } else {
      console.log(
        "===> update category Type is undefined, so can't decide to add or delete in category selection"
      );
    }
  };

  const updateCorporateCategary = () => {
    if (isSome(updateType)) {
      if (updateType.value == "Add") {
        updateAddCorporateCategory();
      } else {
        updateDeleteCorporateCategory();
      }
    } else {
      console.log(
        "===> update category Type is undefined, so can't decide to add or delete in update api call"
      );
    }
  };

  if (!catList) {
    return (
      <>
        <p>something went wrong.. please refresh!!</p>
      </>
    );
  }
  return (
    <>
      <WhiteCard className="p-3">
        <div className="d-flex justify-content-between mb-2">
          <h6 className="text-secondary fw-bold">
            {isSome(updateType)
              ? updateType.value == "Add"
                ? "Add Category"
                : "Remove Category"
              : "Select Category"}
          </h6>

          {applicationStatus === "LIVE" && (
            <div>
              <button
                onClick={(e) => {
                  unSelectAllCategory(catList);
                  setUpdateType(() => fromNullable("Delete"));
                }}
                className={`${
                  isSome(updateType) && updateType.value == "Delete"
                    ? "btn bg-primary border-0 px-4 text-white  ms-2"
                    : "btn bg-light border border-2 border-secondary px-4 text-dark ms-2"
                }`}
              >
                Remove Category
              </button>
              <button
                onClick={() => {
                  unSelectAllCategory(catList);
                  setUpdateType((prev) => fromNullable("Add"));
                }}
                // className="btn bg-primary border-0 px-4 text-white  ms-2"
                className={`${
                  isSome(updateType) && updateType.value == "Add"
                    ? "btn bg-primary border-0 px-4 text-white ms-2"
                    : "btn bg-light border border-2 border-secondary px-4 text-dark ms-2"
                }
                `}
              >
                Add Category
              </button>
            </div>
          )}
        </div>

        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="row gy-2">
                {catList.length &&
                  catList.map((d, index) => (
                    <div className="col-6 col-md-5 mb-2" key={index}>
                      <div
                        className={`w-100 h-100 px-2 rounded border border-3 d-flex align-items-center
                        ${
                          isSome(updateType)
                            ? updateType.value == "Add"
                              ? classNames({
                                  ["bg-success"]: d.isSelected,
                                  ["bg-secondary"]: !d.isEligibleToAdd,
                                  ["opacity-50"]: !d.isEligibleToAdd,
                                })
                              : classNames({
                                  ["bg-danger"]: d.isSelected,
                                  ["bg-secondary"]: d.isEligibleToAdd,
                                  ["opacity-50"]: d.isEligibleToAdd,
                                })
                            : classNames({
                                ["bg-success"]: d.isSelected,
                                ["bg-secondary"]: !d.isEligibleToAdd,
                              })
                        }`}
                        onClick={() => {
                          if (applicationStatus === "LIVE") {
                            handleSelectionByUpdateType(index);
                          } else {
                            handleAddCategorySelection(index);
                          }
                        }}
                      >
                        <div className="my-3">
                          <p className={`m-0 fw-bold text-nowrap text-dark`}>
                            {unWrapOption(d.name, () => "")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {isSome(updateType) && (
            <div className="row">
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-center align-item-center">
                  <button
                    onClick={(e) => {
                      setUpdateType((prev) => none);
                      unSelectAllCategory(catList);
                    }}
                    className="btn bg-light border border-2 border-secondary px-4 text-dark ms-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      updateCorporateCategary();
                    }}
                    className="btn bg-primary border-0 px-4 text-white  ms-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {approvedSuperAdmin && (
          <>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <p style={{ fontWeight: "bold", margin: 0 }}>
                All the selected categories will be applicable to all employees
                in the corporate. Do you want to proceed?
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={!proceed}
                    onClick={() => {
                      setProceed(false);
                    }}
                  />
                  <p style={{ margin: 0, marginTop: 5, marginLeft: 3 }}>Yes</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 16,
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={proceed}
                    onClick={() => {
                      setProceed(true);
                    }}
                  />
                  <p style={{ margin: 0, marginTop: 5, marginLeft: 3 }}>No</p>
                </div>
              </div>
            </div>

            <SetUserCategory
              listOfCat={catList}
              cid={cid}
              setProceed={(val: boolean) => {
                setProceed(val);
              }}
              proceed={proceed}
              employeeList={employeeList}
            />
          </>
        )}
      </WhiteCard>
      {applicationStatus === "REVIEW_PENDING" && (
        <WhiteCard className="p-3">
          <div className="d-flex justify-content-between">
            <h6 className="text-secondary fw-bold">Set Corporate Alias Name</h6>
            <p></p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <span>
                  <p className="d-inline fs-small fw-bold">
                    Enter the corporate name that needs to be printed in the
                    card
                  </p>
                </span>
                <span className="ms-2">
                  <input
                    value={alias}
                    placeholder="Enter Alias"
                    onChange={(e) => {
                      if (e.target.value.length < 14) {
                        setAlias(e.target.value);
                      }
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </WhiteCard>
      )}

      {/* <ProceedPopup
        show={proceedPopup}
        onHide={() => {}}
        onApprove={() => {}}
        onCancel={() => {}}
      /> */}
    </>
  );
};

export default CategorySelection;

const CustomToggle = React.forwardRef(
  ({ onClick, children }: any, ref: any) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <svg
        width="20"
        height="20"
        viewBox="0 0 501 602"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.56752 14.251C-1.66168 23.2094 -0.911674 33.663 4.31231 42.6256L5.0571 43.3704L185.75 296.49V582.464C185.75 589.933 190.23 596.652 196.203 599.636C198.443 601.131 201.433 601.131 204.417 601.131C208.896 601.131 212.631 599.636 216.365 596.651L308.204 519.745C312.683 516.01 314.922 510.786 314.922 505.557V296.49L495.616 43.37L496.361 42.6252C501.59 33.6668 502.335 23.2132 497.105 14.2506C491.876 5.29217 482.917 0.0625715 472.465 0.0625715H27.4653C17.7571 -0.682215 8.04933 5.29177 3.56933 14.2506L3.56752 14.251Z"
          fill="#5C6874"
        />
      </svg>
    </a>
  )
);

const ProceedPopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onApprove: () => void;
  onCancel: () => void;
}> = ({ show, onHide, onApprove, onCancel }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-success d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#10003;</div>
        <Modal.Title className="text-light">Confirmation</Modal.Title>
        <p className="modal-title text-white">
          All the selected categories will be applicable to all employees in the
          corporate. Do you want to proceed?
        </p>
      </Modal.Header>

      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onCancel()}
        >
          No
        </Button>
        <Button variant="success" onClick={() => onApprove()}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
