import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";
import { MembershipAdminBatchDetailApiResponse } from "../model/MembershipAdminBatchDetailApiResponse";
import { MembershipSubscriptionListApiResponse } from "../model/MembershipSubscriptionListApiResponse";
import { MembershipBatchCountApiResponse } from "../model/membershipBatchCountApiResponse";
import { AdminMembershipBatchListApiResponse } from "../model/membershipBatchListApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

export namespace NcaishMembershipBatchApiService {
  export const getMembershipBatchCounts = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipBatchCountApiResponse>> => {
    const path =
      `/secure/corporates/membership/batch/admin/status/counts`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getMembershipBatchByStatus = async (
    status?: string,
    cid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminMembershipBatchListApiResponse>> => {
    const path = `/secure/corporates/membership/batch/${status}`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getMembershipBatchDetailByBatchAndCorpId = async (
    cId: string,
    batchId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipAdminBatchDetailApiResponse>> => {
    const path =
      `/secure/corporates/membership/batch/admin/${cId}/${batchId}`.replace(
        /^\//,
        ""
      );

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateMembershipBatchDetailByBatchAndCorpId = async (
    cId: String,
    batchId: String,
    status: String,
    amtRecieved?: Number | 0,
    transId?: String | "",
    transDate?: String | "",
    paymentMadeDate?: String | "",
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipAdminBatchDetailApiResponse>> => {
    let path;

    if (status === "execute") {
      path =
        `/secure/corporates/membership/batch/admin/${cId}/${batchId}/${status}d/${paymentMadeDate}`.replace(
          /^\//,
          ""
        );
    } else if (status === "approve") {
      path =
        `/secure/corporates/membership/batch/admin/${cId}/${batchId}/${status}d`.replace(
          /^\//,
          ""
        );
      // path =
      //   "/secure/corporates/membership/batch/admin/6290afdb5cafda331e242582/MR-004/approved";
    } else {
      path =
        `/secure/corporates/membership/batch/admin/${cId}/${batchId}/${status}`.replace(
          /^\//,
          ""
        );
    }

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const body = {
      amountRecieved: amtRecieved,
      transactionId: transId,
      transactionDate: transDate,
    };

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;
    let axiosRequestConfig: AxiosRequestConfig;
    if (status === "initiate") {
      axiosRequestConfig = {
        url: apiUrl,
        method: "put",
        data: body,
        headers: { ...headers },
      };
    } else {
      axiosRequestConfig = {
        url: apiUrl,
        method: "put",
        headers: { ...headers },
      };
    }

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getMembershipCountWithCId = async (
    cId: string | String,
    type: string | String,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipBatchCountApiResponse>> => {
    //6290afdb5cafda331e242582 - testing cid to get value in UAT
    const path = `/secure/corporates/${cId}/membershiptype/${type}`;

    const allQueryParams: TQueryParam[] = [];

    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getMembershipSubcriptionListWithCId = async (
    cId: String,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipSubscriptionListApiResponse>> => {
    const path = `/secure/corporates/${cId}/membership/config`;

    const allQueryParams: TQueryParam[] = [];

    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
