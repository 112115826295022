import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { useAppSelector } from "../../../../../hooks/hooks";
import { DetaxReduxStore } from "../../../../../state/slice/detax.slice";
import PageTitle from "../../../../common/page-title";

import TableLoader from "../../common/table-loader";

import { getTabLabel } from "../txn.utils";
import TransactionDetailCard from "./transaction-detail-card";
import TransactionDetailHeading from "./transaction-detail-heading";
import { Option, isNone, isSome, none, fold } from "fp-ts/lib/Option";
import { boolean } from "io-ts";

const TransactionDetail = () => {
  const [reportEnableBtn, setReportEnableBtn] = useState(true);
  const { batchId } = useParams<{ batchId: string }>();
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const batchInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectBatchInfo
  );
  const batchNpsInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectNpsBatchInfo
  );
  const batchCode = isSome(batchInfoOpt)
    ? batchInfoOpt.value.batchCode || ""
    : "";

  const batchNpsCode = isSome(batchNpsInfoOpt)
    ? batchNpsInfoOpt.value.batchCode || ""
    : "";

  const detailLabel = ` > ${getTabLabel(activeTab)} > ${
    activeTab === "NPS" ? batchNpsCode : batchCode
  }`;
  const title = `Batch Details | ${
    activeTab === "NPS" ? batchNpsCode : batchCode
  }`;

  return (
    <div>
      <PageTitle title={title} />
      <div>
        {batchId ? (
          <>
            <TransactionDetailHeading
              text={detailLabel}
              activeTab={activeTab}
              reportEnableBtn={reportEnableBtn}
            />
            <TransactionDetailCard
              batchId={batchId}
              activeTab={activeTab}
              setReportEnableBtn={(val: boolean) => setReportEnableBtn(val)}
            />
            {/* <TransactionMemberTable batchId={batchId} /> */}
          </>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default TransactionDetail;
