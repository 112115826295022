import React from "react";
const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";

const UserRow: React.FC<{
  typename?: string;
  name?: string;
  mobile?: string;
  email?: string;
}> = ({ typename = "Employee Name", name = "", mobile = "", email = "" }) => {
  return (
    <div className="row border rounded mx-2">
      <div className="col-11">
        <div className="row p-2">
          <div className=" col-md-4">
            <div className="row">
              <div className="col-2 col-md-2 text-right">
                <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                  <img
                    src={UserIcon}
                    className="img-fluid ml-auto mr-auto"
                    alt="icon"
                    width="18"
                  />
                </div>
              </div>
              <div className="col-9 col-md-9 ps-0 text-left">
                <p className="mb-0 ncash_fs-12 text-muted">{typename}</p>
                <h5 className="fs-small fw-bold mb-0">{name}</h5>
              </div>
            </div>
          </div>
          <div className=" col-md-4">
            <div className="row">
              <div className="col-2 col-md-2 text-right">
                <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                  <img
                    src={PhoneIcon}
                    className="img-fluid ml-auto mr-auto "
                    alt="icon"
                    width="18"
                  />
                </div>
              </div>
              <div className="col-9 col-md-9 ps-0 text-left">
                <p className="mb-0 ncash_fs-12 text-muted">Mobile Number</p>
                <h5 className="fs-small fw-bold mb-0">{mobile}</h5>
              </div>
            </div>
          </div>
          <div className=" col-md-4">
            <div className="row">
              <div className="col-2 col-md-2 text-right">
                <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                  <img
                    src={EmailIcon}
                    className="img-fluid ml-auto mr-auto"
                    alt="icon"
                    width="18"
                  />
                </div>
              </div>
              <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                <p className="mb-0 ncash_fs-12 text-muted">E-mail</p>
                <h5 className="fs-small fw-bold mb-0">{email}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRow;
