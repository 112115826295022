import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminTrialInfo } from "../../../api/admin/model/adminTrialInfo";
import PageLoader from "../../../page-loader";
import { TrialService } from "../../../services/trial.service";
import { RoutePaths } from "../../../utils/route.utils";
import PageTitle from "../../common/page-title";
import WhiteCard from "../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import Heading from "../heading";
const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const BuildIcon = "/images/icons/building.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";

const TrialDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<AdminTrialInfo>({
    id: "",
    companyName: "",

    type: "FX",
    name: "",
    email: "",
    mobile: "",
    verification: {
      mobile: true,
      email: true,
      aadhar: true,
    },
    remarks: "",
    startDate: "",
    endDate: "",
    state: "PENDING",
  });

  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        setIsLoading(true);
        const result = await TrialService.detail(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful)
            setDetails((prev) => (prev = result.value.data || prev));
        }
        setIsLoading(false);
      }
    };
    run();
  }, [params.id]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageTitle title={`Trial details | ${params?.id}`} />
      <Heading
        title="Trial"
        detail={params?.id}
        status={details.state}
        links={[RoutePaths.NavigateToTrial]}
      />
      <WhiteCard className="p-3">
        <h6 className="text-secondary fw-bold">User Details</h6>
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={BuildIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        {" "}
                        Company Name{" "}
                      </p>
                      <h5 className="fs-small fw-bold mb-0">
                        {details.companyName}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={UserIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        {" "}
                        Employee Name
                      </p>
                      <h5 className="fs-small fw-bold mb-0">{details.name}</h5>
                    </div>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={PhoneIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted"> Mobile No </p>
                      <h5 className="fs-small fw-bold mb-0">
                        {details.mobile}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={EmailIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted"> Email </p>
                      <h5 className="fs-small fw-bold mb-0">{details.email}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WhiteCard>

      <WhiteCard className="p-3">
        <h6 className="text-secondary fw-bold">Trial Details</h6>
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-pink rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={CalendarIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        {" "}
                        Trial Start Date{" "}
                      </p>
                      <h5 className="fs-small fw-bold mb-0">
                        {details.startDate}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-yellow rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={CalendarIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        {" "}
                        Trial End Date
                      </p>
                      <h5 className="fs-small fw-bold mb-0">
                        {details.endDate}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WhiteCard>
    </>
  );
};

export default TrialDetails;
