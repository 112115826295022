import { pipe } from "fp-ts/lib/function";
import { getOrElse, map, none, Option, some } from "fp-ts/lib/Option";
import { MembershipCountInfo } from "../api/admin/model/membershipCountInfo";
import { TrialCountInfo } from "../api/admin/model/trialCountInfo";
import { AdminCorporateCountInfo } from "../api/corporate/model/adminCorporateCountInfo";
import { TBrReceipient } from "../types/br-receipient.type";
import { TCorporateInfo } from "../types/corporate-info.type";
import { TCorporateService } from "../types/corporate-service.type";
import { TCorporateUser } from "../types/corporate-user.type";
import { unWrapOption } from "./option.utils";

export function getDirectorFromBrInfo(
  receipients: TBrReceipient[]
): TCorporateUser[] {
  const directors: TCorporateUser[] = receipients
    .filter((rec) => unWrapOption(rec.type, () => "") === "DIRECTOR")
    .map((d) => ({
      userId: d.userId,
      name: d.name,
      email: d.email,
      mobile: d.mobile,
    }));
  return directors;
}

export function getSignatoryFromBrInfo(receipients: TBrReceipient[]) {
  const signatories: TBrReceipient[] = receipients.filter(
    (rec) => unWrapOption(rec.type, () => "") === "SIGNATORY"
  );
  return signatories;
}

export const getCorpInfo = (corpInfo: Option<TCorporateInfo>) =>
  pipe(
    corpInfo,
    map((corp) => ({
      name: corp.name,
      cin: corp.cin,
      services: corp.services,
      directors: getDirectorFromBrInfo(corp.brInfo.receipients),
      receipients: getSignatoryFromBrInfo(corp.brInfo.receipients),
      brInfoSentOn: corp.brInfo.sentOn,
      brInfoStatus: corp.brInfo.status,
    })),
    getOrElse(() => ({
      name: some(""),
      cin: some(""),
      services: [] as TCorporateService[],
      directors: [] as TCorporateInfo["directors"],
      receipients: [] as TCorporateInfo["brInfo"]["receipients"],
      brInfoSentOn: some("N/A"),
      brInfoStatus: some("PENDING"),
    }))
  );

export const getSubmittedInfo = (corpInfo: Option<TCorporateInfo>) =>
  pipe(
    corpInfo,
    map((corp) => ({
      name: corp.name,
      cin: corp.cin,
      services: corp.services,
      directors: getDirectorFromBrInfo(corp.brInfo.receipients),
      receipients: corp.brInfo.receipients,
      brInfoSentOn: corp.brInfo.sentOn,
      brInfoStatus: corp.brInfo.status,
      brInfo: corp.brInfo,
    })),
    getOrElse(() => ({
      name: some(""),
      cin: some(""),
      services: [] as TCorporateService[],
      directors: [] as TCorporateInfo["directors"],
      receipients: [] as TCorporateInfo["brInfo"]["receipients"],
      brInfoSentOn: some("N/A"),
      brInfoStatus: some("PENDING"),
      brInfo: {} as TCorporateInfo["brInfo"],
    }))
  );

export function addAllCorporateCount(data: AdminCorporateCountInfo[]) {
  const allCount: AdminCorporateCountInfo = {
    status: AdminCorporateCountInfo.StatusEnum.ALL,
    label: "All",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: AdminCorporateCountInfo[] = [allCount, ...data];
  return counts;
}

export function addAllTrialCount(data: TrialCountInfo[]) {
  const allCount: TrialCountInfo = {
    state: TrialCountInfo.StateEnum.ALL,
    label: "All",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: TrialCountInfo[] = [allCount, ...data];
  return counts;
}

export function addAllMembershipCount(data: MembershipCountInfo[]) {
  const allCount: MembershipCountInfo = {
    status: "ALL",
    label: "All",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: MembershipCountInfo[] = [allCount, ...data];
  return counts;
}
