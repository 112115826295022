import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { filter } from "fp-ts/lib/Filterable";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OnboardingService } from "../../../services/onboarding.service";
// import { TOnboardingItem } from "../../../types/admin/onboarding-item.type";
import { TKitmanagementItem } from "../../../types/admin/kitmanagement-item.type";
import {
  adminTableHeight,
  getTitleCase,
  titleCase,
} from "../../../utils/common.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";
import StatusText from "../status-text";
import { KitManagementService } from "../../../services/kitmanagement.service";
import { fromPublic } from "../../../utils/common.utils";
import DeletePopup from "../../pages/dashboard/common/delete-popup";
import Form from "react-bootstrap/Form";
import ReactDOM from "react-dom";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import ThreeDot from "../../../../public/images/icons/menus/ThreeDot";

const ArrowDown = fromPublic("/images/icons/arrow-down-fill.svg");

// type TDropdownButton = {
//   className?: string;
//   btnName?: string;
// };

const CustomToggle = React.forwardRef(
  ({ onClick, children }: any, ref: any) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <svg
        width="20"
        height="20"
        viewBox="0 0 501 602"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.56752 14.251C-1.66168 23.2094 -0.911674 33.663 4.31231 42.6256L5.0571 43.3704L185.75 296.49V582.464C185.75 589.933 190.23 596.652 196.203 599.636C198.443 601.131 201.433 601.131 204.417 601.131C208.896 601.131 212.631 599.636 216.365 596.651L308.204 519.745C312.683 516.01 314.922 510.786 314.922 505.557V296.49L495.616 43.37L496.361 42.6252C501.59 33.6668 502.335 23.2132 497.105 14.2506C491.876 5.29217 482.917 0.0625715 472.465 0.0625715H27.4653C17.7571 -0.682215 8.04933 5.29177 3.56933 14.2506L3.56752 14.251Z"
          fill="#5C6874"
        />
      </svg>
    </a>
  )
);

const KitTable: React.FC<{ selectedState: string; searchVal?: string }> = ({
  selectedState,
  searchVal = "",
}) => {
  const [items, setItems] = useState<Option<TKitmanagementItem[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<TKitmanagementItem[]>>(none);
  const [lastPage, setLastPage] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const [set, setSet] = useState(" ");
  const onChangeType = (event: any) => {
    setSet(event.target.value);
  };
  useEffect(() => {
    setItems(none);
    const run = async () => {
      const result = await KitManagementService.onboardingListByEmployee(set);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          setPaging((p) => ({
            ...p,
            total: (result.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setItems(some(result.value.data));
          filterData(some(result.value.data || []));
        }
      }
    };
    run();
  }, [set]);
  // FOR STATUS FILTER
  const [status, setStatus] = useState(" ");
  const handlechange = (event: any) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    setItems(none);
    const run = async () => {
      const result = await KitManagementService.onboardingListByStatus(status);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          setPaging((p) => ({
            ...p,
            total: (result.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setItems(some(result.value.data));
          filterData(some(result.value.data || []));
        }
      }
    };
    run();
  }, [status]);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  useEffect(() => {
    //if (selectedState === null) {
    setItems(none);
    const run = async () => {
      const result = await KitManagementService.onboardingListByState1(
        selectedState
      );

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          setPaging((p) => ({
            ...p,
            total: (result.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setItems(some(result.value.data));
          filterData(some(result.value.data || []));
        }
      }
    };
    run();
    //}
  }, [selectedState]);

  const tblData = (tblData: TKitmanagementItem[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };
  const [value, setValue] = useState(0);
  useEffect(() => filterData(items), [searchVal]);

  const filterData = (allData: Option<TKitmanagementItem[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal) {
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");

      let filteredData = data;
      // data.filter(
      //   (item) =>
      //     nameRegEx.test(item?.name || "") || mobRegEx.test(item?.mobile || "")
      // );

      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (datas) =>
            datas.length
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Kit Number</th>
                          <th scope="col" style={{ zIndex: "300" }}>
                            <div className="d-flex justify-content-evenly">
                              Type
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  onChange={onChangeType}
                                />
                                <Dropdown.Menu onChange={onChangeType}>
                                  <div className="d-flex col mt-2">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSet("EMPLOYEE");
                                        {
                                          value === 1
                                            ? window.location.reload()
                                            : setValue(1);
                                        }
                                      }}
                                    >
                                      EMPLOYEE
                                    </Dropdown.Item>
                                    {value === 1 ? (
                                      <svg
                                        width="55pt"
                                        height="30pt"
                                        version="1.1"
                                        viewBox="0 0 700 700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                                      >
                                        <g>
                                          <path
                                            d="m294.62 396.03-97.555-97.551 23.746-23.801 73.809 73.809 184.57-184.52 23.746 23.742z"
                                            fill="#5C6874"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Dropdown.Divider />
                                  <div className="d-flex col mt-2">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSet("CORPORATE");
                                        {
                                          value === 2
                                            ? window.location.reload()
                                            : setValue(2);
                                        }
                                      }}
                                    >
                                      CORPORATE
                                    </Dropdown.Item>
                                    {value === 2 ? (
                                      <svg
                                        width="55pt"
                                        height="30pt"
                                        version="1.1"
                                        viewBox="0 0 700 700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                                      >
                                        <g>
                                          <path
                                            d="m294.62 396.03-97.555-97.551 23.746-23.801 73.809 73.809 184.57-184.52 23.746 23.742z"
                                            fill="#5C6874"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </th>
                          <th scope="col" style={{ zIndex: "200" }}>
                            <div className="d-flex justify-content-evenly">
                              Status
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  onChange={handlechange}
                                />
                                <Dropdown.Menu>
                                  <div className="d-flex col mt-2">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setStatus("USED");
                                        {
                                          value === 3
                                            ? window.location.reload()
                                            : setValue(3);
                                        }
                                      }}
                                    >
                                      USED
                                    </Dropdown.Item>
                                    {value === 3 ? (
                                      <svg
                                        width="55pt"
                                        height="30pt"
                                        version="1.1"
                                        viewBox="0 0 700 700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                                      >
                                        <g>
                                          <path
                                            d="m294.62 396.03-97.555-97.551 23.746-23.801 73.809 73.809 184.57-184.52 23.746 23.742z"
                                            fill="#5C6874"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Dropdown.Divider />
                                  <div className="d-flex col mt-2">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setStatus("AVAILABLE");
                                        {
                                          value === 4
                                            ? window.location.reload()
                                            : setValue(4);
                                        }
                                      }}
                                    >
                                      AVAILABLE
                                    </Dropdown.Item>
                                    {value === 4 ? (
                                      <svg
                                        width="55pt"
                                        height="30pt"
                                        version="1.1"
                                        viewBox="0 0 700 700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                                      >
                                        <g>
                                          <path
                                            d="m294.62 396.03-97.555-97.551 23.746-23.801 73.809 73.809 184.57-184.52 23.746 23.742z"
                                            fill="#5C6874"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Dropdown.Divider />
                                  <div className="d-flex col mt-2">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setStatus("BLOCKED");
                                        {
                                          value === 5
                                            ? window.location.reload()
                                            : setValue(5);
                                        }
                                      }}
                                    >
                                      BLOCKED
                                    </Dropdown.Item>
                                    {value === 5 ? (
                                      <svg
                                        width="55pt"
                                        height="30pt"
                                        version="1.1"
                                        viewBox="0 0 700 700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                                      >
                                        <g>
                                          <path
                                            d="m294.62 396.03-97.555-97.551 23.746-23.801 73.809 73.809 184.57-184.52 23.746 23.742z"
                                            fill="#5C6874"
                                          />
                                        </g>
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* <Dropdown.Item>hnjm</Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </th>
                          <th scope="col">Ref ID</th>
                          <th scope="col">Ref Name</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Created By</th>
                          <th scope="col">Used At</th>
                          <th scope="col">Blocked At</th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(datas).map((item, key) => (
                          <tr key={key}>
                            <td>
                              {/* <Link
                                className="text-secondary ncash_nav-link"
                                to={
                                  RoutePaths.NavigateToOnboardingDetails +
                                  "/" +
                                  item.id
                                }
                              > */}

                              {item.kitNumber === "" ? " - " : item.kitNumber}
                              {/* </Link> */}
                            </td>
                            <td>{item.type === "" ? " - " : item.type}</td>
                            <td>{item.status === "" ? " - " : item.status}</td>
                            <td>{item.refId === "" ? " - " : item.refId}</td>
                            <td>
                              {item.refName === "" ? " - " : item.refName}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.createdAt || "-"}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.createdBy || "-"}
                            </td>
                            <td>{item.usedAt === "" ? " - " : item.usedAt}</td>
                            <td>
                              {item.blockedAt === "" ? " - " : item.blockedAt}
                            </td>
                            {/* DELETE POPUP */}
                            {/* <td
                              onClick={() => {
                                setShowDeletePopup(true);
                                // setSelectedCorporateId(item.id || "");
                              }}
                            > */}
                            {/* DELETE POPUP */}
                            {/* <button
                                // className=""
                                onClick={() => {
                                  setShowDeletePopup(true);
                                  // setSelectedCorporateId(item.id || "");
                                }}
                              > */}{" "}
                            {/* DELETE ICON */}
                            {/* <svg
                                width="2em"
                                height="2em"
                                viewBox="0 0 104 118"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M98.6846 14.8198H75.4138L69.872 3.68265C69.4317 2.79735 68.7541 2.05204 67.9133 1.53107C67.0725 1.01009 66.1038 0.73291 65.1146 0.73291H39.7315C38.7424 0.73291 37.7737 1.01009 36.9329 1.53107C36.0921 2.05204 35.4144 2.79735 34.9741 3.68265L29.4323 14.8198H6.16155C4.26304 14.8198 2.5081 15.833 1.55813 17.4775C0.608877 19.122 0.608877 21.1485 1.55813 22.7938C2.50832 24.4383 4.26304 25.4515 6.16155 25.4515H10.5073V100.697C10.5073 105.279 12.3279 109.673 15.5677 112.914C18.8075 116.153 23.2017 117.973 27.784 117.973H77.0622C81.6445 117.973 86.0384 116.153 89.2785 112.914C92.5183 109.673 94.3389 105.279 94.3389 100.697V25.4515H98.6846C100.583 25.4515 102.338 24.4383 103.288 22.7938C104.237 21.1484 104.237 19.1218 103.288 17.4775C102.338 15.833 100.583 14.8198 98.6846 14.8198ZM43.0273 11.3648H61.8188L63.5467 14.8198H41.2995L43.0273 11.3648ZM83.7053 100.697C83.7053 102.459 83.0054 104.15 81.7586 105.396C80.5127 106.642 78.8228 107.342 77.0605 107.342H27.7822C26.02 107.342 24.33 106.642 23.0841 105.396C21.8373 104.15 21.1374 102.459 21.1374 100.697V25.4517H83.7053L83.7053 100.697Z"
                                  fill="#FF2A2A"
                                />
                                <path
                                  d="M52.4226 102.556C53.8325 102.556 55.1842 101.996 56.1816 101C57.1781 100.002 57.7381 98.6508 57.7381 97.2408V35.6957C57.7381 33.7963 56.7248 32.0416 55.0804 31.0923C53.4359 30.1421 51.4094 30.1421 49.765 31.0923C48.1205 32.0416 47.1073 33.7965 47.1073 35.6957V97.2265C47.1036 98.6384 47.6617 99.9936 48.6592 100.994C49.6566 101.993 51.011 102.555 52.4227 102.555L52.4226 102.556Z"
                                  fill="#FF2A2A"
                                />
                                <path
                                  d="M69.3929 102.556C70.8029 102.556 72.1545 101.996 73.152 101C74.1485 100.002 74.7084 98.6508 74.7084 97.2408V35.6957C74.7084 33.7963 73.6961 32.0416 72.0507 31.0923C70.4062 30.1421 68.3797 30.1421 66.7354 31.0923C65.0908 32.0416 64.0777 33.7965 64.0777 35.6957V97.2265C64.074 98.6384 64.632 99.9936 65.6295 100.994C66.6269 101.993 67.9813 102.555 69.3931 102.555L69.3929 102.556Z"
                                  fill="#FF2A2A"
                                />
                                <path
                                  d="M35.4522 102.556C36.8622 102.556 38.2138 101.996 39.2113 101C40.2078 100.002 40.7677 98.6508 40.7677 97.2408V35.6957C40.7677 33.7963 39.7545 32.0416 38.1101 31.0923C36.4655 30.1421 34.439 30.1421 32.7947 31.0923C31.1492 32.0416 30.137 33.7965 30.137 35.6957V97.2265C30.1333 98.6384 30.6914 99.9936 31.6888 100.994C32.6863 101.993 34.0407 102.555 35.4524 102.555L35.4522 102.556Z"
                                  fill="#FF2A2A"
                                />
                              </svg> */}
                            {/* DELETE ICON */}
                            {/* </td> */}
                            {/* DELETE POPUP */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
      <DeletePopup
        show={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        // onLogout={() => handleLogout()}
        onDelete={() => setShowDeletePopup(false)}
      />
    </div>
  );
};

export default KitTable;
