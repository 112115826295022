import { isSuccess } from "@devexperts/remote-data-ts";
import { Option, fromNullable, isNone, isSome, none } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AddSubscriptionPayload } from "../../../../api/admin/model/corporate/addSubscriptionPayload";
import { SubscriptionData } from "../../../../api/admin/model/corporate/subscriptionData";
import { AdminMembershipSubscriptionList } from "../../../../api/admin/model/membership/AdminMembershipSubscriptionList";
import PageLoader from "../../../../page-loader";
import { CorporateService } from "../../../../services/corporate.service";
import { MembershipService } from "../../../../services/membership.service";
import { unWrapOption } from "../../../../utils/option.utils";
import NoTableData from "../../../pages/dashboard/common/no-table-data";
import CorporateAddSubscription from "../corporate-add-subscription";

interface UserListDetails {
  userId?: String;
  userName?: String;
}

interface SubscriptionListDetails {
  categoryName?: String;
  subscriptionName?: String;
}

const AddNewSubscriptionPopUp: React.FC<{
  cId: String;
  show?: boolean;
  onHide: () => void;
  setSuccess: (message: string) => void;
  setFailure: (messgae: string) => void;
}> = ({ cId, show, onHide, setSuccess, setFailure }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [userList, setUserList] = useState<Option<UserListDetails[]>>(none);
  const [subscriptionList, setSubscriptionList] =
    useState<Option<SubscriptionListDetails[]>>(none);
  const [selectedUser, setSelectedUser] =
    useState<Option<UserListDetails>>(none);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Option<SubscriptionListDetails>>(none);

  const [addedUser, setAddedUser] = useState<SubscriptionData[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const run = async () => {
      if (cId?.length > 0) {
        const userResult = await CorporateService.getCorporateSubscriptionList(
          cId
        );
        if (isSuccess(userResult)) {
          if (userResult.value.successful) {
            const userDetail: Option<UserListDetails[]> = fromNullable(
              userResult?.value?.data?.map((item) => {
                return {
                  userId: item.uid,
                  userName: item.userName,
                };
              })
            );
            setIsLoading(true);
            if (isSome(userDetail)) {
              const subscriptionResult =
                await MembershipService.getMembershipSubcriptionListWithCId(
                  cId
                );
              if (isSuccess(subscriptionResult)) {
                if (
                  unWrapOption(subscriptionResult.value.successful, () => false)
                ) {
                  const val: AdminMembershipSubscriptionList = {
                    Food: [],
                    Health: [],
                  };

                  const entries = Object.entries(
                    unWrapOption(subscriptionResult.value.data, () => val)
                  );
                  let subscriptionDetail: SubscriptionListDetails[] | any[] =
                    [];
                  entries.map(([key, val]) =>
                    val.map((item: String) => {
                      subscriptionDetail.push({
                        categoryName: key,
                        subscriptionName: item,
                      });
                    })
                  );

                  if (subscriptionDetail.length > 0) {
                    setSubscriptionList(fromNullable(subscriptionDetail));
                  }
                  setUserList(userDetail);
                }
              }
            }
            setIsLoading(false);
          }
        }
      }
    };
    run();
  }, []);
  const handleUserSelection = (event: any) => {
    setSelectedUser(fromNullable(JSON.parse(event.target.value)));
  };
  const handleSubscriptionSelection = (event: any) => {
    setSelectedSubscription(fromNullable(JSON.parse(event.target.value)));
  };

  const handleAdd = () => {
    if (
      isSome(selectedUser) &&
      isSome(selectedSubscription) &&
      startDate &&
      endDate
    ) {
      let date: String;
      let month: String;
      const sd = startDate.getDate();
      const sm = startDate.getMonth() + 1;

      date = sd < 10 ? `0${sd}` : `${sd}`;
      month = sm < 10 ? `0${sm}` : `${sm}`;
      const subDate = `${date}-${month}-${startDate.getFullYear()}`;
      const ed = endDate.getDate();
      const em = endDate.getMonth() + 1;

      date = ed < 10 ? `0${ed}` : `${ed}`;
      month = em < 10 ? `0${em}` : `${em}`;
      const expDate = `${date}-${month}-${endDate.getFullYear()}`;

      const val: SubscriptionData = {
        uid: selectedUser?.value?.userId,
        userName: selectedUser?.value?.userName,
        subscriptionName: selectedSubscription?.value?.subscriptionName,
        subscriptionStartDate: subDate,
        subscriptionEndDate: expDate,
        SubscriptionCategories: selectedSubscription?.value?.categoryName,
      };
      setAddedUser([...addedUser, val]);
    } else {
      return;
    }
  };

  const removeUser = (val: number) => {
    // const value = [...addedUser].splice(val, 1);-curious to know why this is not working
    const value = [...addedUser].filter((item, index) => index !== val);
    setAddedUser(value);
  };

  const handleSubmit = async () => {
    if (addedUser.length > 0) {
      const val: AddSubscriptionPayload = { cid: cId, data: [...addedUser] };
      const result = await CorporateService.updateCorporateSubscriptionList(
        val
      );
      if (isSuccess(result)) {
        if (result.value.successful) {
          setStartDate(null);
          setEndDate(null);
          setAddedUser([]);
          setSuccess(result.value.message || "Success");
        } else {
          setStartDate(null);
          setEndDate(null);
          setAddedUser([]);
          setFailure(result.value.message || "Failed");
        }
      }
    } else {
    }
  };

  if (isLoading) {
    return (
      <Modal show={show} onHide={() => onHide()} size="xl" scrollable centered>
        <ModalHeader closeButton>
          <div className="container">
            <div className="row">
              <div className="col ">
                <h4>Add Subscription Details</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <PageLoader />
        </ModalBody>
      </Modal>
    );
  }

  if (isNone(userList) || isNone(subscriptionList)) {
    return (
      <Modal show={show} onHide={() => onHide()} size="xl" centered>
        <ModalHeader closeButton>
          <div className="container">
            <div className="row">
              <div className="col ">
                <h4>Add Subscription Details</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <NoTableData />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setStartDate(null);
        setEndDate(null);
        setAddedUser([]);
        onHide();
      }}
      size="xl"
      scrollable
      centered
    >
      <ModalHeader>
        <div className="container">
          <div className="row-col-1">
            <div className="col">
              <h4>Add Subscription Details</h4>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="row-col-1">
                    <div className="col">
                      <h6>Employee</h6>
                    </div>
                    <div className="col">
                      <select
                        className=" w-100 py-1 px-1"
                        defaultValue={""}
                        onChange={handleUserSelection}
                      >
                        <option value="" disabled hidden>
                          Select User
                        </option>
                        {isSome(userList) &&
                          userList.value.map((userDetail, index) => (
                            <option
                              key={index}
                              value={JSON.stringify(userDetail)}
                            >{`${userDetail.userName}`}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row-col-1">
                    <div className="col">
                      <h6>Subscription</h6>
                    </div>
                    <div className="col">
                      <select
                        className="w-100 py-1 px-1"
                        defaultValue={""}
                        onChange={handleSubscriptionSelection}
                      >
                        <option value="" disabled hidden>
                          Select Subscription
                        </option>
                        {isSome(subscriptionList) &&
                          subscriptionList?.value?.map(
                            (subscriptionDetail, index) => (
                              <option
                                key={index}
                                value={JSON.stringify(subscriptionDetail)}
                              >{`${subscriptionDetail.subscriptionName}`}</option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row-col-1">
                    <div className="col">
                      <h6>Date of Subscription</h6>
                    </div>
                    <div className="col">
                      <ReactDatePicker
                        selected={startDate}
                        maxDate={endDate ? endDate : null}
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText={"dd/mm/yyyy"}
                        onChange={(date) => setStartDate(date)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row-col-1">
                    <div className="col">
                      <h6>Date of Expiry</h6>
                    </div>
                    <div className="col">
                      <ReactDatePicker
                        selected={endDate}
                        minDate={startDate ? startDate : null}
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        dateFormat={"dd/MM/yyyy"}
                        placeholderText={"dd/mm/yyyy"}
                        onChange={(date) => setEndDate(date)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col pt-3 d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-1 justify-content-center"
                onClick={() => handleAdd()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row row-col-1">
            <div className="col">
              <CorporateAddSubscription
                cId={cId}
                userList={addedUser}
                removeUser={(val: number) => removeUser(val)}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <button
                className="btn-ncaish-light text-white px-3 py-2 btn w-50"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setAddedUser([]);
                  onHide();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 justify-content-center w-50"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewSubscriptionPopUp;
