import { Route } from "react-router-dom";
import { NcaishRegisterLeadApiResponse } from "../api/lead/model/ncaishRegisterLeadApiResponse";
import { UserVerificationResponse } from "../api/lead/model/userVerificationResponse";
import { numberOfVisitedPages } from "../components/app/app.slice";
import { NextActionEnum } from "../enum/next-screen.enum";
import { AuthResponse } from "../model/auth/authResponse";
import { LoginResponse } from "../model/auth/loginResponse";
import { SignupResponse } from "../model/auth/signupResponse";
import { CorpReviewApiResponse } from "../model/corpReviewApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { SendBrResponse } from "../model/sendBrResponse";
import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { ValidateCorpApiResponse } from "../model/validateCorpApiResponse";
import { store } from "../state/store";

export enum RoutePaths {
  // routes used in admin-app
  Home = "/",
  Login = "/login",
  LoginVerification = "/login-verify",

  // admin dasboard routes
  Onboarding = "onboarding",
  Trial = "trial",
  Corporate = "corporate",
  AllEmployees = "allEmployees",
  Employees = "employees",
  TrialDetails = "trialDetails",
  CorporateDetails = "corporateDetails",
  OnboardDetails = "onboardingDetails",
  EmployeesDetail = "employeesDetail",
  MembershipDetails = "membershipDetails",
  // FOR KIT
  KitManagement = "kitmanagement",
  Membership = "/dashboard/membership",

  NavigateToOnboarding = "/dashboard/onboarding",
  NavigateToOnboardingDetails = "/dashboard/onboardingDetails",
  NavigateToCorporate = "/dashboard/corporate",
  NavigateToAllEmployees = "/dashboard/allEmployees",
  NavigateToCorporateDetails = "/dashboard/corporateDetails",
  NavigateToEmployees = "/dashboard/employees",
  NavigateToEmployeesDetails = "/dashboard/employeesDetail",
  NavigateToTrial = "/dashboard/trial",
  NavigateToTrialDetails = "/dashboard/trialDetails",
  NavigateToMembershipDetails = "/dashboard/membershipDetails",
  // FOR KIT
  NavigateToKitManagement = "/dashboard/kitmanagement",

  SwitchAccount = "/switch/:switchToken",
  /**
   * Below routes are redundent for this app. Need to remove them
   */

  // Company Onboarding Routes
  ValidateCompany = "/", //CurrentScreen.VALIDATE,
  SignUp = "/signup", //CurrentScreen.SIGNUP,
  SignupVerification = "/signup-verify", //CurrentScreen.SIGNUP_VERIFICATION,
  CompanyReview = "/review", //CurrentScreen.COMPANY_REVIEW,
  DigitalContract = "/receipient", //CurrentScreen.CONTRACT_RECEIPIENT,
  SelectServices = "/services", //CurrentScreen.SELECT_SERVICES,
  ReviewApplication = "/app-review", //CurrentScreen.APPLICATION_REVIEW,
  ApplicationSubmitted = "/app-submitted", //CurrentScreen.APPLICATION_SUBMITTED,
  ForgotPassword = "/forgot-password",
  // Misc Routes

  UserDetails = "/user-details",
  DigitalContractSuccess = "/digital-contract-success",
  WelcomeBack = "/welcome",

  // Dashboard Routes
  EmployeesSearch = "/employeesSearch",
  EmployeesAdd = "/add-employees",

  DashboardMyProfile = "/dashboard/myProfile",
  DashboardChangePassword = "/dashboard/changePassword",
  DashboardHome = "/dashboard",
  DashboardEmployees = "/employees",
  DashboardDetax = "/detax",
  DashboardFxOnline = "/fx",
  DashboardNps = "/nps",
  DashboardAnalysis = "/analysis",
  DashboardTransaction = "/dashboard/transaction",
  DashboardClaims = "/claims",
  DashboardSettings = "/dashboard/settings",
  DetaxDetails = "/detax-details",
  PageLoader = "/loader",
  Review = "/review",
}

export const ScreenSteps: Array<{ page: string; step: number }> = [
  { page: RoutePaths.ValidateCompany, step: 1 },
  { page: RoutePaths.SignUp, step: 2 },
  { page: RoutePaths.SignupVerification, step: 2 },
  { page: RoutePaths.CompanyReview, step: 3 },
  { page: RoutePaths.DigitalContract, step: 4 },
  { page: RoutePaths.SelectServices, step: 5 },
  { page: RoutePaths.ReviewApplication, step: 6 },
  { page: RoutePaths.ApplicationSubmitted, step: 7 },
];

export const DashboardScreens: string[] = [
  RoutePaths.DashboardMyProfile,
  RoutePaths.DashboardChangePassword,
  RoutePaths.ForgotPassword,
  RoutePaths.DashboardHome,
  RoutePaths.DashboardEmployees,
  RoutePaths.DashboardDetax,
  RoutePaths.DashboardFxOnline,
  RoutePaths.DashboardNps,
  RoutePaths.DashboardAnalysis,
  RoutePaths.DashboardTransaction,
  RoutePaths.DashboardClaims,
  RoutePaths.DashboardSettings,
  RoutePaths.EmployeesSearch,
  RoutePaths.EmployeesAdd,
  RoutePaths.EmployeesDetail,
  RoutePaths.Onboarding,
  RoutePaths.Trial,
  RoutePaths.Corporate,
  RoutePaths.Employees,
  RoutePaths.TrialDetails,
  RoutePaths.CorporateDetails,
  RoutePaths.OnboardDetails,
  RoutePaths.ForgotPassword,
  // FOR KIT
  RoutePaths.KitManagement,
];

export const GetCompletedScreen = (currentScreen: string) => {
  // to do: add logic when signup and contract_review comes.
  // make it blue when we have number from 0 to return number
  const visitedPages: number[] = [];

  const currentStep =
    ScreenSteps.find((s) => s.page === currentScreen)?.step || 0;

  if (currentStep) {
    for (let i = 1; i < currentStep; i++) {
      visitedPages.push(i);
    }
    if (currentScreen === RoutePaths.ApplicationSubmitted) {
      store.dispatch(numberOfVisitedPages([1, 2, 3, 4, 5, 6, 7]));
    } else {
      store.dispatch(numberOfVisitedPages(visitedPages));
    }
  }
};

export function ConvertToRoutePath(
  nextAction?:
    | NextActionEnum
    | SignupResponse.NextActionEnum
    | AuthResponse.NextActionEnum
    | UpdateSignatoriesApiResponse.NextActionEnum
    | SendBrResponse.NextActionEnum
    | CorpReviewApiResponse.NextActionEnum
    | ValidateCorpApiResponse.NextActionEnum
    | LoginResponse.RedirectionEnum
    | CorpUpdateServiceApiResponse.NextScreenEnum
    | NcaishRegisterLeadApiResponse.NextActionEnum
    | UserVerificationResponse.NextActionEnum
): string | undefined {
  if (!nextAction) {
    return undefined;
  } else {
    switch (nextAction) {
      case NextActionEnum.SIGNUP:
        return RoutePaths.SignUp;
      case NextActionEnum.SIGNUPVERIFICATION:
        return RoutePaths.SignupVerification;
      case NextActionEnum.LEADVERIFICATION:
        return RoutePaths.SignupVerification;
      case NextActionEnum.COMPANYREVIEW:
        return RoutePaths.CompanyReview;
      case NextActionEnum.CONTRACTRECEIPIENT:
        return RoutePaths.DigitalContract;
      case NextActionEnum.SELECTSERVICES:
        return RoutePaths.SelectServices;
      case NextActionEnum.APPLICATIONREVIEW:
        return RoutePaths.ReviewApplication;
      case NextActionEnum.APPLICATIONSUBMITTED:
        return RoutePaths.ApplicationSubmitted;
      case NextActionEnum.DASHBOARD:
        return RoutePaths.DashboardHome;
      case NextActionEnum.LOGIN:
        return RoutePaths.Login;
      case NextActionEnum.LOGINVERIFICATION:
        return RoutePaths.LoginVerification;
      case NextActionEnum.FXRETAIL:
        return RoutePaths.DashboardFxOnline;
      case NextActionEnum.LEADREVIEW:
        return RoutePaths.Review;
      default:
        return nextAction;
    }
  }
}
