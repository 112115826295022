import { remoteData } from "@devexperts/remote-data-ts";
import { DashboardApiService } from "../api/dashboardApi.service";
import { DashboardResponse } from "../model/dashboard/dashboardResponse";

export namespace DashoardService {
    export const getDashboardData = async () => {
        const result = await DashboardApiService.dashboardData();
        return remoteData.map(result, parseDashboardData);
    };
    const parseDashboardData = (
        res: DashboardResponse
    ) => ({
        successful: res?.successful ? res.successful : false,
        message: res?.message ? res.message : "",
        detaxInfo: res?.detaxInfo ? res.detaxInfo : {
            eligibleUser: '',
            actiUser: '',
            activationPending: '',
            enrolmentPending: '',
            inActiveUser: '',
        },
        employeeInfo: res?.employeeInfo ? res.employeeInfo : {
            active: '',
            inActive: '',
            totalEmployee: '',
        },
        claimInfo: res?.claimInfo ? res.claimInfo : {
            totalClaims: '',
            approved: '',
            pending: '',
            rejected: '',
        },
        transactionInfo: res?.transactionInfo ? res.transactionInfo : {
            allTransactions: '',
            batchCreated: '',
            confPending: '',
            paymentReceived: '',
            subProcessed: '',
            subOnHold: '',
        },
    });
}