import { Modal } from "react-bootstrap";
import { KitManagementService } from "../../../../services/kitmanagement.service";
import React, { useState, useEffect } from "react";
import Button from "../../../common/button/button";
import { isSuccess } from "@devexperts/remote-data-ts";
import { unWrapOption } from "../../../../utils/option.utils";
import "././reject-popup.scss";

const AddNewKitPopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onDelete: (remark: string) => void;
}> = ({ show, onHide, onDelete }) => {
  const [remark, setRemark] = useState("");

  const cardStyle = {
    textAreaContainer: {
      height: 200,
      width: 460,
    },
    txtStyle1: {
      width: 460,
      height: 40,
      borderRadius: 3,
    },
  };
  const [txtArea, setTxtArea] = useState("");
  const [dis, setDis] = useState(true);
  const handleChange = (e: any) => {
    const re = /^[0-9\n]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setTxtArea(e.target.value);
      setDis(false);
    }
  };
  const arr = txtArea.split("\n");
  const [selectOption, setSelectOption] = useState("EMPLOYEE");
  const [selectOptionType, setSelectOptionType] = useState("RUPAY");
  const handleSelection = (event: any) => {
    setSelectOption(event.target.value);
  };

  const handleSelectionType = (event: any) => {
    setSelectOptionType(event.target.value);
  };
  const [btn, setBtn] = useState(false);
  const [isLoading, setLoader] = React.useState(false);
  // let navigate = useNavigate();
  const reloadfun = () => {
    window.location.reload();
  };

  const run = async () => {
    const result = await KitManagementService.createByNewKit(
      selectOption,
      arr,
      selectOptionType
    );
    // navigate("/dashboard/kitmanagement");
    reloadfun();
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false)) {
        // <KitTable selectedState=" " />;
        // console.log("the set is" + result.value);
        // RoutePaths.NavigateToKitManagement;
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide(false);
        setTxtArea("");
        setSelectOption("EMPLOYEE");
        setSelectOptionType("RURAY");
      }}
      centered
    >
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Modal.Header closeButton>
        <Modal.Title className=" fs-5 fw-bold">Kit Management</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fw-bold">Adding New Kits</p>
        <div className="col">
          <div className="form-group col-md-4">
            <label htmlFor="code">Type</label>
            <select
              aria-label="Default select example"
              onChange={handleSelection}
              style={{
                appearance: "auto",
                border: "auto",
                background: "initial",
                padding: "initial",
                // Add any other properties you need to reset
                ...cardStyle.txtStyle1, // Merge with additional styles from cardStyle.txtStyle1
              }}
            >
              <option value="EMPLOYEE">EMPLOYEE</option>
              <option value="CORPORATE">CORPORATE</option>
            </select>
          </div>
          <div className="mt-2"></div>
          <div className="form-group col-md-4">
            <label htmlFor="">Kit Number</label>
            {/* <Field name="area" /> */}
            <textarea
              // type="text"
              name="arr1"
              className="arr1"
              style={cardStyle.textAreaContainer}
              placeholder="Enter kit numbers"
              value={txtArea}
              onChange={handleChange}
            />
          </div>
          <div className="mt-2"></div>
          <div className="form-group col-md-4">
            <label htmlFor="">Kit Type</label>
            <select
              aria-label="Default select example"
              value={selectOptionType}
              onChange={handleSelectionType}
              style={{
                appearance: "auto",
                border: "auto",
                background: "initial",
                padding: "initial",
                // Add any other properties you need to reset
                ...cardStyle.txtStyle1, // Merge with additional styles from cardStyle.txtStyle1
              }}
            >
              <option value="RUPAY">Rupay Card</option>
              <option value="VISA">Visa Card</option>
            </select>
          </div>
        </div>
        <hr />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            onHide(false);
            setTxtArea("");
            setSelectOption("EMPLOYEE");
            setSelectOptionType("RUPAY");
          }}
          type="button"
          className="btn-ncaish-light px-3 py-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={dis}
          onClick={() => {
            run();
          }}
          className="btn-gradiant-blue px-3 py-2"
        >
          Submit
        </button>
      </Modal.Footer>
      {/* </form> */}
    </Modal>
  );
};

export default AddNewKitPopup;
