import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { AdminCorporateCountInfo } from "../../../api/corporate/model/adminCorporateCountInfo";

import { CorporateService } from "../../../services/corporate.service";
import { addAllCorporateCount } from "../../../utils/company.utils";
import WhiteCard from "../../white-card/white-card";
import CardLoader from "../common/card-loader";
// import DashboardPageLoader from "../common/dashboard-page-loader";
//TrialService
const CorporateStatusCount: React.FC<{
  setCurTbl: (tbl: AdminCorporateCountInfo) => void;
  curTbl: AdminCorporateCountInfo | null;
}> = ({ setCurTbl, curTbl }) => {
  const [counts, setCounts] = useState<Array<AdminCorporateCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const corporateStatusCount = await CorporateService.corporateCounts();
      setIsLoading(false);
      if (isSuccess(corporateStatusCount)) {
        if (corporateStatusCount.value.successful) {
          if ((corporateStatusCount.value.data || []).length > 0) {
            const counts = addAllCorporateCount(
              corporateStatusCount.value.data || []
            );
            setCounts(counts);
            setCurTbl(counts[0]);
          }
        }
      } else if (isFailure(corporateStatusCount)) {
        // alert(corporateStatusCount?.error?.outcome)
        console.log("corporateCounts: ", corporateStatusCount?.error?.outcome);
      }
    };
    run();
  }, []);

  return (
    <>
      {isLoading ? (
        <CardLoader />
      ) : (
        <div className="card-counts mt-4">
          {counts.map((item, key) => (
            <div
              key={key}
              onClick={() => setCurTbl(item)}
              className="card-count"
            >
              <WhiteCard
                className={classNames("p-2 w-75 status-dot-card", {
                  color0: item.status == "ALL",
                  color7: item.status == "ACTIVE",
                  color6: item.status == "INACTIVE",
                  color8: item.status == "BLOCKED",
                  active: curTbl?.status == item.status,
                })}
              >
                <div className="status-dot"></div>
                <h2 className="text-black text-center mb-0 fw-bold">
                  {item.count}
                </h2>
                <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                  {item.label}
                </p>
              </WhiteCard>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CorporateStatusCount;
