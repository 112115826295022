import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../common/reject-popup.scss";

const AnnualLimit: React.FC<{
  show: boolean;

  onHide: (show: boolean) => void;
  onApprove: () => void;
}> = ({ show, onHide, onApprove }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <style>{`.modal-dialog { max-width: ${550}px; }`}</style>
      <Modal.Header>
        <p className="m-0" style={{ fontWeight: "bold", fontSize: 18 }}>
          Confirm
        </p>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: 16 }} className="m-0">
          Annual Max Limit will be enabled for this corporate. All available
          limits for categories for which Max Limit is enabled will be updated
          for all users taking into consideration the remaining months in the
          Financial Year.
        </p>
        <span
          className="fs-bold text-danger"
          style={{ fontSize: 16, fontWeight: "bold" }}
        >
          Once this is turned on, it cannot be reverted back.
        </span>
      </Modal.Body>

      <Modal.Footer className=" d-flex justify-content-right">
        <Button
          className="btn-gradiant-gray btn-lg text-white fs-small m-1 me-2"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2"
          onClick={() => onApprove()}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AnnualLimit;
