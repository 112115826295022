import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../state/store";

export interface UserState {
  signup: { name: string; email: string; mobile: string };
  login: { mobile: string };
  signupId: string;
  verificationId: string;
}

const initialState: UserState = {
  signup: { name: "", email: "", mobile: "" },
  login: { mobile: "" },
  signupId: "",
  verificationId: "",
};

const slice = createSlice({
  name: "sa-signup",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setSignupUser: (
      state,
      {
        payload: user,
      }: PayloadAction<{ name: string; email: string; mobile: string }>
    ) => {
      state.signup = user;
    },
    setLoginUser: (
      state,
      { payload: user }: PayloadAction<{ mobile: string }>
    ) => {
      state.login = user;
    },
    setSignupId: (state, { payload: signupId }: PayloadAction<string>) => {
      state.signupId = signupId;
    },
    setVerificationId: (
      state,
      { payload: verificationId }: PayloadAction<string>
    ) => {
      state.verificationId = verificationId;
    },
  },
});

export const { setSignupUser, setLoginUser, setSignupId, setVerificationId } =
  slice.actions;

export const selectSignUp = (state: State) => state.signup.signup;

export const selectLogin = (state: State) => state.signup.login;

export const selectSignupId = (state: State) => state.signup.signupId;

export const selectVerificationId = (state: State) =>
  state.signup.verificationId;

export default slice.reducer;
