import React from "react";
import { Option, isNone, isSome, none, fold, some } from "fp-ts/lib/Option";

import { isSuccess } from "@devexperts/remote-data-ts";
import { NcaishCorporateApiService } from "../../../api/corporate/api/ncaishCorporateApi.service";

const BalanceAmount: React.FC<{ cid: string }> = ({ cid }) => {
  const [balance, setBalance] = React.useState<Option<number>>(none);

  React.useEffect(() => {
    const run = async () => {
      if (cid) {
        const result = await NcaishCorporateApiService.fetchBalance(cid);
        if (isSuccess(result)) {
          if (result.value.successful) {
            // dispatch(setCompanyInfo(result.value.data));
            setBalance(some(result.value.balance || 0));
          } else {
            //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
          }
        } else {
          //setBalance(some(0));
        }
      }
    };
    run();
  }, [cid]);

  if (isNone(balance)) {
    return <>loading...</>;
  }
  return <>₹{balance.value}</>;
};

export default BalanceAmount;
