import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Select, { ActionMeta, SingleValue } from "react-select";
import makeAnimated from "react-select/animated";
import { CorporateService } from "../../../../services/corporate.service";
import ErrorPopup from "../../../admin/common/ErrorPopup";
import { Option } from "../../../admin/onbaording/common/set-max-limit";
import SuccessPopup from "../common/succes-popup";

const backIcon = "/images/back.svg";

const editIcon = "/images/edit.svg";
const defaultIcon = "/images/default.svg";

interface props {}

const initialData = {
  subject: "",
  content: "",
  images: defaultIcon as any,
  cid: "",
  businessUnit: "",
};
const customStyles = {
  // container: (provided) => ({
  //   ...provided,
  //   width: 300,
  //   margin: '0 auto',
  // }),
  // control: (provided, state) => ({
  //   ...provided,
  //   backgroundColor: state.isFocused ? 'lightblue' : 'white',
  //   borderColor: state.isFocused ? 'blue' : 'gray',
  //   boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
  //   '&:hover': {
  //     borderColor: state.isFocused ? 'blue' : 'gray',
  //   },
  // }),
  valueContainer: (e: any) => ({
    ...e,
    fontSize: 14,
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    // border: "1px solid blue",
    zIndex: 999,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    // backgroundColor: state.isSelected ? 'blue' : state.isFocused ? 'lightblue' : 'white',
    // color: state.isSelected ? 'white' : 'black',
    // '&:hover': {
    //   backgroundColor: state.isSelected ? 'blue' : 'lightgray',
    //   color: state.isSelected ? 'white' : 'black',
    // },
  }),
  // singleValue: (provided) => ({
  //   ...provided,
  //   color: 'black',
  // }),
};

const Notifications: React.FC<props> = ({}) => {
  const animatedComponents = makeAnimated();
  const [corporateList, setCorporateList] = useState<Option[]>([]);
  const [businessUnitList, setBusinessUnitList] = useState<Option[] | null>();
  const [selectedCorpOption, setSelectedCorpOption] = useState<Option>();
  const [selectedBUOption, setSelectedBUOption] = useState<Option>();
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });
  const [data, setData] = useState(initialData);
  const [image, setImage] = useState<any>();
  const handleMultiSelectChangeCorp = (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ): void => {
    setSelectedCorpOption(newValue as Option);
    setData({ ...data, cid: newValue?.value ?? "", businessUnit: "" });
    setSelectedBUOption(undefined);
  };
  const handleMultiSelectChangeBU = (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ): void => {
    setSelectedBUOption(newValue as Option);
    setData({ ...data, businessUnit: newValue?.value ?? "" });
  };

  useEffect(() => {
    getCorporate();
  }, []);

  useEffect(() => {
    const run = async () => {
      if (data.cid.length > 0) {
        const result = await CorporateService.detail(data.cid);
        if (isSuccess(result)) {
          if (
            result.value.successful &&
            result.value.data &&
            result.value.data?.businessUnit
          ) {
            setBusinessUnitList(
              result.value.data?.businessUnit.map((obj) => ({
                label: obj ?? "",
                value: obj ?? "",
              }))
            );
          } else {
            setBusinessUnitList(null);
          }
        }
      }
    };
    run();
  }, [data.cid]);

  const getCorporate = async () => {
    const result = await CorporateService.fetchByState("ACTIVE", 1, 100000);
    if (isSuccess(result)) {
      if (
        result.value.successful &&
        result.value.data &&
        result.value.data.length > 0
      ) {
        setCorporateList(
          result.value.data?.map((obj) => ({
            label: obj.companyName ?? "",
            value: obj.id ?? "",
          }))
        );
      }
    }
  };

  const SendNotification = async () => {
    const formData = new FormData();

    formData.append("subject", data.subject);
    formData.append("content", data.content);

    if (typeof data.images !== "string") {
      formData.append("image", data.images);
    }

    if (data.businessUnit.length > 0) {
      formData.append("bu", data.businessUnit);
    }
    const result = await CorporateService.sendNotification(data.cid, formData);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setSuccessPopup({ show: true, message: result.value.message ?? "" });
        setData(initialData);
        setSelectedCorpOption(undefined);
      } else {
        setErr({ show: true, message: result.value.message ?? "" });
      }
    } else {
      setErr({ show: true, message: "Send Notification Failed" });
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      // setSelectedFileName(file.name);

      // if (file.size > 2097152) {
      //   // 2 MB in bytes
      //   alert("File size exceeds the maximum limit of 2 MB.");
      //   return;
      // }

      if (!file.type.startsWith("image/")) {
        alert("Only image files (JPG, JPEG, PNG) are allowed.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          // if (image.width !== 380 || image.height !== 180) {
          //   alert("Image dimensions must be exactly 380x180 pixels.");
          //   return;
          // }
          setImage(reader.result);
          setData({ ...data, images: file });
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        marginTop: 8,
        marginLeft: 8,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ width: "60%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="m-0" style={{ fontWeight: "bold", fontSize: 18 }}>
            Notifications
          </p>
        </div>
        <div className="mt-4">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className="mt-3"
          >
            <span style={{ width: "45%" }}>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Corporates<span className="text-danger">*</span>
              </p>
              <Select
                options={corporateList}
                value={selectedCorpOption}
                onChange={(newValue, actionMeta) =>
                  handleMultiSelectChangeCorp(
                    newValue as SingleValue<Option>,
                    actionMeta as ActionMeta<Option>
                  )
                }
                // isMulti
                components={animatedComponents}
                styles={customStyles}
                isClearable
                className="w-100 mt-1"
              />
            </span>
            {(businessUnitList?.length ?? 0) > 0 && businessUnitList && (
              <span style={{ width: "45%" }}>
                <p
                  className="m-0 "
                  style={{ fontWeight: "bold", fontSize: 14 }}
                >
                  Business Unit
                  {/* <span className="text-danger">*</span> */}
                </p>
                <Select
                  options={businessUnitList}
                  value={selectedBUOption}
                  onChange={(newValue, actionMeta) =>
                    handleMultiSelectChangeBU(
                      newValue as SingleValue<Option>,
                      actionMeta as ActionMeta<Option>
                    )
                  }
                  // isMulti
                  components={animatedComponents}
                  styles={customStyles}
                  isClearable
                  className="w-100 mt-1"
                />
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Title<span className="text-danger">*</span>
              </p>
              <input
                style={{
                  minHeight: 30,
                  maxHeight: 30,
                  width: 250,
                  fontSize: 14,
                }}
                value={data?.subject}
                onChange={(e) => {
                  setData({ ...data, subject: e.target.value });
                }}
                maxLength={50}
              />
              <p
                className="m-0 mt-1 "
                style={{ fontWeight: "bold", fontSize: 8 }}
              >
                Maximum 50 characters only allowed
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Description<span className="text-danger">*</span>
              </p>
              <textarea
                style={{
                  minHeight: 80,
                  maxHeight: 80,
                  width: 250,
                  fontSize: 14,
                }}
                value={data?.content}
                onChange={(e) => {
                  setData({ ...data, content: e.target.value });
                }}
              />
              <p
                className="m-0"
                style={{ fontWeight: "bold", fontSize: 8, marginBottom: -100 }}
              >
                Maximum {typeof data.images === "string" ? "100" : "50"}{" "}
                characters only allowed
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 8,
              justifyContent: "space-between",
            }}
          >
            <div>
              <p className="m-0 " style={{ fontWeight: "bold", fontSize: 14 }}>
                Image
                {/* <span className="text-danger">*</span> */}
              </p>
              <div
                style={{
                  minHeight: 120,
                  maxHeight: 120,
                  width: 250,
                  border: "2px dashed #d0d0db",
                }}
              >
                <Dropzone
                  onDrop={handleDrop}
                  accept=".jpg, .jpeg, .png"
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className="p-1 text-center my-1 mx-auto wrap-file-upload "
                      role="button"
                    >
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept=".png" />
                        <svg
                          id="Group_2844"
                          width="20"
                          data-name="Group 2844"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 158.798 190.158"
                        >
                          <path
                            id="Path_6474"
                            data-name="Path 6474"
                            d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                            transform="translate(-157.192 -168.825)"
                            fill="#cecece"
                          />
                          <rect
                            id="Rectangle_365"
                            data-name="Rectangle 365"
                            width="158.798"
                            height="21.5"
                            rx="10.75"
                            transform="translate(0 168.659)"
                            fill="#cecece"
                          />
                        </svg>
                        <div
                          className="w-100 px-4 mx-auto py-2"
                          style={{ fontSize: 12 }}
                        >
                          <p className="m-0 ">Drag and drop here </p>
                          <p className="m-0">or</p>
                          <p className="m-0">Browse Files</p>
                        </div>
                        <ul className="files-list">
                          {/* {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))} */}
                        </ul>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {/* <p
                className="m-0 mt-1 "
                style={{ fontWeight: "bold", fontSize: 8 }}
              >
                Image uploaded should be of the following dimension: 380*180
              </p> */}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
            className="mt-3"
          >
            <Button
              className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2 p-2"
              style={{ minWidth: 150 }}
              disabled={
                data.subject.length === 0 ||
                data.content.length === 0 ||
                data.cid.length === 0
              }
              onClick={() => {
                SendNotification();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="px-5"
      >
        <div
          style={{ border: "0.5px solid black", width: 280, borderRadius: 15 }}
        >
          <img
            style={{
              minHeight: 140,
              height: 140,
              width: 278,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              objectFit: "cover",
            }}
            alt="Campaign Image"
            src={image ? image : data.images}
          />
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}
            className="py-3"
          >
            <p
              className="m-0 px-2"
              style={{
                textAlign: "center",
                display: "flex",
                // textWrap: "wrap",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {data?.subject}
            </p>
            <p
              className="m-0 px-2"
              style={{
                textAlign: "center",
                display: "flex",
                // textWrap: "wrap",
                fontSize: 14,
              }}
            >
              {data?.content}
            </p>
            {/* <div
              style={{
                backgroundColor: "#105099",
                borderRadius: 25,
                color: "white",
              }}
              className="px-3 py-2 mt-2"
            >
              Continue
            </div> */}
          </div>
        </div>
      </div>
      <SuccessPopup
        show={successPopup.show}
        message={successPopup.message}
        onClose={() => {
          setSuccessPopup({ show: false, message: "" });
        }}
      />
      <ErrorPopup
        show={err.show}
        errorText={err.message}
        onHide={() => {
          setErr({ show: false, message: "" });
        }}
      />
    </div>
  );
};

export default Notifications;

export const epochToDate = (epoch: number): string => {
  const date = new Date(epoch); // Convert from seconds to milliseconds
  const split = date.toLocaleDateString().split("/");

  const final = `${split[1]}-${split[0]}-${split[2]}`;

  return final;
};
