import React from "react";

import { SelectedServicesInfo } from "../../../api/admin/model/models";
import WhiteCard from "../../white-card/white-card";
import ServicePopup from "./ServicePopup";
const Pencil = "/images/icons/edit.svg";
const ServiceSelected: React.FC<{
  // services: Array<CorporateServiceInfo>;
  selectedServices: SelectedServicesInfo[];
  setSelectedServices: (val: SelectedServicesInfo[]) => void;
  applicationStatus: string;
  editService: boolean;
  setEditService: (val: boolean) => void;
  id: string;
  fundName: string;
  cboCode: string;
  choCode: string;
}> = ({
  selectedServices,
  setSelectedServices,
  applicationStatus,
  setEditService,
  editService,
  id,
  fundName,
  cboCode,
  choCode,
}) => {
  // const selectedServices = services
  //   ? services.map(OnboardingService.parseService)
  //   : [];
  let buttonView =
    selectedServices.filter((service) => service.selected).length < 3;

  return (
    <WhiteCard className="p-3">
      <div className="d-flex justify-content-between">
        <h6 className="text-secondary fw-bold">Service Selected</h6>
        <p></p>
        {applicationStatus === "LIVE" && (
          //  && buttonView
          <div
            className=" btn p-1 me-3 border border-2 rounded-2"
            onClick={() => {
              // setEdit(true);
            }}
          >
            <img
              src={Pencil}
              className="img-fluid ml-auto mr-auto"
              alt="icon"
              width="18"
              onClick={() => setEditService(true)}
            />
          </div>
        )}
      </div>

      <div className="row mx-2">
        <div className="col-12">
          <div className="row">
            {selectedServices
              .filter((obj) => obj.selected)
              .map((item, key) => {
                return (
                  <div key={key} className=" col-md-4">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          {/* <img
                          src={item.img}
                          className="img-fluid ml-auto mr-auto w-50"
                          alt="icon"
                        /> */}

                          <span className="text-success me-2">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 20 20"
                              height="1.4em"
                              width="1.4em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left d-flex align-items-center">
                        <h6 className="mb-0  text-secondary fw-bold">
                          {item.name}
                        </h6>
                      </div>
                      {/* <p
                        className="m-0"
                        style={{
                          fontSize: 14,
                          fontWeight: "600",
                          // border: "1px solid black",
                        }}
                      >
                        {item.name === "NPS" ? `PFM : ${fundName}` : null}
                      </p> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {fundName.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-around",
          }}
        >
          <div>
            <div className="d-flex justify-content-between mt-3">
              <h6 className="text-secondary fw-bold">NPS - PFM</h6>
            </div>
            <div className="row " style={{ margin: 0 }}>
              <div className="col-12">
                <div className="row" style={{ fontWeight: "600" }}>
                  {fundName}
                </div>
              </div>
            </div>
          </div>
          {cboCode && (
            <>
              <div className="ms-5 pt-3">
                <div
                  style={{
                    border: "1px solid #dee2e6",
                    height: "50px",
                    borderRadius: 50,
                  }}
                />
              </div>
              <div className="ms-5">
                <div
                  className="d-flex justify-content-between mt-3"
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <h6 className="text-secondary fw-bold">CBO No.</h6>
                  <div className="row " style={{ margin: 0 }}>
                    <div className="col-12">
                      <div className="row" style={{ fontWeight: "600" }}>
                        {cboCode}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {cboCode && (
            <>
              <div className="ms-5 pt-3">
                <div
                  style={{
                    border: "1px solid #dee2e6",
                    height: "50px",
                    borderRadius: 50,
                  }}
                />
              </div>
              <div className="ms-5">
                <div className="d-flex justify-content-between mt-3">
                  <h6 className="text-secondary fw-bold">CHO No.</h6>
                </div>
                <div className="row " style={{ margin: 0 }}>
                  <div className="col-12">
                    <div className="row" style={{ fontWeight: "600" }}>
                      {choCode}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <ServicePopup
        show={editService}
        closeModal={() => {}}
        actionState1={false}
        selectedServices={selectedServices}
        setSelectedServices={(val: SelectedServicesInfo[]) =>
          setSelectedServices(val)
        }
        close={(val: boolean) => setEditService(val)}
        id={id}
        fundName={fundName}
        cboCode={cboCode}
        choCode={choCode}
      />
    </WhiteCard>
  );
};

export default ServiceSelected;
