import CustomSearchBar from "../../custom-search/custom-search";

const EmployeeSearch = () => {
  return (
    <div
      className="row border rounded-12"
      style={{ marginLeft: 5, marginRight: 10 }}
    >
      <CustomSearchBar
        height="auto"
        width="100%"
        text="Search by Name/ Mobile "
      />
    </div>
  );
};

export default EmployeeSearch;
