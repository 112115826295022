/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AuthResponse } from "../model/authResponse";
import { LoginRequest } from "../model/loginRequest";
import { LoginResponse } from "../model/loginResponse";
import { RegisterInvestorRequest } from "../model/registerInvestorRequest";
import { RegisterInvestorResponse } from "../model/registerInvestorResponse";
import { UserVerificationResponse } from "../model/userVerificationResponse";
import { VerifyDraftRequest } from "../model/verifyDraftRequest";
import { VerifyLoginApiRequest } from "../model/verifyLoginApiRequest";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishInternalAuthApiService {
  /**
   * Api to initiate login
   * @param body -
   */
  export const login = async (
    body: LoginRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, LoginResponse>> => {
    const path = `/auth/login`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to register new investor
   * @param body -
   */
  export const registerLead = async (
    body: RegisterInvestorRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RegisterInvestorResponse>> => {
    const path = `/auth/register`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to verify registration
   * @param body -
   * @param id -
   */
  export const verifyLead = async (
    body: VerifyDraftRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UserVerificationResponse>> => {
    const path = `/auth/register/verify/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to verify login
   * @param body -
   */
  export const verifyLogin = async (
    body: VerifyLoginApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AuthResponse>> => {
    const path = `/auth/login/verify`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NcaishInternalAuthApiService()
