import PageTitle from "../../common/page-title";
import Heading from "../heading";
import EmployeeSearch from "./employee-search";
import EmployeeStatusCount from "./employee-status-count";
import EmployeeTable from "./employee-table";

const Employees = () => {
  return (
    <>
      <PageTitle title="Employees" />
      <Heading title="Employees" />
      <EmployeeSearch />
      <EmployeeStatusCount />
      <EmployeeTable />
    </>
  );
};

export default Employees;
