import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, store } from "../store";

export interface AlphaState {
  activeTab: string;
}

const initialAlphaState: AlphaState = {
  activeTab: "",
};

const alphaSlice = createSlice({
  name: "alpha",
  initialState: initialAlphaState,
  reducers: {
    setActiveTab: (state, { payload: val }: PayloadAction<string>) => {
      state.activeTab = val;
    },
  },
});

export namespace AlphaDispatch {
  export const setActiveTab = (payload: string) => {
    store.dispatch(alphaSlice.actions.setActiveTab(payload));
  };
}

export namespace AlphaSelect {
  export const selectActiveTab = (state: State) => state.alpha.activeTab;
}

export default alphaSlice.reducer;
