import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { allowedUids } from "../../app";
import { useAppDispatch } from "../../hooks/hooks";
import { useLoginWithPassword } from "../../hooks/use-login-with-password";
import { Dispatch } from "../../state/dispatch";
import {
  ProfileDispatch,
  setApprovedSuperAdmin,
} from "../../state/slice/profile.slice";
import { setToken } from "../../state/slice/token.slice";
import { setLoginUser } from "../../state/slice/user.slice";
import { fromPublic } from "../../utils/common.utils";
import { unWrapOption } from "../../utils/option.utils";
import { getNextScreenFromReview } from "../../utils/review.utils";
import { RoutePaths } from "../../utils/route.utils";
import { setIsAuthenticated } from "../app/app.slice";
import FormikComponent from "../common/formik/formik-component/formik-component";
import SpinnerComponent from "../common/loader/spinner";
import { LoginWithUsernameAndPassword } from "../form/login";
import { toast } from "react-toastify";

// import { OnboardingService } from "../../services/onboarding.service";

const Image3 = fromPublic("/images/bg1-new.svg");

interface IValues {
  username: string;
  password: string;
}

const formInitialSchema: IValues = {
  username: "",
  password: "",
};
const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required("Please enter your username")
    .test("test-name", "Please enter a valid username", function (value: any) {
      const emailRegex =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
      let isValidEmail = emailRegex.test(value);
      let isValidPhone = phoneRegex.test(value);
      if (!isValidEmail && !isValidPhone) {
        return false;
      }
      return true;
    }),
  password: yup.string().required("Please enter your password"),
});

// while testing use this Number: 8093700268

const LoginUsingPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");

  const { mutateAsync: loginWithPassword, isLoading } = useLoginWithPassword();
  const handleFormSubmit = async (values: IValues) => {
    console.log(values);
    setError("");

    const result = await loginWithPassword({
      username: values.username,
      password: values.password,
    });

    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false)) {
        Dispatch.App.setLoginId(result.value.id);
        dispatch(setLoginUser({ mobile: values.username }));

        const allow = allowedUids.includes(
          unWrapOption(result.value.id, () => "")
        );

        ProfileDispatch.setApprovedSuperAdmin(allow);

        dispatch(
          setToken({
            accessToken: unWrapOption(result.value.access_token, () => ""),
            refreshToken: unWrapOption(result.value.refresh_token, () => ""),
            tokenExpiry: unWrapOption(result.value.expires_in, () => 0),
            scope: unWrapOption(result.value.scope, () => ""),
          })
        );
        dispatch(setIsAuthenticated(true));

        const roles = unWrapOption(result.value.roles, () => [""]);

        if (roles[0] === "ROLE_CORPORATE_APPLICANT") {
          await getNextScreenFromReview(navigate);
        } else if (roles[0] === "ROLE_CORPORATE_ADMIN") {
          navigate(RoutePaths.Onboarding);
        }
        navigate(RoutePaths.NavigateToOnboarding); //FORCE
        // const path = pipe(
        //   result.value.nextScreen,
        //   getOrElse(() => "/")
        // );
        // dispatch(setCurrentScreen(path));
      } else {
        toast.error("Login Failed");
        setError(unWrapOption(result.value.message, () => ""));
      }
    } else if (isFailure(result)) {
      if (result.error && "httpStatus" in result.error) {
        setError(result.error.httpStatus.Data.message);
      } else {
        setError("Login Failed");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center vh-100">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="Mobile"
                    />
                  </div>
                  <div className="textDetail mt-5">
                    <Heading
                      className="mb-3 mt-85"
                      text="Login"
                      variant="small"
                    />

                    <p className="fw-bolder text-secondary">
                      Being a Fintech we understand the importance of Multi
                      Factor authentication prescribed by Reserve Bank of India
                      !
                    </p>
                  </div>
                </MediumScreen>
              </div>
              <div className="mt-4 col-lg-4  col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="card  p-3 bg-grey shadow rounded-15 border-0">
                      {error && (
                        <div
                          className="alert alert-danger text-center fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <Heading
                        className="text-center mt-120"
                        text="Log In"
                        variant="large"
                      />
                      <p className="d-none d-sm-none d-md-block text-center mb-0 fw-bold text-black mt-4 mb-4">
                        Please Enter Your Credentials
                      </p>
                      <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block text-center">
                        <LoginWithUsernameAndPassword />
                        <div className="d-flex justify-content-end">
                          <Link
                            className="ncash_nav-link mt-2"
                            to={RoutePaths.ForgotPassword}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="text-center ml-auto mr-auto mb-5 mt-5">
                          <Button type="submit">
                            {isLoading ? <SpinnerComponent /> : <>Continue</>}
                          </Button>
                        </div>
                      </div>
                      {/* 
                      <p className="fw-bolder text-secondary text-center mb-5 mt-1">
                        Not having an account!
                        <Link
                          className="ncash_nav-link"
                          to={RoutePaths.ValidateCompany}
                        >
                          Sign Up
                        </Link>
                        now
                      </p> */}
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="card  p-3 bg-grey shadow border-0">
                  {error && (
                    <div
                      className="alert alert-danger text-center fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="BG"
                    />
                  </div>

                  <h1 className="fw-bold text-center mb-4 heading fs-5 mt-3">
                    Log In
                  </h1>
                  <p className="d-md-none d-lg-none d-xl-none d-xxl-none text-center mb-0 fw-bold text-black fs-small">
                    Kindly enter your username
                  </p>

                  <div className="card  inputCard p-3 mt-4 mb-4 d-md-none d-lg-none d-xl-none d-xxl-none">
                    <LoginWithUsernameAndPassword />
                    <div className="d-flex justify-content-end">
                      <Link
                        className="ncash_nav-link mt-2"
                        to={RoutePaths.ForgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center ml-auto mr-auto mb-5">
                    <Button type="submit">
                      {isLoading ? <SpinnerComponent /> : <>Continue</>}
                    </Button>
                  </div>
                  {/* 
                  <p className="fw-bolder text-secondary text-center  mt-1 ">
                    Not having an account!
                    <Link className="ncash_nav-link" to={RoutePaths.SignUp}>
                      Sign Up
                    </Link>
                    now
                  </p> */}
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

export default LoginUsingPasswordPage;
