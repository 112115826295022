import React from "react";
import { Modal, Button } from "react-bootstrap";

const ApprovePopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  // membershipInfo: any;
  onApprove: () => void;
}> = ({
  show,
  onHide,
  // membershipInfo,
  onApprove,
}) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-center text-black fw-semibold fs-6">CONFIRM</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="text-sm-start text-center text-black fs-6">
                Confirm if we have received the memberships/vouchers from the
                partners
              </p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex justify-content-center">
              <button
                className="btn-ncaish-light text-white fs-small px-3 py-2 w-75 justify-content-center"
                onClick={() => onHide(false)}
              >
                CANCEL
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 w-75 justify-content-center"
                onClick={onApprove}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApprovePopup;
