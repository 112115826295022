import React from "react";
import { AdminCorpInfo } from "../../../api/admin/model/adminCorpInfo";
import WhiteCard from "../../white-card/white-card";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const BuildIcon = "/images/icons/building.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const RegistrationNumber = "/images/icons/emp_status.svg";
const RocCOde = "/images/icons/ROC_Code.svg";
const CINIcon = "/images/pen_card.svg";

const CompanyDetails: React.FC<{
  corporateInfo: AdminCorpInfo;
  agreement?: string;
}> = ({ corporateInfo, agreement = "" }) => {
  return (
    <WhiteCard className="p-3">
      <div className="d-flex justify-content-between">
        <h6 className="text-secondary fw-bold">Company Details</h6>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={BuildIcon}
                      className="img-fluid ml-auto mr-auto"
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted">Company Name</p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo?.name}
                  </h5>
                </div>
              </div>
            </div>
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={CINIcon}
                      className="img-fluid ml-auto mr-auto"
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-9 col-md-9 ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted"> CIN</p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo?.cin}
                  </h5>
                </div>
              </div>
            </div>
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={RocCOde}
                      className="img-fluid ml-auto mr-auto "
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-4 col-md-4 ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted">ROC Code </p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo.rocCode}
                  </h5>
                </div>
                <div className="col-6 col-md-6 ps-0 text-left">
                  {agreement && (
                    <button
                      onClick={() => window.open(agreement, "_blank")}
                      className="btn bg-secondary border-0 px-4 text-white  ms-2"
                    >
                      View Agreement
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={RegistrationNumber}
                      className="img-fluid ml-auto mr-auto "
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted">
                    Registration Number
                  </p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo?.regNum}
                  </h5>
                </div>
              </div>
            </div>
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={CalendarIcon}
                      className="img-fluid ml-auto mr-auto"
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted">
                    Date Of Incorporation
                  </p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo.incDate}
                  </h5>
                </div>
              </div>
            </div>
            <div className=" col-md-4">
              <div className="row">
                <div className="col-2 col-md-2 text-right">
                  <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                    <img
                      src={LocationIcon}
                      className="img-fluid ml-auto mr-auto"
                      alt="icon"
                      width="18"
                    />
                  </div>
                </div>
                <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                  <p className="mb-0 ncash_fs-12 text-muted">
                    Registered Address
                  </p>
                  <h5 className="fs-small fw-bold mb-0">
                    {corporateInfo.regAdd}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default CompanyDetails;
