import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CampaignData } from "../../../../api/detax/model/campaignCreateResponse";
import { SettingsService } from "../../../../services/settings.service";
import NoTableData from "../common/no-table-data";
import { ITableData } from "../common/table/table-pagination";
import { epochToDate } from "./campaign-details";
import { CampaignLabel, CampaignTabKind } from "./campaign.utils";
import { Detail } from "./settings";

interface props {
  setDetail: (val: Detail) => void;
  detail: Detail;
  activeTab: CampaignTabKind;
}

const CampaignTable: React.FC<props> = ({ setDetail, detail, activeTab }) => {
  const [data, setData] = useState<Array<CampaignData>>();
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    // setPaging((p) => ({
    //   ...p,
    //   total: 10000,
    //   page: 1,
    //   limit: 10,
    // }));
  }, []);
  useEffect(() => {
    const run = async () => {
      const result = await SettingsService.getCampaignList(
        activeTab,
        1,
        100000
      );
      if (isSuccess(result)) {
        if (result.value.successful) {
          //   setPaging((p) => ({
          //     ...p,
          //     total: result.value.recordCount ?? 10,
          //     page: paging.page,
          //     limit: paging.limit,
          //   }));
          setData(result.value.data);
        }
      } else {
        alert("The campaign list cannot be loaded");
      }
    };

    run();

    //}
  }, [
    // paging.page, paging.limit, paging.total,
    detail.show,
    activeTab,
  ]);

  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      {data && data.length ? (
        // <TablePagination
        //   data={paging}
        //   changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
        //   changeLimit={(data) =>
        //     setPaging((p) => ({ ...p, limit: data, page: 1 }))
        //   }
        // >
        <table>
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Campaign Title</th>
              <th scope="col">Created Date</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>
                  <Link
                    className="text-secondary ncash_nav-link"
                    to={""}
                    onClick={() => {
                      setDetail({ show: true, id: item.objectId });
                    }}
                  >
                    {item.title ?? "-"}
                  </Link>
                </td>
                <td>{epochToDate(item.createdAt) ?? "-"}</td>

                <td>{item.startDate ?? "-"}</td>
                <td> {item.endDate ?? "-"} </td>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: bg(item.campaignState),
                      borderRadius: 3,
                    }}
                    className="m-0 px-3 py-1"
                  >
                    {CampaignLabel(item.campaignState)}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        // </TablePagination>
        <NoTableData />
      )}
    </div>
  );
};

export default CampaignTable;

const bg = (data: CampaignTabKind) => {
  switch (data) {
    case CampaignTabKind.LIVE:
      return "#00B726";

    case CampaignTabKind.DRAFT:
      return "#FE9C13";
    case CampaignTabKind.DEACTIVATE:
      return "#FF4330";

    default:
      break;
  }
};
