import React from "react";
import { ApplicantInfo } from "../../../api/admin/model/applicantInfo";
import WhiteCard from "../../white-card/white-card";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";

const UserDetailsInfo: React.FC<{ applicantInfo?: ApplicantInfo }> = ({
  applicantInfo,
}) => {
  return (
    <WhiteCard className="p-3">
      <div className="">
        <div className="d-flex justify-content-between">
          <h6 className="text-secondary fw-bold">User Details</h6>
        </div>
        <div className="row">
          <div className="col-11">
            <div className="row">
              <div className=" col-md-4">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={UserIcon}
                        className="img-fluid ml-auto mr-auto" width="18"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Employee Name</p>
                    <h5 className="fs-small fw-bold mb-0">
                      {applicantInfo?.name}
                    </h5>
                  </div>
                </div>
              </div>
              <div className=" col-md-4">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={PhoneIcon}
                        className="img-fluid ml-auto mr-auto" width="18"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Mobile Number</p>
                    <h5 className="fs-small fw-bold mb-0">
                      {applicantInfo?.mobile}
                    </h5>
                  </div>
                </div>
              </div>
              <div className=" col-md-4">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={EmailIcon}
                        className="img-fluid ml-auto mr-auto" width="18"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">E-mail</p>
                    <h5 className="fs-small fw-bold mb-0">
                      {applicantInfo?.email}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};

export default UserDetailsInfo;
