import { RemoteData } from "@devexperts/remote-data-ts";
import React from "react";
import PageLoader from "../../../../page-loader";
import { Option, isSome } from "fp-ts/lib/Option";
import { DetaxBatchCountInfo } from "../../../../api/detax/model/detaxBatchCountInfo";
import CountCard from "./count-card";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  useGetDetaxBatchesCount,
  useNpsCount,
} from "./hooks/transaction.hooks.";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";

type Props = {
  isLoading: boolean;
  data?: Option<DetaxBatchCountInfo[]>;
};

const StatusSection: React.FC<Props> = ({ isLoading, data }) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const filter = useAppSelector(DetaxReduxStore.selectFilter);
  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError: isErrorCounts,
  } = useGetDetaxBatchesCount(activeTab);

  const {
    data: countsNps,
    isLoading: isLoadingCountsNps,
    isError: isErrorCountsNps,
  } = useNpsCount(activeTab);

  if (isLoadingCounts || isLoadingCountsNps) {
    return <PageLoader />;
  }

  if (filter.type === "NPS") {
    if (countsNps && isSome(countsNps)) {
      return (
        <div className="card-counts">
          {/* <CountCard
          count={0}
          label={"All"}
          isActive={"all" === filter.status.toLowerCase()}
        /> */}
          {countsNps.value.length > 0 ? (
            countsNps.value.map((item, index) => (
              <CountCard
                key={index}
                count={item.count || 0}
                label={item.label || ""}
                isActive={Boolean(
                  item.label
                    ? item.label.toLowerCase() ===
                        filter.status.toString().toLowerCase()
                    : false
                )}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      );
    }

    return <div></div>;
  } else {
    if (counts && isSome(counts)) {
      return (
        <div className="card-counts">
          {/* <CountCard
          count={0}
          label={"All"}
          isActive={"all" === filter.status.toLowerCase()}
        /> */}
          {counts.value.length > 0 ? (
            counts.value.map((item, index) => (
              <CountCard
                key={index}
                count={item.count || 0}
                label={item.label || ""}
                isActive={Boolean(
                  item.label
                    ? item.label.toLowerCase() ===
                        filter.status.toString().toLowerCase()
                    : false
                )}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      );
    }

    return <div></div>;
  }
};

export default StatusSection;
