import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, getOrElse, isSome, none, some } from "fp-ts/lib/Option";

import { CorporateOnboardingApiService } from "../api/corporateOnboardingApi.service";

//import { AccountHolder } from "../model/accountHolder";
import { ApiBrInfo } from "../model/apiBrInfo";

import { CorporateApiInfo } from "../model/corporateApiInfo";
import { CorporateServiceInfo } from "../model/corporateServiceInfo";
import { CorporateUser } from "../model/corporateUser";
import { CorpReviewApiResponse } from "../model/corpReviewApiResponse";
import { CorpServicesApiResponse } from "../model/corpServicesApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { GetContractReceipientApiResponse } from "../model/getContractReceipientApiResponse";

import { SendBrResponse } from "../model/sendBrResponse";

import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { ValidateCorpApiResponse } from "../model/validateCorpApiResponse";

//import { TAccountHolder } from "../types/account-holder.type";
import { TApiBrInfo } from "../types/api-br-info";
import { TBaseResponse, TOtpResponse } from "../types/base-response.type";
import { TBrReceipient } from "../types/br-receipient.type";
import { TContractReceipient } from "../types/contract-receipient.type";
import { TCorporateInfo } from "../types/corporate-info.type";
import { TCorporateReviewResponse } from "../types/corporate-review-response.type";
import { TCorporateService } from "../types/corporate-service.type";
import { TCorporateUser } from "../types/corporate-user.type";

import { TReceipientDto } from "../types/update-receipient-request.type";

import { TValidateCorpApiResponse } from "../types/validate-corp-api-response.type";
import { TCorpServicesApiResponse } from "../types/corp-services-api-response.type";
import { TCorpUpdateServiceApiResponse } from "../types/corp-update-service-api-response.type";
import { BrReceipientInfo } from "../model/brReceipientInfo";
import { unWrapOption } from "../utils/option.utils";
import { ConvertToRoutePath } from "../utils/route.utils";
import { NcaishAdminApplicationApiService } from "../api/admin/api/ncaishAdminApplicationApi.service";
import { ApplicationCountApiResponse } from "../api/admin/model/applicationCountApiResponse";
import { AdminCorpApplicationCountInfo } from "../api/admin/model/adminCorpApplicationCountInfo";
import { TOnBoardingApplicationCountInfo } from "../types/admin/onboarding-application-count.type";
import { AdminApplicationListResponse } from "../api/admin/model/adminApplicationListResponse";
import { AdminApplicationBasicInfo } from "../api/admin/model/adminApplicationBasicInfo";
import { ApplicantInfo } from "../api/admin/model/applicantInfo";
import { TOnboardingItem } from "../types/admin/onboarding-item.type";
import { ApplicationDetailApiResponse } from "../api/admin/model/applicationDetailApiResponse";
import { ApplicationApprovalRequest } from "../api/admin/model/applicationApprovalRequest";
import { NcaishCorporateApplicationApiService } from "../api/corporate/api/ncaishCorporateApplicationApi.service";
import { TKitmanagementItem } from "../types/admin/kitmanagement-item.type";
import { AdminKitmanagementBasicInfo } from "../api/admin/model/adminkitmanagementBasicInfo";
import { NcaishSettingsApiService } from "../api/detax/api/ncaishSettingsApi.service";
import { CampaignCreateResponse } from "../api/detax/model/campaignCreateResponse";
import { CampaignTabKind } from "../components/pages/dashboard/settings/campaign.utils";
import { CampaignListResponse } from "../api/detax/model/campaignListResponse";
// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace SettingsService {
  export const createCampaign = async (
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind
  ) => {
    const result = await NcaishSettingsApiService.createCampaign(
      title,
      body,
      cids,
      images,
      campaignState
    );
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };

  export const getCampaignList = async (
    state: CampaignTabKind,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishSettingsApiService.getCampaignList(
      state,
      pn,
      rc
    );
    return remoteData.map(result, (response: CampaignListResponse) => response);
  };

  export const getCampaignById = async (campaignId: string) => {
    const result = await NcaishSettingsApiService.getCampaignById(campaignId);
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };

  export const updateCampaignById = async (
    campaignId: string,
    title: string,
    body: string,
    cids: Array<string>,
    images: any,
    campaignState: CampaignTabKind
  ) => {
    const result = await NcaishSettingsApiService.updateCampaignById(
      campaignId,
      title,
      body,
      cids,
      images,
      campaignState
    );
    return remoteData.map(
      result,
      (response: CampaignCreateResponse) => response
    );
  };
}
