import React, { useState,useEffect } from "react";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import KitTable from "./kit-table";
// import { TOnBoardingApplicationCountInfo } from "../../../types/admin/onboarding-application-count.type";
import { KitManagementCountInfo } from "../../../types/admin/kitmanagement-application-count.type";
import KitAddNew from "./kit-addData";

const KitManagement: React.FC = () => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [counts, setCounts] = useState<KitManagementCountInfo[]>([]);

  // useEffect(() => {
  //   const run = async () => {
  //     const result = await OnboardingService.onboardingCounts();

  //     if (isSuccess(result)) {
  //       if (unWrapOption(result.value.successful, () => false)) {
  //         const countsObj = addAllDashboardCount(result.value.data);
  //         setCounts(countsObj);
  //         setSelectedState("ALL");
  //       }
  //     }
  //   };
  //   run();
  // }, [selectedState]);

    return (
        <>
        <PageTitle title="Kit Management" />
        <div className="d-flex flex-row mb-3 mt-14 justify-content-between">
        <Heading title="Kit Management" />
        <KitAddNew /></div>
        <KitTable   selectedState={selectedState}/>
        {/* <OnboardTable selectedState={selectedState} /> */}
 
        </>
      );
    };
    
    export default KitManagement;
    