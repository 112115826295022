import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageLoader from "../../page-loader";
import { CorporateService } from "../../services/corporate.service";
import { setProfile } from "../../state/slice/profile.slice";
import { fromPublic } from "../../utils/common.utils";
import BrandLogo from "../brand-logo/brand-logo";
import CommonError from "../common/errors/common-error";
import DashboardSubHeader from "../dashboard-sub-header/sub-header";
import SidebarMenu from "../sidebar-menu/sidebar-menu";
import "./dashboard-layout.scss";
import { useAppSelector } from "../../hooks/hooks";
import { AlphaSelect } from "../../state/slice/alpha.slice";
import { RoutePaths } from "../../utils/route.utils";

const NcashLogo = fromPublic("/images/icons/Brand-logo-white.svg");
const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const activeTab = useAppSelector(AlphaSelect.selectActiveTab);
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const result = await CorporateService.myProfile2();
      if (isSuccess(result)) {
        if (result.value.successful) {
          dispatch(setProfile(result.value.data || null));
        }
      } else {
        dispatch(setProfile(null));
      }
      setIsLoading(false);
    };
    run();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid dashboard-body-bg">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-1 col-xl-1 col-xxl-1 dashboard-sidebar-container menu-max-width ps-0">
            <div className="primary-gradient">
              <div className="dashboard-sidebar">
                {/* <Link to="/"> */}
                <BrandLogo
                  src={NcashLogo}
                  width="55"
                  height="82"
                  classname="my-3"
                  name="Ncash Logo"
                />
                {/* </Link> */}
                <div className="mt-0">
                  <SidebarMenu />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-10 col-md-10 col-lg col-xl col-xxl dashboard-content-container">
            <div className="mx-auto">
              <div className="mb-1">
                {activeTab !== RoutePaths.DashboardSettings && (
                  <DashboardSubHeader />
                )}
              </div>

              {children}
              <CommonError />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
