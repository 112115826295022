//@ts-ignore
import { failure, RemoteData, success } from "@devexperts/remote-data-ts";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient, axiosInstance, FailedRequestError } from "../api-client";
import { APIError } from "../errors";

import {
  OAuthLoginRequest,
  OAuthLoginResponse,
} from "../../model/auth/oauthLogin.model";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OAuthLoginApiService {
  export const loginUsingPassword = async (
    body: OAuthLoginRequest,
    customHeaders: AxiosRequestHeaders = {
      "content-type": "application/x-www-form-urlencoded",
    }
  ): Promise<RemoteData<APIError, OAuthLoginResponse>> => {
    const path = `/oauth/login`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;

    const data = new URLSearchParams();
    data.append("username", body.username);
    data.append("password", body.password);
    data.append("grant_type", "password");

    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      url: apiUrl,
      method: "post",
      data: data,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const oAuthLogout = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, any>> => {
    const path = `/oauth/logout`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: {},
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const refreshOAuthToken = async (
    refreshToken: string,
    customHeaders: AxiosRequestHeaders = {
      "content-type": "application/x-www-form-urlencoded",
    }
  ): Promise<RemoteData<APIError, OAuthLoginResponse>> => {
    const path = `/oauth/login`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;

    const data = new URLSearchParams();

    data.append("refresh_token", refreshToken);
    data.append("grant_type", "refresh_token");

    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: `${process.env.REACT_APP_API_PATH}`,
      url: apiUrl,
      method: "post",
      data: data,
      headers: {
        ...headers,
        Authorization: `Basic ${process.env.REACT_APP_CLIENT}`,
        "client-id": `${process.env.REACT_APP_CLIENT_ID}`,
        "client-secret": `${process.env.REACT_APP_CLIENT_SECRET}`,
      },
      //params: { skipAuthRefesh: true },
    };

    // refresh token axios should be seperate instance
    return axiosInstance
      .request(axiosRequestConfig)
      .then((res) => success(res.data))
      .catch((error) => {
        return failure(FailedRequestError(error));
      });

    //return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new RefreshTokenApiService()
