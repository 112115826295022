import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { AddUserRequest } from "../../../../api/corporate/model/addremoveuserresponse";
import { CorporateService } from "../../../../services/corporate.service";

const AddUserPopup: React.FC<{
  show?: boolean;
  onHide: (val: boolean) => void;
}> = ({ show, onHide }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState<AddUserRequest>();
  const params = useParams<{ id: string }>();
  const cid = params?.id ?? "";

  const handlereadd = {
    run: async () => {
      console.log("id", params?.id, request);
      if (params?.id && request) {
        setIsLoading(true);
        const result = await CorporateService.getaddUser(request);
        if (isSuccess(result)) {
          if (result.value.successful) {
            onHide(true);
            window.location.reload();
          }
        }
        setIsLoading(false);
      }
    },
  };

  useEffect(() => {
    setRequest({ name, email, mobile, cid });
  }, [name, email, mobile]);

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <ModalHeader closeButton>
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>Add Corporate Admin User</h4>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          {/* <div className="col col-cols-1 gy-3">
            <div className="col d-flex justify-content-center"> */}

          <div className="form-group row mt-2">
            <label htmlFor="name" className="col-sm-2 col-form-label">
              Name:{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(val) => setName(val.currentTarget.value)}
              />
            </div>
          </div>
          <div className="form-group row mt-2">
            <label htmlFor="age" className="col-sm-2 col-form-label">
              Email:{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="age"
                value={email}
                onChange={(val) => setEmail(val.currentTarget.value)}
              />
            </div>
          </div>
          <div className="form-group row mt-2">
            <label htmlFor="age" className="col-sm-2 col-form-label">
              Mobile
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="age"
                value={mobile}
                onChange={(val) => setMobile(val.currentTarget.value)}
              />
            </div>
          </div>
          <div className="form-group col mt-3">
            <div className="col-sm-10 offset-sm-2 row">
              <div className="col-sm-6 text-center">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => onHide(true)}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handlereadd.run}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </ModalBody>
    </Modal>
  );
};

export default AddUserPopup;
