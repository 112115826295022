import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { RoutePaths } from "../../utils/route.utils";
import Corporate from "../admin/corporate/corporate";
import CorporateDetails from "../admin/corporate/corporate-details";
import Employees from "../admin/employees/employees";
import OnboardDetails from "../admin/onbaording/onboard-details";
import Onboarding from "../admin/onbaording/onboarding";
import DashboardChangePassword from "../admin/profile/ChangePassword";
import DashboardMyProfile from "../admin/profile/Profile";
import Trial from "../admin/trial/trial";
import TrialDetails from "../admin/trial/trial-details";
import DashboardLayout from "../layouts/dashboard-layout";
import { LoginPage } from "../pages";
import TransactionDetail from "../pages/dashboard/upload-txn/detail/transaction-detail";
import UploadTXN from "../pages/dashboard/upload-txn/upload-txn";
import ForgotPasswordPage from "../pages/forget-password";
import LoginUsingPasswordPage from "../pages/login-using-password.page";

import { LoginVerificationPage } from "../pages/login-verification-page";
import { selectIsAuthenticated } from "./app.slice";
import { useSelector } from "react-redux";
import ProtectedRoute from "../../protected-route";
import LoginUsingToken from "../pages/login-using-token";
import KitManagement from "../admin/kit-management/kit_management";
import Membership from "../admin/membership/membership";
import MembershipDetails from "../admin/membership/membership-details";
import AllEmployees from "../admin/all-corporate-employees/all-employees";
import Settings from "../pages/dashboard/settings/settings";

export const AppRoutes = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<LoginUsingPasswordPage />} />
      <Route path={RoutePaths.SwitchAccount} element={<LoginUsingToken />} />
      <Route
        path={RoutePaths.ForgotPassword}
        element={<ForgotPasswordPage />}
      />

      <Route path={RoutePaths.Login} element={<LoginUsingPasswordPage />} />
      <Route
        path={RoutePaths.LoginVerification}
        element={<LoginVerificationPage />}
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAuthenticated>
            <DashboardLayout>
              <Outlet />
            </DashboardLayout>
          </ProtectedRoute>
        }
      >
        <Route
          path={RoutePaths.DashboardMyProfile}
          element={
            isAuthenticated ? (
              <DashboardMyProfile />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path={RoutePaths.DashboardChangePassword}
          element={
            isAuthenticated ? (
              <DashboardChangePassword />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route path={RoutePaths.Onboarding} element={<Onboarding />} />
        <Route path={RoutePaths.Trial} element={<Trial />} />
        <Route path={RoutePaths.Corporate} element={<Corporate />} />
        <Route path={RoutePaths.AllEmployees} element={<AllEmployees />} />
        <Route path={RoutePaths.Employees} element={<Employees />} />
        <Route path={RoutePaths.KitManagement} element={<KitManagement />} />
        <Route path={RoutePaths.Membership} element={<Membership />} />
        <Route path={RoutePaths.DashboardSettings} element={<Settings />} />
        <Route path={RoutePaths.DashboardTransaction} element={<UploadTXN />} />
        <Route
          path={RoutePaths.DashboardTransaction + "/:batchId"}
          element={<TransactionDetail />}
        />
        <Route
          path={RoutePaths.TrialDetails + "/:id"}
          element={<TrialDetails />}
        />
        <Route
          path={RoutePaths.CorporateDetails + "/:id"}
          element={<CorporateDetails />}
        />
        <Route
          path={RoutePaths.OnboardDetails + "/:id"}
          element={<OnboardDetails />}
        />
        <Route
          path={RoutePaths.MembershipDetails + "/:cId/:membershipId"}
          element={<MembershipDetails />}
        />
        <Route path="*" element={<Navigate to={RoutePaths.Onboarding} />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
