import { pipe } from "fp-ts/lib/function";
import {
  fold,
  fromNullable,
  isNone,
  isSome,
  none,
  Option,
  some,
} from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { SubscriptionData } from "../../../api/admin/model/corporate/subscriptionData";
import { unWrapOption } from "../../../utils/option.utils";
import CustomSearchBarUpgraded from "../../custom-search/custom-search-upgraded";
import NoTableData from "../../pages/dashboard/common/no-table-data";
import TableLoader from "../../pages/dashboard/common/table-loader";
import {
  ITableData,
  TablePagination,
} from "../../pages/dashboard/common/table/table-pagination";

const DeleteIcon = "/images/icons/add.svg";

const CorporateAddSubscription: React.FC<{
  cId: String;
  userList: SubscriptionData[];
  removeUser: (val: number) => void;
}> = ({ cId, userList, removeUser }) => {
  const [items, setItems] = useState<Option<SubscriptionData[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<SubscriptionData[]>>(none);

  const [paging, setPaging] = React.useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const [searchVal, setSearchVal] = useState<string>("");

  const tblData = (tblData: SubscriptionData[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  useEffect(() => {
    setItems(fromNullable(userList));
  }, [userList]);
  useEffect(() => {
    if (isSome(items)) {
      setPaging((p) => ({
        ...p,
        total: (items.value || []).length,
        page: 1,
        limit: 10,
      }));
      filterData(some(items.value || []));
    }
  }, [items]);

  useEffect(() => filterData(items), [searchVal]);

  const filterData = (allData: Option<SubscriptionData[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );

    if (searchVal) {
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let filteredData = data.filter((item) =>
        nameRegEx.test(item?.userName?.toString() || "")
      );

      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col border rounded-12">
          <CustomSearchBarUpgraded
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="60%"
            text="Search by Employee Name "
          />
        </div>
      </div>
      <div
        className="row mt-2 ms-1 me-1 p-3 border rounded-15"
        style={{ background: "white" }}
      >
        <div className="col-12">
          {pipe(
            filteredData,
            fold(
              () => [<NoTableData />],
              (datas) =>
                datas.length
                  ? [
                      <TablePagination
                        data={paging}
                        changePage={(data) =>
                          setPaging((p) => ({ ...p, page: data }))
                        }
                        changeLimit={(data) =>
                          setPaging((p) => ({ ...p, limit: data, page: 1 }))
                        }
                      >
                        <table>
                          <thead>
                            <th className="col">Employee</th>
                            <th className="col">Subscription</th>
                            <th className="col">Date of Subscription</th>
                            <th className="col">Date of Expiry</th>
                            <th className="col">Delete</th>
                          </thead>
                          <tbody>
                            {tblData(datas).map((item, index) => (
                              <tr key={index}>
                                <td className="text-start">
                                  {item.userName ? item.userName : "-"}
                                </td>
                                <td className="text-start">
                                  {item.subscriptionName
                                    ? item.subscriptionName
                                    : "-"}
                                </td>
                                <td>
                                  {item.subscriptionStartDate
                                    ? item.subscriptionStartDate
                                    : "-"}
                                </td>
                                <td>
                                  {item.subscriptionEndDate
                                    ? item.subscriptionEndDate
                                    : "-"}
                                </td>
                                <td>
                                  <button
                                    className="btn"
                                    onClick={() => removeUser(index)}
                                  >
                                    <img
                                      src={DeleteIcon}
                                      className="img-fluid ml-auto mr-auto"
                                      width="18"
                                      alt="icon"
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </TablePagination>,
                    ]
                  : [<NoTableData />]
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CorporateAddSubscription;
