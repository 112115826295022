import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { State, store } from "../../state/store";

import { none, Option } from "fp-ts/lib/Option";

import { AdminMembershipBatchInfo } from "../../api/admin/model/membership/adminMembershipBatchInfo";

export interface IMembershipFilter {
  status: string;
  label: string;
}

export interface MembershipState {
  filter: IMembershipFilter;
  membershipBatchInfo: Option<AdminMembershipBatchInfo>;
}

const initialMembershipState: MembershipState = {
  filter: {
    status: "ALL",
    label: "All",
  },
  membershipBatchInfo: none,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState: initialMembershipState,
  reducers: {
    setFilterTypeStatus: (
      state,
      { payload: { status, label } }: PayloadAction<IMembershipFilter>
    ) => {
      state.filter.status = status;
      state.filter.label = label;
    },

    setBatchInfo: (
      state,
      { payload: batchInfo }: PayloadAction<Option<AdminMembershipBatchInfo>>
    ) => {
      // console.log("redux set batch info function :::: ", batchInfo);
      state.membershipBatchInfo = batchInfo;
    },
  },
});

export namespace MembershipDispatch {
  export const setFilterTypeStatus = (payload: IMembershipFilter) => {
    store.dispatch(membershipSlice.actions.setFilterTypeStatus(payload));
  };

  export const setBatchInfo = (payload: Option<AdminMembershipBatchInfo>) => {
    store.dispatch(membershipSlice.actions.setBatchInfo(payload));
  };
}

export namespace MembershipReduxStore {
  export const selectFilter = (state: State) => state.membership.filter;
  export const selectBatchInfo = (state: State) =>
    state.membership.membershipBatchInfo;
}

export default membershipSlice.reducer;
