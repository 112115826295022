import { isSuccess } from "@devexperts/remote-data-ts";
import { option } from "fp-ts";
import { Option, fold, none, some } from "fp-ts/lib/Option";
import { useQuery } from "react-query";
import { AllEmployeesStatusCountInfo } from "../../../../api/admin/model/models";
import {
  TAdminAllEmployeesListInfo,
  TAllEmployeeStatusCountInfo,
} from "../../../../models/allEmployees.models";
import { AllEmployeesService } from "../../../../services/allEmployees.service";
import { unWrapOption } from "../../../../utils/option.utils";

interface IFilter {
  status?: string;
}

interface params {
  key: string;
  value: string;
}

export function useGetAllEmployeesList(
  filter: string,
  pn: number,
  rc: number,
  from: string,
  to: string
) {
  // let filterList: [params] | [] = [];
  // if (filter.status) {
  //   if (filter.status !== "ALL") {
  //     filterList = [
  //       {
  //         key: "status",
  //         value: filter.status,
  //       },
  //     ];
  //   }
  // }

  return useQuery<TAdminAllEmployeesListInfo[], Error>(
    ["useGetAllEmployeesList", filter],
    async () => {
      const result = await AllEmployeesService.getAllEmployeeList(
        filter,
        pn,
        rc,
        from,
        to
      );
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          return AllEmployeesService.parseAdminAllEmployeeListInfo(
            result.value.data
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetAllEmployeesListPageCount(
  filter: string,
  pn: number,
  rc: number,
  from: string,
  to: string
) {
  return useQuery<number, Error>(
    ["useGetAllEmployeesListPageCount", filter],
    async () => {
      const result = await AllEmployeesService.getAllEmployeeList(
        filter,
        pn,
        rc,
        from,
        to
      );

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          const countOption = fold(
            () => none,
            (num: any) => some(num)
          )(result.value.recordCount);

          let count = 0;
          if (countOption._tag === "Some") {
            count = countOption.value;
          }

          // DetaxDispatch.setBatches(batches);
          return count;
        } else {
          return 0; // Return a default value when not successful
        }
      } else {
        return 0; // Return a default value when not successful
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetAllEmployeesStatusCount() {
  return useQuery<Option<TAllEmployeeStatusCountInfo[]>, Error>(
    ["useGetMembershipBatchesCount", "count"],
    async () => {
      const result = await AllEmployeesService.getAllEmployeesStatusCounts();
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          const counts = option.isSome(result.value.data)
            ? option.some(addAllCount(result.value.data.value))
            : none;
          return counts;
        } else {
          return none;
        }
      } else {
        return none;
      }
    }
  );
}

function addAllCount(data: AllEmployeesStatusCountInfo[]) {
  const allCount: TAllEmployeeStatusCountInfo = {
    status: "ALL",
    label: "All",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: TAllEmployeeStatusCountInfo[] = [
    allCount,
    ...data.map((d) => ({
      status: d.status || "",
      label: d.label || "",
      count: d.count || 0,
    })),
  ];
  return counts;
}
