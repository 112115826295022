/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AdminTrialCountApiResponse } from "../model/adminTrialCountApiResponse";
import { AdminTrialDetailApiResponse } from "../model/adminTrialDetailApiResponse";
import { AdminTrialsApiResponse } from "../model/adminTrialsApiResponse";
import { LeadRequestFilter } from "../model/leadRequestFilter";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NCaishAdminTrialApiService {
  /**
   * Api to get application details
   * @param id -
   */
  export const detail = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminTrialDetailApiResponse>> => {
    const path = `/secure/trials/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch status wise applications list
   * @param filter -
   */
  export const fetchByState = async (
    filter: LeadRequestFilter,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminTrialsApiResponse>> => {
    const path = `/secure/trials`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //         {
    //             key: 'filter',
    //             value: filter
    //         },
    // ];
    // const queryParams = allQueryParams
    //         .map(param => `${param.key}=${param.value}`)
    //         .join('&');

    // const apiUrl = queryParams === '' ? path : path.concat(`?${queryParams}`);

    const apiUrl = path.concat(
      `?type=${filter.type}&mobile=${filter.mobile}&from=${filter.from}&to=${
        filter.to
      }&state=${filter.state || ""}`
    );

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch status wise lead count
   */
  export const trialCounts = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminTrialCountApiResponse>> => {
    const path = `/secure/trials/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NCaishAdminTrialApiService()
