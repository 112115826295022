import { createSlice } from "@reduxjs/toolkit";
import { ClaimInfo } from "../../model/dashboard/claimInfo";
import { DetaxInfo } from "../../model/dashboard/detaxInfo";
import { EmployeeInfo } from "../../model/dashboard/employeeInfo";
import { TransactionInfo } from "../../model/dashboard/transactionInfo";
import { State } from "../store";

export interface IDashboard {
  dashboardLoading: boolean;
  detaxInfo?: DetaxInfo;
  employeeInfo?: EmployeeInfo;
  claimInfo?: ClaimInfo;
  transactionInfo?: TransactionInfo;
}

const initialState: IDashboard = {
  dashboardLoading: true,
  detaxInfo: {
    eligibleUser: "",
    actiUser: "",
    activationPending: "",
    enrolmentPending: "",
    inActiveUser: "",
  },
  employeeInfo: {
    active: "",
    inActive: "",
    totalEmployee: "",
  },
  claimInfo: {
    totalClaims: "",
    approved: "",
    pending: "",
    rejected: "",
  },
  transactionInfo: {
    allTransactions: "",
    batchCreated: "",
    confPending: "",
    paymentReceived: "",
    subProcessed: "",
    subOnHold: "",
  },
};

const slice = createSlice({
  name: "sa-dashboard",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setDashboardData: (state, { payload: dashboardData }) => {
      state.detaxInfo = dashboardData.detaxInfo;
      state.employeeInfo = dashboardData.employeeInfo;
      state.claimInfo = dashboardData.claimInfo;
      state.transactionInfo = dashboardData.transactionInfo;
    },
    endDashboardLoad: (state) => {
      state.dashboardLoading = false;
    },
  },
});

export const { initializeApp, setDashboardData, endDashboardLoad } =
  slice.actions;

export const selectDashboardData = (state: State) => state.dashboard;
export const selectDashboardLoader = (state: State) =>
  state.dashboard.dashboardLoading;

export default slice.reducer;
