import { isSuccess } from "@devexperts/remote-data-ts";
import { Option, isSome, none } from "fp-ts/lib/Option";
import { useQuery } from "react-query";
import {
  TAdminMembershipBatchListInfo,
  TMembershipBatchCountInfo,
} from "../../../../models/membership.models";
import { MembershipService } from "../../../../services/membership.service";
import { option } from "fp-ts";
import { MembershipCountInfo } from "../../../../api/admin/model/membershipCountInfo";
import { unWrapOption } from "../../../../utils/option.utils";

export function useGetMembershipBatchesCount() {
  return useQuery<Option<TMembershipBatchCountInfo[]>, Error>(
    ["useGetMembershipBatchesCount", "count"],
    async () => {
      const result = await MembershipService.getMembershipBatchCounts();
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          const counts = option.isSome(result.value.data)
            ? option.some(addAllCount(result.value.data.value))
            : none;
          return counts;
        } else {
          return none;
        }
      } else {
        return none;
      }
    }
  );
}

export function useGetMembershipBatchList(batchStatus: string) {
  return useQuery<TAdminMembershipBatchListInfo[], Error>(
    ["useGetMembershipBatchList", batchStatus],
    async () => {
      const result = await MembershipService.getMembershipBatchByStatus({
        batchStatus,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          return MembershipService.parseAdminMembershipBatchInfo(
            result.value.data
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

function addAllCount(data: MembershipCountInfo[]) {
  const allCount: TMembershipBatchCountInfo = {
    status: "All",
    label: "ALL",
    count: data.reduce((a, c) => a + (c.count || 0), 0),
  };
  const counts: TMembershipBatchCountInfo[] = [
    allCount,
    ...data.map((d) => ({
      status: d.status || "",
      label: d.label || "",
      count: d.count || 0,
    })),
  ];
  return counts;
}
