import { isSuccess } from "@devexperts/remote-data-ts";
import ExcelJS from "exceljs";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { KitManagementService } from "../../../../services/kitmanagement.service";
import DashboardPageLoader from "../../../admin/common/dashboard-page-loader";
import ErrorPopup from "../../../admin/common/ErrorPopup";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import WhiteCard from "../../../white-card/white-card";
import NoTableData from "../common/no-table-data";
import { Modal } from "react-bootstrap";
import { ITableData, TablePagination } from "../common/table/table-pagination";
const excelSchema: any = {
  "Kit No": { prop: "kitNo", type: String, required: true },
  "AWB Number": { prop: "awbNumber", type: String, required: true },
};
// same as import { EmployeePayload } from './employeePayload';
interface IPayload {
  kitNo: string;
  awbNumber: string;
  error?: string[];
}

interface IData {
  kitNo: string;
  awbNumber: string;
  error: string[];
}

const DeliveryUpload: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [importData, setImportData] = useState<IPayload[] | []>([]);
  const [excelData, setExcelData] = useState<File[]>([]);
  const [showSuccess, setShowSuccess] = useState({
    show: false,
    message: "",
    data: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionState, setActionState] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState({
    show: false,
    message: "",
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [editData, setEditData] = useState<IData | null>(null);
  const [editID, setEditID] = useState<number | null>(null);

  const validateData = (data: IData[] | []) => {
    if (data.length > 0) {
      let tmp1: string[] = [""];
      let tmp2: string[] = [""];
      let tmp3: string[] = [""];

      let newData: [] | IData[] = data.map((item) => {
        item = { ...item, error: [] };

        // if (!item.kitNo) item.error = [...item.error, "kitNo"];
        // if (!item.awbNumber) item.error = [...item.error, "awbNumber"];
        console.log("Invalid", item);

        if (!item.kitNo) item.error = [...item.error, "kitNo"];

        if (!item.awbNumber) item.error = [...item.error, "awbNumber"];

        return item;
      });

      return newData;
    } else {
      return data;
    }
  };

  const importExcel = async () => {
    setShowError(false);
    if (excelData.length > 0) {
      let data: any[] = [];
      let rowData: any[] = [];
      let filesArr = [...excelData];

      for (let i = 0; i < filesArr.length; i++) {
        let file = filesArr[i];
        let rows = await readXlsxFile(file, {
          schema: excelSchema,
        });
        /* delete rows[0];
        rows = rows.filter((i) => i); */
        //  console.log("importExcel ::", rows);
        rowData = rows.rows;
        rowData = rowData.map((row: any) => ({ ...row, error: [] }));
      }
      console.log("rowData: ", rowData);
      rowData = validateData(rowData);

      let payload: IPayload[] | [] = [];
      rowData.map((item) => {
        payload = [
          ...payload,
          {
            kitNo: item.kitNo,
            awbNumber: item.awbNumber,
            error: item.error,
          },
        ];
      });

      if (payload.length == 0) setShowError(true);
      console.log("payload:  ", payload);
      setImportData(payload);
      setExcelData([]);
      setPaging((p) => ({ ...p, total: payload.length, page: 1, limit: 10 }));
    }
  };

  const tblData = (tblData: IPayload[] | []) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);

    return result;
  };

  const uploadDelivery = async () => {
    setShowError(false);
    setResponseError({ show: false, message: "" });
    setActionState(true);
    let payloadData: Array<IPayload> = JSON.parse(JSON.stringify(importData));
    payloadData = payloadData.filter(
      (item: IPayload) => item.error && item.error.length === 0
    );
    payloadData.map((item: IPayload) => {
      delete item.error;
    });

    const deliveryRequestData = payloadData.map((item: IPayload) => ({
      kitNo: item.kitNo,
      awbNumber: item.awbNumber,
    }));

    const result = await KitManagementService.deliveryUpload(
      deliveryRequestData
    );

    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        if ((result.value.data?.length ?? 0) > 0) {
          setShowSuccess({
            show: true,
            message: `${result.value.message}` ?? "Upload Success",
            data: result.value.data?.join(", ") ?? "",
          });
        } else {
          setShowSuccess({
            show: true,
            message: `${result.value.message}` ?? "Upload Success",
            data: "",
          });
        }
        setImportData([]);
      } else {
        setResponseError({ show: true, message: result.value.message || "" });
      }
    } else {
      setResponseError({ show: true, message: "Update Failed" });
    }
  };

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  function generateExcelSheet() {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a new worksheet
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add data to the worksheet
    worksheet.columns = [
      { header: "Kit No", width: 20 },
      { header: "AWB Number", width: 20 },
    ];

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Download the file
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "DeliveryUploadTemplate.xlsx";
      a.click();
    });
  }

  return (
    <>
      <h1 className="page-heading fw-bold">Delivery Upload</h1>
      {importData && importData && importData.length === 0 && (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1
                <span>
                  {" "}
                  Download the template and enter the employee delivery details
                  in the required format{" "}
                </span>
              </h3>
            </div>

            <ReuseIcon className="mb-3">
              <svg
                className="comman-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="47.54"
                height="43.884"
                viewBox="0 0 47.54 43.884"
                onClick={generateExcelSheet}
              >
                <path
                  id="download"
                  d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                  fill="#103458"
                />
              </svg>
            </ReuseIcon>
          </div>
        </WhiteCard>
      )}

      <WhiteCard className="mt-4">
        <div className="steps">
          {/* <h3 className="mb-3">Uploaded Sheet</h3> */}
          {importData.length > 0 ? (
            <>
              {tblData(importData).length > 0 ? (
                <TablePagination
                  data={paging}
                  changePage={(data) =>
                    setPaging((p) => ({ ...p, page: data }))
                  }
                  changeLimit={(data) =>
                    setPaging((p) => ({ ...p, limit: data, page: 1 }))
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Kit No</th>
                        <th scope="col">AWB Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tblData(importData).map((item, key) => (
                        <tr
                          //   className={classNames({
                          //     "error-row": item.error && item.error.length > 0,
                          //   })}
                          style={
                            item.error && item.error.length > 0
                              ? { backgroundColor: "#ffb4c5" }
                              : {}
                          }
                          key={key}
                        >
                          <td
                            // className={classNames({
                            //   "error-column":
                            //     item.error && item.error.includes("kitNo"),
                            // })}
                            style={
                              item.error && item.error.includes("kitNo")
                                ? { backgroundColor: "#ffb4c5" }
                                : {}
                            }
                          >
                            {item.kitNo}
                          </td>
                          <td
                            // className={classNames({
                            //   "error-column":
                            //     item.error && item.error.includes("awbNumber"),
                            // })}
                            style={
                              item.error && item.error.includes("awbNumber")
                                ? { backgroundColor: "#ffb4c5" }
                                : {}
                            }
                          >
                            {item.awbNumber}
                          </td>

                          <td style={{ display: "none" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  let newData = importData.filter(
                                    (i, k) => k === keyIndex
                                  )[0];
                                  let editModalData: IData = {
                                    kitNo: newData.kitNo || "",
                                    awbNumber: newData.awbNumber || "",
                                    error: [],
                                  };

                                  // console.log('newKey: ', startFrom + key )
                                  // console.log('Key: ', key, paging);
                                  setEditData(editModalData);
                                  setEditID(keyIndex);
                                }}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <MdModeEdit />
                              </button>
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  setImportData(
                                    importData.filter((i, k) => k !== keyIndex)
                                  );
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                <MdDelete />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TablePagination>
              ) : (
                <NoTableData />
              )}
              <div className="action"></div>
            </>
          ) : (
            <>
              <h3 className="page-heading mb-3">
                Step - 2
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  Upload the filled-in employee delivery details sheet to the
                  tracking card
                </p>
              </h3>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  let arr: File[] = [];
                  acceptedFiles.forEach((file) => {
                    if (file.name.split(".").pop() == "xlsx")
                      arr = [...arr, file];
                  });
                  setExcelData(arr);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="p-3 text-center my-4 mx-auto wrap-file-upload "
                    role="button"
                    style={{
                      border: "2px dashed #d0d0db",
                      width: "80%",
                      borderRadius: "1rem",
                    }}
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} accept=".xlsx" />
                      <svg
                        id="Group_2844"
                        width="60"
                        data-name="Group 2844"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 158.798 190.158"
                      >
                        <path
                          id="Path_6474"
                          data-name="Path 6474"
                          d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                          transform="translate(-157.192 -168.825)"
                          fill="#cecece"
                        />
                        <rect
                          id="Rectangle_365"
                          data-name="Rectangle 365"
                          width="158.798"
                          height="21.5"
                          rx="10.75"
                          transform="translate(0 168.659)"
                          fill="#cecece"
                        />
                      </svg>
                      <div
                        className="w-25 px-4 mx-auto mt-2"
                        style={{ fontSize: 16 }}
                      >
                        <p>Drag and drop here </p>
                        <p>or</p>
                        <p className="">Browse Files</p>
                      </div>
                      <ul className="files-list">
                        {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="w-25 mx-auto text-center">
                {excelData.length > 0 && (
                  <button
                    style={{ marginRight: "20px" }}
                    className="btn-ncaish-light text-white px-3 py-2"
                    onClick={() => setExcelData([])}
                  >
                    Clear
                  </button>
                )}
                {excelData.length > 0 ? (
                  <button
                    disabled={excelData.length == 0}
                    className="btn-gradiant-blue text-white w-50 px-3 py-2"
                    onClick={() => importExcel()}
                  >
                    Upload
                  </button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </WhiteCard>
      {importData && importData && importData.length > 0 && (
        <div className="mb-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => setImportData([])}
          >
            Remove
          </button>
          <button
            // disabled={actionState}
            disabled={
              importData
                .map((obj) => obj.error)
                .map((obj) => (obj?.length === 0) === true)
                .filter((obj) => obj === false).length !== 0
            }
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => uploadDelivery()}
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </div>
      )}

      <SuccessPopup
        show={showSuccess.show}
        onClose={() => {
          setShowSuccess({ show: false, message: "", data: "" });
        }}
        message={showSuccess.message}
        txtSmall={true}
        failedData={showSuccess.data.length > 0 ? showSuccess.data : undefined}
      />

      <ErrorPopup
        errorText={
          showError
            ? `Invalid data! Download the template and enter the employee delivery details in the required format`
            : responseError.message
        }
        show={showError || responseError.show}
        onHide={(flag) => {
          setShowError(flag);
          setResponseError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default DeliveryUpload;

export const SuccessPopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
  txtSmall?: boolean;
  failedData?: string;
}> = ({
  message = "Successful!",
  show = false,
  onClose,
  txtSmall = false,
  failedData,
}) => {
  return (
    <>
      <Modal show={show} onClose={() => onClose(false)} centered>
        <Modal.Header className="d-flex flex-column justify-content-center mt-3">
          <div className="text-center rounded-circle bg-success  text-light  fs-2 ncaish-w-50">
            &#10004;
          </div>
          <Modal.Title
            className="text-success mt-3 mb-3"
            style={
              txtSmall
                ? { fontSize: 20, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            {message}

            {failedData && (
              <p className="m-0 text-danger" style={{ fontSize: 14 }}>
                Failed: {failedData}
              </p>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer
          className="ncaish-bg-grey d-flex justify-content-center pointer"
          onClick={() => onClose(false)}
        >
          <p
            className="light fw-bold"
            style={
              txtSmall
                ? { fontSize: 14, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            OK
          </p>
          {/* <button
            className="primary-bg btn success-popup-button"
            onClick={() => onClose(false)}
            style={
              txtSmall
                ? { fontSize: 14, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            OK
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
