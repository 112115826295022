import classNames from "classnames";
import React from "react";
import { MembershipDispatch } from "../../../../state/slice/membership.slice";
import WhiteCard from "../../../white-card/white-card";

type Props = {
  isActive: boolean;
  count: number;
  label: string;
  status: string;
};

const CountCard: React.FC<Props> = (props) => {
  return (
    <div>
      <div
        onClick={() => {
          MembershipDispatch.setFilterTypeStatus({
            status: props.status || "ALL",
            label: props.label || "All",
          });
        }}
        className="card-count"
      >
        <WhiteCard
          className={classNames("cursor-pointer p-2 w-80 status-dot-card", {
            color0: props.status == "ALL",
            color1: props.status == "REQUESTED",
            color3: props.status == "INITIATED",
            color14: props.status == "EXECUTED",
            color7: props.status == "APPROVED",
            color16: props.status == "CANCELED",
            active: props.isActive,
          })}
        >
          <div className="status-dot"></div>
          <h2 className="text-black text-center mb-0 fw-bold">{props.count}</h2>
          <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
            {props.label === "Canceled" ? "Cancelled" : props.label}
          </p>
        </WhiteCard>
      </div>
    </div>
  );
};

export default CountCard;
