import * as success from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { isSome } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/hooks";
import { TDetaxAdminBatchInfo } from "../../../../models/batches.models";
import { BatchesService } from "../../../../services/batches.service";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import {
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import StatusText from "../../../admin/status-text";
import NoTableData from "../common/no-table-data";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import { useGetBatchesForNps } from "./hooks/transaction.hooks.";

const TransactionTable: React.FC = () => {
  const filter = useAppSelector(DetaxReduxStore.selectFilter);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [filteredData, setFilteredData] = useState<TDetaxAdminBatchInfo[]>([]);

  // const {
  //   data: detaxBasicInfos,
  //   isLoading: isLoadingStatus,
  //   isSuccess: isSuccessStatus,
  //   isFetching: isFetchingStatus,
  //   isError: isErrorStatus,
  // } = useGetBatchesByStatus(
  //   filter.type,
  //   getTitleCaseToStatus(filter.status),
  //   paging.page,
  //   paging.limit
  // );

  // const { data: detaxBasicInfos1 } = useGetBatchesByStatusPageCount(
  //   filter.type,
  //   getTitleCaseToStatus(filter.status),
  //   paging.page,
  //   paging.limit
  // );
  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 10000,
      page: 1,
      limit: 10,
    }));
  }, [getTitleCaseToStatus(filter.status), filter.type]);
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await BatchesService.getCorporateBatchByStatus({
          type: filter.type,
          batchStatus: getTitleCaseToStatus(filter.status),
          pn: paging.page,
          rc: paging.limit,
        });

        // Handle the result here
        if (success.isSuccess(result)) {
          if (unWrapOption(result.value.successful, () => false)) {
            const parsedData = BatchesService.parseDetaxAdminBatchInfo(
              result.value.data
            );
            // // Update state with the parsed data
            // // setItems(parsedData);
            setFilteredData(parsedData);
            setPaging((p) => ({
              ...p,
              total: unWrapOption(result.value.recordCount, () => 0),
              page: paging.page,
              limit: paging.limit,
            }));
            console.log("Filtered", paging);
          }
        }
      } catch (error) {
        // Handle any unexpected errors here
        // You may want to set state accordingly or show an error message
      }
    }
    fetchData();
  }, [paging.page, paging.limit, paging.total]);

  const {
    data: detaxBasicInfosNps,
    isLoading: isLoadingNps,
    isSuccess: isSuccessNps,
    isFetching: isFetchingNps,
    isError: isErrorNps,
  } = useGetBatchesForNps(
    filter.type,
    getTitleCaseToStatus(filter.status),
    paging.page,
    paging.limit
  );

  const rows = (data: TDetaxAdminBatchInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td>
              <Link
                className="text-secondary ncash_nav-link"
                to={
                  RoutePaths.DashboardTransaction +
                  "/" +
                  unWrapOption(item.batchId, () => "")
                }
              >
                {unWrapOption(item.batchCode, () => "")}
              </Link>
            </td>
            <td>
              {isSome(item.corpInfo) ? item.corpInfo.value.name || "" : ""}
            </td>
            <td>
              {isSome(item.corpInfo) ? item.corpInfo.value.cin || "" : ""}
            </td>
            <td className="text-center text-nowrap">
              {unWrapOption(item.createdAt, () => "-")}
            </td>
            <td className="text-center text-nowrap">
              {unWrapOption(item.updatedAt, () => "-")}
            </td>
            <td>{unWrapOption(item.batchId, () => "")}</td>
            <td>{unWrapOption(item.type, () => "")}</td>
            <td>{currencyFormat(unWrapOption(item.amount, () => 0))}</td>
            <td className="text-center text-nowrap" style={{ zIndex: "500" }}>
              <StatusText
                text={getTitleCase(unWrapOption(item.status, () => ""))}
                className={classNames({
                  color0: unWrapOption(item.status, () => "") == "ALL",
                  color2: unWrapOption(item.status, () => "") == "CREATED",
                  color1: unWrapOption(item.status, () => "") == "SUBMITTED",
                  color3:
                    unWrapOption(item.status, () => "") == "PAYMENT_CONFIRMED",
                  color7: unWrapOption(item.status, () => "") == "EXECUTED",
                  color9: unWrapOption(item.status, () => "") == "ON_HOLD",
                  color4: unWrapOption(item.status, () => "") == "CANCELLED",
                  color5: unWrapOption(item.status, () => "") == "REJECTED",
                  color12: unWrapOption(item.status, () => "") == "EXECUTING",
                  color13: unWrapOption(item.status, () => "") == "FAILED",
                  color14:
                    unWrapOption(item.status, () => "") == "PARTIALLY_EXECUTED",
                })}
              />
            </td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={7}>
              <img
                src={process.env.PUBLIC_URL + "/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TDetaxAdminBatchInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  if (isLoadingNps || isFetchingNps) {
    return <TableLoader />;
  }

  if (isErrorNps) {
    return <div>Error</div>;
  }

  //console.log("detaxBasicInfos: ", detaxBasicInfos);

  return (
    // <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
    <div>
      {filter.type !== "NPS" &&
        (filteredData && filteredData.length > 0 ? (
          <TablePagination
            data={paging}
            changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
            changeLimit={(data) =>
              setPaging((p) => ({ ...p, limit: data, page: 1 }))
            }
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Batch Code</th>
                  <th scope="col">Corporate Name</th>
                  <th scope="col">CIN Number</th>
                  <th scope="col">Created At</th>
                  <th scope="col"> Updated At</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Type</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col" style={{ zIndex: "100" }}>
                    Status
                  </th>
                </tr>
              </thead>

              <tbody>{filteredData && rows(tblData(filteredData))}</tbody>
            </table>
          </TablePagination>
        ) : (
          <NoTableData />
        ))}
      {filter.type === "NPS" &&
        (isSuccessNps && detaxBasicInfosNps && detaxBasicInfosNps.length > 0 ? (
          <TablePagination
            data={paging}
            changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
            changeLimit={(data) =>
              setPaging((p) => ({ ...p, limit: data, page: 1 }))
            }
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Batch Code</th>
                  <th scope="col">Corporate Name</th>
                  <th scope="col">CIN Number</th>
                  <th scope="col">Created At</th>
                  <th scope="col"> Updated At</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Type</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col" style={{ zIndex: "100" }}>
                    Status
                  </th>
                </tr>
              </thead>

              <tbody>
                {detaxBasicInfosNps && rows(tblData(detaxBasicInfosNps))}
              </tbody>
            </table>
          </TablePagination>
        ) : (
          <NoTableData />
        ))}
    </div>
  );
};

export default TransactionTable;
