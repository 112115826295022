import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddRemoveUserInfo } from "../../../api/corporate/model/addremoveuserresponse";
import PageLoader from "../../../page-loader";
import { CorporateService } from "../../../services/corporate.service";
import RemoveUserPopup from "../../common/popups/remove-user";
import WhiteCard from "../../white-card/white-card";
import AddUserPopup from "../corporate/utils/adduserpopup";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const BuildIcon = "/images/icons/building.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const RegistrationNumber = "/images/icons/emp_status.svg";
const RocCOde = "/images/icons/ROC_Code.svg";
const CINIcon = "/images/pen_card.svg";

const AddRemoveUser: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [removeUserPopUp, setRemoveUserPopUp] = useState(false);
  const [userId, setUserId] = useState("");

  const [details, setDetails] = useState<AddRemoveUserInfo[]>([]);

  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        setIsLoading(true);
        const result = await CorporateService.getlistOfUser(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful)
            setDetails((prev) => (prev = result.value.data || prev));
        }
        setIsLoading(false);
      }
    };
    run();
  }, [params.id]);

  useEffect(() => {
    const sortedDetails = details.sort((a, b) => {
      if (a.status === "ACTIVE" && b.status === "INACTIVE") {
        return -1; // a comes before b
      } else if (a.status === "INACTIVE" && b.status === "ACTIVE") {
        return 1; // a comes after b
      } else {
        return 0; // preserve original order
      }
    });
  }, [details]);

  useEffect(() => {
    console.log("id", userId);
  }, [userId]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <WhiteCard className="p-3">
      <div className="d-flex justify-content-between  mb-1">
        <h6 className="text-secondary fw-bold ">Corporate Admin Users</h6>
        <button
          className="btn bg-primary border-0 px-4 mx-4 text-white "
          onClick={() => setAddUserPopUp(true)}
        >
          Add
        </button>
      </div>

      {details?.map((d, index) => (
        <div className="row border rounded mx-2 mb-1">
          <div className="col-12">
            <div className="row p-2">
              <div className=" col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={UserIcon}
                        className="img-fluid ml-auto mr-auto"
                        alt="icon"
                        width="18"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Name</p>
                    <h5 className="fs-small fw-bold mb-0">{d.name}</h5>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={PhoneIcon}
                        className="img-fluid ml-auto mr-auto "
                        alt="icon"
                        width="18"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Mobile Number</p>
                    <h5 className="fs-small fw-bold mb-0">{d.mobile}</h5>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={EmailIcon}
                        className="img-fluid ml-auto mr-auto"
                        alt="icon"
                        width="18"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">E-mail</p>
                    <h5 className="fs-small fw-bold mb-0">{d.email}</h5>
                  </div>
                </div>
              </div>
              <div className=" col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={EmpDesgIcon}
                        className="img-fluid ml-auto mr-auto"
                        alt="icon"
                        width="18"
                      />
                    </div>
                  </div>
                  <div className="col-5 col-md-4 ps-0 text-left ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Status</p>
                    <h5 className="fs-small fw-bold mb-0">{d.status}</h5>
                  </div>
                  <div className="col-4 col-md-6 text-end text-left">
                    {d.status == "ACTIVE" ? (
                      <button
                        className="btn bg-danger border-0 px-2 text-white"
                        onClick={() => {
                          setUserId(d.userId);
                          setRemoveUserPopUp(true);
                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className=" col-md-2">
        <div className="row">
          <div className="col-2 col-md-2 text-right">
            
          </div>
        
          <div className="col-1 col-md-6 ps-0 text-end">
            {d.status == "ACTIVE" ?  <button
                  className="btn bg-danger border-0 px-4 text-white  ms-2"
                  onClick={() => {
                    setUserId(d.userId)
                    setRemoveUserPopUp(true)
                    console.log("id",userId)
                  }}
              >
                    
                Remove
                 </button> : <></>}
          </div>
        </div>
       
      
      </div> */}
            </div>
          </div>
        </div>
      ))}

      <AddUserPopup
        show={addUserPopUp}
        onHide={() => {
          setAddUserPopUp(false);
        }}
      />
      <RemoveUserPopup
        show={removeUserPopUp}
        uid={userId}
        onHide={() => {
          setRemoveUserPopUp(false);
        }}
        onCancel={() => setRemoveUserPopUp(false)}
      />
    </WhiteCard>
  );
};

export default AddRemoveUser;
