import { isSuccess } from "@devexperts/remote-data-ts";
import classnames from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";

import { useState } from "react";
import { ChangePasswordApiRequest } from "../../../api/employee/model/changePasswordApiRequest";
import { PasswordService } from "../../../services/password.service";
import PageTitle from "../../common/page-title";
import SuccessPopup from "../../pages/dashboard/common/succes-popup";
import WhiteCard from "../../white-card/white-card";
import Heading from "../heading";
import { option } from "fp-ts";
import { Option } from "fp-ts/lib/Option";
import { AuthService } from "../../../services/auth.service";
import { MyStorage } from "../../../utils/local-storage.utils";
import { useAppDispatch } from "../../../hooks/hooks";
import { resetProfile } from "../../../state/slice/profile.slice";
import { useNavigate } from "react-router-dom";

interface IChangePassword {
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const DashboardChangePassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IChangePassword>({
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  const [actionState, setActionState] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [optError, setError] = useState<Option<string>>(option.none);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<ChangePasswordApiRequest> = async (data) => {
    const result = await AuthService.changePassword({
      password: data.password,
      newPassword: data.newPassword,
    });
    if (isSuccess(result)) {
      setActionState(false);
      if (result.value.successful) {
        setShowSuccess(true);
        setError(option.none);
      } else {
        setError(option.fromNullable(result.value.message));
      }
    } else {
      // handle error state
      //setError(option.some("Something went wrong."));
      setActionState(false);
    }
  };
  return (
    <>
      <PageTitle title={`Change Password`} />
      <Heading title="Change Password" />
      <WhiteCard className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          {option.isSome(optError) ? (
            <div className="row text-danger">{optError.value}</div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="password">Current Password</label>
              <input
                type="password"
                {...register("password", {
                  required: "Current password is required!",
                })}
                className={classnames("form-control", {
                  "is-invalid": errors?.password?.message,
                })}
              />
              <div className="invalid-feedback">
                {errors?.password?.message}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                {...register("newPassword", {
                  required: "New password is required!",
                })}
                className={classnames("form-control", {
                  "is-invalid": errors?.newPassword?.message,
                })}
              />
              <div className="invalid-feedback">
                {errors?.newPassword?.message}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                {...register("confirmPassword", {
                  required: "Confirm password is required!",
                  validate: (val) =>
                    val === getValues("newPassword") ||
                    "Password not matching with new password",
                })}
                className={classnames("form-control", {
                  "is-invalid": errors?.confirmPassword?.message,
                })}
              />
              <div className="invalid-feedback">
                {errors?.confirmPassword?.message}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary"
          >
            Submit
          </button>
        </form>
      </WhiteCard>
      <SuccessPopup
        message={`Password changed successfully`}
        show={showSuccess}
        onClose={(flag) => {
          MyStorage.AuthToken.ClearData();
          MyStorage.AuthUser.ClearData();
          MyStorage.LoginId.ClearData();
          dispatch(resetProfile());
          navigate("/login");
          setShowSuccess(flag);
        }}
      />
    </>
  );
};
export default DashboardChangePassword;
