import { Option } from "fp-ts/lib/Option";
import { store } from "../../state/store";
import {
  setBadRequestError,
  setCommonError,
  setDraftId,
  setLoginId,
  TBadRequestError,
  TCommonError,
} from "./app.slice";

export const AppDispatch = {
  setDraftId: (payload: Option<string>) => store.dispatch(setDraftId(payload)),
  setLoginId: (payload: Option<string>) => store.dispatch(setLoginId(payload)),
  setCommonError: (payload: TCommonError) =>
    store.dispatch(setCommonError(payload)),

  setBadRequestError: (payload: TBadRequestError) =>
    store.dispatch(setBadRequestError(payload)),
};
