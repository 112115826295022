import { useEffect, useState } from "react";
import { adminTableHeight, fromPublic } from "../../../utils/common.utils";
const NcashLogo = fromPublic("/images/icons/Brand-logo.svg");
const TableLoader = () => {
  return (
    <div
      className="vh-100 d-flex justify-content-center align-items-center"
      style={{
        background: "#fff",
        maxHeight: adminTableHeight(),
      }}
    >
      <div
        className="spinner-border"
        style={{ width: "8rem", height: "8rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default TableLoader;
