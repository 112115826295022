import { useEffect } from "react";
import { TrialService } from "../../../services/trial.service";
import WhiteCard from "../../white-card/white-card";
const EmployeeStatusCount = () => {
  useEffect(() => {
    const run = async () => {
      const trialStatusCount = await TrialService.trialCounts();
    };
  }, []);
  return (
    <>
      <div className="row mt-3">
        <div className="col-2 ">
          <WhiteCard className="bg-gray p-2 w-75">
            <h2 className="text-black text-center mb-0 fw-bold">42</h2>
            <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
              All Employee
            </p>
          </WhiteCard>
        </div>
        <div className="col-2 ">
          <WhiteCard className="p-2 w-75">
            <h2 className="text-black text-center mb-0 fw-bold ">30</h2>
            <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
              Active Employee
            </p>
          </WhiteCard>
        </div>
        <div className="col-2 ">
          <WhiteCard className="p-2 w-75">
            <h2 className="text-black text-center mb-0 fw-bold">12</h2>
            <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
              Inactive Employee
            </p>
          </WhiteCard>
        </div>
      </div>
    </>
  );
};

export default EmployeeStatusCount;
