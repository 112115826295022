/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CorporateUser {
  userId?: string;
  name?: string;
  email?: string;
  mobile?: string;
  role?: CorporateUser.RoleEnum;
  user?: string;
  amount?: number;
  businessUnit?: string[];
}

export namespace CorporateUser {
  export type RoleEnum =
    | "ROLE_APPROVER"
    | "ROLE_CORPORATE_ADMIN"
    | "ROLE_CORPORATE_ADMIN_MAKER";
  export const RoleEnum = {
    APPROVER: "ROLE_APPROVER" as RoleEnum,
    CORPORATE_ADMIN: "ROLE_CORPORATE_ADMIN" as RoleEnum,
    CORPORATE_ADMIN_MAKER: "ROLE_CORPORATE_ADMIN_MAKER" as RoleEnum,
  };
}
