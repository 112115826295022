import { Button } from "react-bootstrap";
import CustomSearchBar from "../../custom-search/custom-search";
import WhiteCard from "../../white-card/white-card";

const OnboardSearch: React.FC<{
  searchVal: string;
  setSearchVal: (val: string) => void;
  search: boolean;
  setSearch: (val: boolean) => void;
}> = ({ searchVal, setSearchVal, search, setSearch }) => {
  return (
    <WhiteCard className="mb-4 mt-2 p-1 rounded">
      <div className="d-flex justify-content-between">
        <CustomSearchBar
          height="auto"
          width="100%"
          text="Search by Corporate Name"
          value={searchVal}
          onChange={setSearchVal}
          search={search}
          setSearch={(val: boolean) => setSearch(val)}
        />

        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1"
          onClick={() => setSearch(!search)}
        >
          Search
        </Button>
      </div>
    </WhiteCard>
  );
};

export default OnboardSearch;
