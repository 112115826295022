import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React from "react";
import { AdminBrInfo } from "../../../api/admin/model/adminBrInfo";
import { OnboardingService } from "../../../services/onboarding.service";
import { TBrReceipient } from "../../../types/br-receipient.type";
import { TCorporateUser } from "../../../types/corporate-user.type";
import {
  getDirectorFromBrInfo,
  getSignatoryFromBrInfo,
} from "../../../utils/company.utils";
import WhiteCard from "../../white-card/white-card";
import UserRow from "./user-row";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";

const BrInfo: React.FC<{ brInfo: AdminBrInfo }> = ({ brInfo }) => {
  const receipients: TBrReceipient[] = brInfo.receipients
    ? brInfo.receipients.map(OnboardingService.parseReceipients)
    : [];

  const directors = getDirectorFromBrInfo(receipients).map((r) => {
    return {
      userId: pipe(
        r.userId,
        getOrElse(() => "")
      ),
      name: pipe(
        r.name,
        getOrElse(() => "")
      ),
      email: pipe(
        r.email,
        getOrElse(() => "")
      ),
      mobile: pipe(
        r.mobile,
        getOrElse(() => "")
      ),
    };
  });

  const signatories = getSignatoryFromBrInfo(receipients).map((r) => {
    return {
      userId: pipe(
        r.userId,
        getOrElse(() => "")
      ),
      name: pipe(
        r.name,
        getOrElse(() => "")
      ),
      email: pipe(
        r.email,
        getOrElse(() => "")
      ),
      mobile: pipe(
        r.mobile,
        getOrElse(() => "")
      ),
    };
  });

  return (
    <WhiteCard className="p-3">
      <div className="">
        {directors.length > 0 && (
          <>
            <div className="d-flex justify-content-between">
              <h6 className="text-secondary fw-bold">Directors</h6>
            </div>
            {directors.map((d, index) => (
              <UserRow
                key={index}
                name={d.name}
                mobile={d.mobile}
                email={d.email}
              />
            ))}
          </>
        )}

        {signatories.length > 0 && (
          <div className={directors.length > 0 ? "mt-5" : ""}>
            <div className="d-flex justify-content-between">
              <h6 className="text-secondary fw-bold">Authorised Signatory</h6>
            </div>
            {signatories.map((d, index) => (
              <UserRow
                key={index}
                typename="Signatory Name"
                name={d.name}
                mobile={d.mobile}
                email={d.email}
              />
            ))}
          </div>
        )}
      </div>
    </WhiteCard>
  );
};

export default BrInfo;
