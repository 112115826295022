import React, { useEffect, useState } from "react";

import PageTitle from "../../../common/page-title";

import WhiteCard from "../../../white-card/white-card";
import Heading from "../common/heading";

import TransactionTabs from "../../../horizontal-tabs/transaction-tabs";
import { useAppSelector } from "../../../../hooks/hooks";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";

import StatusSection from "./status-section";
import SearchTXN from "./search-txn";
import TransactionTable from "./transaction-table";

import EmployeeDataTable from "./employee-data-table";
import { Button } from "react-bootstrap";
import SearchMemberPopup from "./search-member-popup";

const UploadTXN: React.FC = () => {
  const showUploadTransactionScreen = useAppSelector(
    DetaxReduxStore.selectShowUploadTransaction
  );

  const [showSearchMemberPopup, setShowSearchMemberPopup] = useState(false);

  // const {
  //   data: counts,
  //   isLoading: isLoadingCounts,
  //   isError: isErrorCounts,
  // } = useGetDetaxBatchesCount(activeTab);

  // useEffect(() => {
  //   const run = async () => {
  //     const result = await BatchesService.getDetaxBatchCounts(activeTab);
  //     console.log(result);
  //   };
  //   run();
  // }, [activeTab]);
  //console.log("data: ", counts);
  return (
    <div>
      <PageTitle title="Batches" />
      <Heading title="Batches" />
      <TransactionTabs />
      {showUploadTransactionScreen ? (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            <div className="steps">
              <h3 className="page-heading mb-3"> Please Review</h3>
            </div>
            <div className="steps  mr-4">
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small"
                onClick={() => setShowSearchMemberPopup(true)}
              >
                Add Member
              </Button>
            </div>
          </div>
          <EmployeeDataTable />

          <SearchMemberPopup
            show={showSearchMemberPopup}
            closeModal={() => setShowSearchMemberPopup(false)}
          />
        </WhiteCard>
      ) : (
        <>
          {/* <SearchTXN /> */}
          <div className="mt-4"></div>
          <StatusSection isLoading />
          <div className="mt-2"></div>
          <TransactionTable />
        </>
      )}
    </div>
  );
};

export default UploadTXN;
