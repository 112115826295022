import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { State, store } from "../../state/store";

export interface IAllEmployeesFilter {
  status: string;
  label: string;
}

export interface AllEmployeesState {
  filter: IAllEmployeesFilter;
}

const initialAllEmployeesState: AllEmployeesState = {
  filter: {
    status: "ALL",
    label: "All",
  },
};

const allEmployeesSlice = createSlice({
  name: "allEmployees",
  initialState: initialAllEmployeesState,
  reducers: {
    setFilterTypeStatus: (
      state,
      { payload: { status, label } }: PayloadAction<IAllEmployeesFilter>
    ) => {
      state.filter.status = status;
      state.filter.label = label;
    },
  },
});

export namespace AllEmployeesDispatch {
  export const setFilterTypeStatus = (payload: IAllEmployeesFilter) => {
    store.dispatch(allEmployeesSlice.actions.setFilterTypeStatus(payload));
  };
}

export namespace AllEmployeesReduxStore {
  export const selectFilter = (state: State) => state.allEmployees.filter;
}

export default allEmployeesSlice.reducer;
