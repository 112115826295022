import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import OtpField from "react-otp-field";
import { Link, useNavigate } from "react-router-dom";
import { Button, CardButton, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { AuthService } from "../../services/auth.service";
import { setToken } from "../../state/slice/token.slice";
import { selectLogin } from "../../state/slice/user.slice";
import { fromPublic } from "../../utils/common.utils";
import { setErrorMessages } from "../../utils/error.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import {
  selectLoginId,
  setCurrentScreen,
  setIsAuthenticated,
} from "../app/app.slice";
import SpinnerComponent from "../common/loader/spinner";

const Image1 = fromPublic("/images/otp.svg");

export const LoginVerificationPage: React.FC = () => {
  const loginId = useAppSelector(selectLoginId);
  const loginUser = useAppSelector(selectLogin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [numberOtp, setNumberOtp] = useState("");
  const [otpCounter, setOtpCounter] = useState(25);

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);

  const handleNumberOtpChange = (numberOtp: any) => {
    setNumberOtp(numberOtp);
  };

  const handleResendOtp = () => {
    setOtpCounter(25);

    setNumberOtp("");
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    setError("");
    setIsLoading(true);

    const result = await AuthService.verifyLogin(loginId, numberOtp);
    console.log("verifyResult", result);
    if (isSuccess(result)) {
      setIsLoading(false);
      if (unWrapOption(result.value.successful, () => false)) {
        dispatch(
          setToken({
            accessToken: unWrapOption(result.value.accessToken, () => ""),
            refreshToken: unWrapOption(result.value.refreshToken, () => ""),
            tokenExpiry: unWrapOption(result.value.expiry, () => 0),
            scope: unWrapOption(result.value.scope, () => ""),
          })
        );
        // keep user data in local storage

        MyStorage.AuthUser.StoreData(result.value.data);

        const scope = unWrapOption(result.value.scope, () => "");
        dispatch(setIsAuthenticated(true));

        navigate(RoutePaths.NavigateToOnboarding);
      } else {
        setError(unWrapOption(result.value.message, () => "Invalid OTP"));
      }
    } else if (isFailure(result)) {
      setIsLoading(false);
      setError(setErrorMessages(result.error));
    }
  };
  const goBack = () => {
    navigate(RoutePaths.Login);
  };

  return (
    <div>
      <div className="container p-0">
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="Mobile"
                  />
                </div>
                <div className="textDetail mt-5">
                  <Heading
                    className="mb-3 mx-5 mt-85"
                    text="OTP Capture"
                    variant="small"
                  />

                  <p className="fw-bolder mx-5 text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India ! Please
                    do not share this OTP with anyone else !
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4  col-lg-4  col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="card shadow px-3 py-2 bg-grey shadow rounded-15 border-0">
                    {/* <h2 className="">User Verification</h2> */}
                    <Heading
                      className="text-center mt-5"
                      text="OTP Verification"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="alert alert-danger text-center fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <span className="text-center fw-bold text-black mt-3 mb-3 text-capitalize">
                      We have sent the code verification to your <br />
                      mobile number
                    </span>
                    <span className="text-center fw-bold text-black mb-1 mt-3  fs-5">
                      +91-{loginUser.mobile} &nbsp;
                      <CardButton icon={"edit"} onClick={goBack} />
                    </span>
                    <div
                      id="otp"
                      className="inputs d-flex flex-row justify-content-center mt-3 mb-3"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={numberOtp}
                        onChange={handleNumberOtpChange}
                        numInputs={6}
                        onChangeRegex={/^([0-9]{0,})$/}
                        autoFocus
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>

                    <p className="fw-bolder text-secondary text-center mb-0 mt-1">
                      Didn't receive Verification Code?
                    </p>
                    <Link
                      to=""
                      className={
                        "fw-bolder text-darkblue text-center nav-link " +
                        (otpCounter <= 0 ? " " : "disabled")
                      }
                      onClick={handleResendOtp}
                    >
                      Resend Code{" "}
                      <span className="text-danger mt-2">00:{otpCounter}</span>
                    </Link>

                    {/* <ResendButton /> */}

                    <div className="text-center ml-auto mr-auto mt-5 mb-20">
                      <Button
                        type="button"
                        className={
                          numberOtp.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!numberOtp}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Continue</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="card  p-2 bg-grey shadow border-0">
                {error && (
                  <div
                    className="alert alert-danger text-center fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid p-2"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="BG"
                  />
                </div>

                <div className="card inputCard p-3 mt-2 mb-3 d-md-none d-lg-none d-xl-none d-xxl-none">
                  <Heading
                    className="text-center mt-0"
                    text="OTP Verification"
                    variant="small"
                  />
                  <span className="text-center fs-small fw-bold text-secondary mt-0">
                    We have sent the code verification to your mobile number
                  </span>
                  <span className="text-center fw-bold text-black mt-2  fs-5">
                    +91-{loginUser.mobile} &nbsp;
                    <CardButton icon={"edit"} onClick={goBack} />
                  </span>

                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mb-4 mt-2"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={numberOtp}
                      onChange={handleNumberOtpChange}
                      numInputs={6}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>

                  <p className="fw-bolder text-secondary text-center mb-0 ">
                    Didn't receive Verification Code?
                  </p>
                  {/* <ResendButton /> */}
                  <Link
                    to=""
                    className={
                      "fw-bolder text-darkblue text-center nav-link " +
                      (otpCounter <= 0 ? " " : "disabled")
                    }
                    onClick={handleResendOtp}
                  >
                    Resend Code{" "}
                    <span className="text-danger mt-2">00:{otpCounter}</span>
                  </Link>
                </div>
                <div className="text-center ml-auto mr-auto mb-3">
                  <Button
                    type="button"
                    className={
                      numberOtp.length !== 6
                        ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                        : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                    }
                    disabled={!numberOtp}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <SpinnerComponent /> : <>Continue</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </div>
  );
};
