import React, { useState } from "react";
import CustomSearchBar from "../../custom-search/custom-search";
import WhiteCard from "../../white-card/white-card";
import { Button } from "react-bootstrap";
import { fromPublic } from "../../../utils/common.utils";
import ReuseIcon from "../../common/reuse-icons/reuse-icons";
import { Link } from "react-router-dom";
import DateRangeDropdown from "./date-range-dropdown";
const Filter = fromPublic("/images/filter.svg");
interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const AllEmployeesSearch: React.FC<{
  searchVal: string;
  setSearchVal: (val: string) => void;
  filterDate: any;
  setFilterDate: (val: any) => void;
  onSubmit: boolean;
  setOnSubmit: (val: boolean) => void;
  search: boolean;
  setSearch: (val: boolean) => void;
}> = ({
  searchVal,
  setSearchVal,
  filterDate,
  setFilterDate,
  onSubmit,
  setOnSubmit,
  search,
  setSearch,
}) => {
  return (
    <WhiteCard className="mb-4 mt-2 p-1 rounded">
      <div className="d-flex justify-content-between">
        <CustomSearchBar
          value={searchVal}
          onChange={(val: any) => setSearchVal(val)}
          boxShadow="none"
          height="auto"
          width="70%"
          text="Search by Employee Name/E-mail/Mobile "
          search={search}
          setSearch={(val: boolean) => setSearch(val)}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="me-2"
        >
          <Button
            className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2"
            onClick={() => setSearch(!search)}
          >
            Search
          </Button>
          {/* <DateRangeDropdown
            startDate={filterDate.startDate}
            endDate={filterDate.endDate}
            onChange={(data: IDateRange) => setFilterDate(data)}
          /> */}
        </div>
      </div>
    </WhiteCard>
  );
};

export default AllEmployeesSearch;
