import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "././reject-popup.scss";

const UpdatePopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onUpdate: () => void;
}> = ({ show, onHide, onUpdate }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-success d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#10003;</div>
        <Modal.Title className="text-light">Confirmation</Modal.Title>
        <p className="modal-title text-white">Do you want to Update?</p>
      </Modal.Header>

      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
        <Button variant="success" onClick={() => onUpdate()}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePopup;
