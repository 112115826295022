import React, { useState } from "react";
import CampaignTab from "./campaign-tab";
import { CampaignTabKind } from "./campaign.utils";
import CampaignTable from "./campaign-table";
import { Detail } from "./settings";

interface props {
  setDetail: (val: Detail) => void;
  detail: Detail;
}

const Campaigns: React.FC<props> = ({ setDetail, detail }) => {
  const [activeTab, setActiveTab] = useState<CampaignTabKind>(
    CampaignTabKind.LIVE
  );
  return (
    <div>
      <CampaignTab
        activeTab={activeTab}
        setActiveTab={(tabType: CampaignTabKind) => setActiveTab(tabType)}
      />
      <CampaignTable
        setDetail={(val: Detail) => setDetail(val)}
        detail={detail}
        activeTab={activeTab}
      />
    </div>
  );
};

export default Campaigns;
