/**
 * Ncaish Admin API
 * Apis to super admin operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TrialCountInfo {
  label?: string;
  state?: TrialCountInfo.StateEnum;
  count?: number;
}
export namespace TrialCountInfo {
  export type StateEnum = "ALL" | "PENDING" | "LIVE" | "EXPIRED";
  export const StateEnum = {
    ALL: "ALL" as StateEnum,
    PENDING: "PENDING" as StateEnum,
    LIVE: "LIVE" as StateEnum,
    EXPIRED: "EXPIRED" as StateEnum,
  };
}
