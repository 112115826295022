import { Link } from "react-router-dom";
import { adminTableHeight } from "../../../utils/common.utils";
import { RoutePaths } from "../../../utils/route.utils";
const EmployeeTable = () => {
  return (
    <div
      className="row mt-2 ms-1 me-1 p-3 border rounded-15"
      style={{ background: "white" }}
    >
      <table className="custom-table" style={{ maxHeight: adminTableHeight() }}>
        <thead>
          <tr>
            <th scope="col">Employee Code</th>
            <th scope="col">Joining Date</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link
                className="text-secondary ncash_nav-link"
                to={RoutePaths.EmployeesDetail}
              >
                NC0001
              </Link>
            </td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-primary">Active</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-warning">Inactive</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-success">Activated</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-secondary">Deactivated</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-danger">Cancelled</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-primary">Active</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-warning">Inactive</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-success">Activated</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-secondary">Deactivated</td>
          </tr>
          <tr>
            <td>NC0001</td>

            <td>17-09-2021</td>
            <td>Aman Khurana</td>
            <td>+91-8778379273</td>
            <td>email.gmail.com</td>

            <td className="text-danger">Cancelled</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
