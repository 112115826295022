import { isSome } from "fp-ts/lib/Option";
import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import PageLoader from "../../../page-loader";
import { AllEmployeesReduxStore } from "../../../state/slice/allEmployee.slice";

import CountCard from "./common/count-card";
import { useGetAllEmployeesStatusCount } from "./hooks/allEmployees.hooks";

const AllEmployeesStatusCount: React.FC = () => {
  const filter = useAppSelector(AllEmployeesReduxStore.selectFilter);

  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError: isErrorCounts,
  } = useGetAllEmployeesStatusCount();

  if (isLoadingCounts) {
    return <PageLoader />;
  }
  if (counts && isSome(counts)) {
    return (
      <div className="card-counts">
        {counts.value.length > 0 ? (
          counts.value.map((item, index) => (
            <CountCard
              key={index}
              count={item.count || 0}
              status={item.status || ""}
              label={item.label || ""}
              isActive={Boolean(
                item.label
                  ? item.label.toLowerCase() === filter.label.toLowerCase()
                  : false
              )}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }
  return <div></div>;
};

export default AllEmployeesStatusCount;
