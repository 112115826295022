import React, { useState } from "react";
// import { Button, Heading, MediumScreen, SmallScreen } from "..";
import Button from "../../common/button/button";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../utils/route.utils";
import WhiteCard from "../../white-card/white-card";
import AddNewKitPopup from "../../pages/dashboard/common/add-new-kit";
import SpinnerComponent from "../../common/loader/spinner";
const KitAddNew: React.FC = () => {
  const [showkitPopup, setShowKitPopup] = useState(false);
  const [isLoading, setLoader] = React.useState(false);
  return (
    <>
      <div className="w-20 text-end ml-auto mr-auto mb-1 mt-1">
        <button
          type="submit"
          className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary"
          onClick={() => {
            setShowKitPopup(true);
            // setSelectedCorporateId(item.id || "");
          }}
        >
          Add New Kit
        </button>
      </div>
      {/* <div className="text-center ml-auto mr-auto mb-5 mt-5">
        <Button type="submit">
          {isLoading ? <SpinnerComponent /> : <>Continue</>}
        </Button>
      </div> */}
      <AddNewKitPopup
        show={showkitPopup}
        onHide={() => {
          setShowKitPopup(false);
        }}
        onDelete={() => {
          setShowKitPopup(false);
        }}
      />
    </>
  );
};

export default KitAddNew;
