import { isSuccess } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig } from "axios";
import { setCurrentScreen } from "../components/app/app.slice";
import { AuthService } from "../services/auth.service";
import { ProfileDispatch, resetProfile } from "../state/slice/profile.slice";
import {
  initialTokenState,
  setToken,
  TokenState,
} from "../state/slice/token.slice";
import { dispatchOnCall, store } from "../state/store";
import { TAuthResponse } from "../types/auth-response";
import { TOAuthLoginResponse } from "../types/oauth-login-response.type";
import { LocalStorageItemKeys, MyStorage } from "./local-storage.utils";
import { unWrapOption } from "./option.utils";
import { allowedUids } from "../app";

export function CalculateTokenExpiryTime(expiresInSeconds: number): number {
  return GetSafeExpiryMillis(expiresInSeconds) + new Date().getTime();
}

function GetSafeExpiryMillis(expiresInSeconds: number): number {
  const TokenExpiryBufferMillis = 300000; // 5 minutes
  const defaultExpiryMillis = expiresInSeconds * 1000;

  // don't allow expiry to be less than 5 minutes
  if (defaultExpiryMillis < TokenExpiryBufferMillis) {
    return defaultExpiryMillis;
  }

  return defaultExpiryMillis - TokenExpiryBufferMillis;
}

export async function GetAuthorizationHeader(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  let accessToken = "";
  const tokenState = store.getState().token;
  const expiresAt = tokenState.expiresAt;

  if (IsExpired(expiresAt)) {
    // Stop refresh token logic here add this in axios interceptor.
    // const result = await AuthService.refreshOAuthToken(tokenState.refreshToken);
    // console.log("refresh token response:  ", result);
    // if (isSuccess(result)) {
    //   SetOAuthTokenInStore(result.value);
    //   accessToken = unWrapOption(result.value.access_token, () => "");
    // }
  } else {
    accessToken = tokenState.accessToken;
  }

  if (accessToken !== "") {
    config = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${accessToken}` },
    };
  }

  return config;
}

export function IsExpired(expiresAt: number): boolean {
  return new Date().getTime() > expiresAt;
}

export function GetTokenFromLocalStorage(): TokenState {
  const token = { ...initialTokenState };

  const authToken = MyStorage.AuthToken.LoadData();
  if (authToken) {
    const authObj = authToken; //JSON.parse(authToken);

    if (authObj.accessToken && authObj.refreshToken && authObj.tokenExpiry) {
      token.accessToken = authObj.accessToken;
      token.refreshToken = authObj.refreshToken;
      token.tokenExpiry = authObj.tokenExpiry;
      token.scope = authObj.scope;
      token.expiresAt = CalculateTokenExpiryTime(authObj.tokenExpiry);
    }
  }

  return token;
}

export function GetRefreshTokenFromLocalStorage(): string {
  const token = GetTokenFromLocalStorage();
  return token.refreshToken;
}

export function SetTokenInStore(
  auth: TAuthResponse,
  refreshToken: string
): void {
  dispatchOnCall(
    setToken({
      accessToken: unWrapOption(auth.accessToken, () => ""),
      refreshToken: refreshToken,
      tokenExpiry: unWrapOption(auth.expiry, () => 0),
      scope: unWrapOption(auth.scope, () => ""),
    })
  );
}

export async function refreshOAuthTokenLogic(failedRequest: any) {
  const result = await AuthService.refreshOAuthToken(
    GetRefreshTokenFromLocalStorage()
  );

  console.log("RefreshToken Response: ", result);
  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false)) {
      SetOAuthTokenInStore(result.value);

      const allow = allowedUids.includes(
        unWrapOption(result.value.id, () => "")
      );
      ProfileDispatch.setApprovedSuperAdmin(allow);

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + unWrapOption(result.value.access_token, () => "");
    } else {
      //console.log("RefreshToken Error: ", result);
      localStorage.clear();
      window.location.href = "/login";
    }
  } else {
    //console.log("RefreshToken Error: ", result);
    localStorage.clear();
    window.location.href = "/login";
  }

  return Promise.resolve();
}

export function SetOAuthTokenInStore(auth: TOAuthLoginResponse): void {
  dispatchOnCall(
    setToken({
      accessToken: unWrapOption(auth.access_token, () => ""),
      refreshToken: unWrapOption(auth.refresh_token, () => ""),
      tokenExpiry: unWrapOption(auth.expires_in, () => 0),
      scope: unWrapOption(auth.scope, () => ""),
    })
  );
}
