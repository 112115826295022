import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isSome, none, Option, some } from "fp-ts/lib/Option";
import { State } from "../../state/store";
import { IUser } from "../../types/user.type";
import { LocalStorageItemKeys } from "../../utils/local-storage.utils";

export type TCommonError = { showError: boolean; errorText: string };
export type TBadRequestError = { showError: boolean; errorText: string[] };
export interface AppState {
  draftId: Option<string>;
  loginId: Option<string>;
  currentScreen: string;
  user: Option<IUser>;
  loading: boolean;
  goToPageAfterLogin: string;
  visitedPages: number[];
  isAuthenticated: boolean;
  commonError: TCommonError;
  badRequestError: TBadRequestError;
}

const initialState: AppState = {
  draftId: none,
  loginId: none,
  currentScreen: "/",
  user: none,
  loading: true,
  goToPageAfterLogin: "/",
  visitedPages: [],
  isAuthenticated: true,
  commonError: { showError: false, errorText: "" },
  badRequestError: { showError: false, errorText: [] },
};

const slice = createSlice({
  name: "sa-app",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setDraftId: (
      state,
      { payload: draftId }: PayloadAction<Option<string>>
    ) => {
      if (isSome(draftId)) {
        localStorage.setItem(LocalStorageItemKeys.draftId, draftId.value);
      }
      state.draftId = draftId;
    },
    setLoginId: (
      state,
      { payload: loginId }: PayloadAction<Option<string>>
    ) => {
      if (isSome(loginId)) {
        localStorage.setItem(LocalStorageItemKeys.loginId, loginId.value);
      }
      state.loginId = loginId;
    },
    setCurrentScreen: (state, { payload: screen }: PayloadAction<string>) => {
      const currentRoute = screen.includes("/")
        ? screen.toLowerCase()
        : `/${screen.toLowerCase()}`;
      localStorage.setItem(
        LocalStorageItemKeys.currentScreenRoute,
        currentRoute
      );
      state.currentScreen = currentRoute;
    },
    updatePageAfterLogin: (
      state,
      { payload: screen }: PayloadAction<string>
    ) => {
      state.goToPageAfterLogin = screen;
    },
    loadUser: (state, { payload: user }: PayloadAction<IUser>) => {
      state.user = some(user);
      state.loading = false;
    },
    logout: (state) => {
      state.user = none;
    },
    endLoad: (state) => {
      state.loading = false;
    },
    numberOfVisitedPages: (state, { payload }: PayloadAction<number[]>) => {
      state.visitedPages = payload;
    },
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    setCommonError: (
      state,
      { payload: error }: PayloadAction<TCommonError>
    ) => {
      state.commonError.errorText = error.errorText;
      state.commonError.showError = error.showError;
    },
    setBadRequestError: (
      state,
      { payload: error }: PayloadAction<TBadRequestError>
    ) => {
      console.log("State error: ", error);
      state.badRequestError = error;
      //state.badRequestError.errorText = error.errorText;
      //state.badRequestError.showError = error.showError;
    },
  },
});

export const {
  setDraftId,
  setLoginId,
  setCurrentScreen,
  loadUser,
  logout,
  endLoad,
  initializeApp,
  updatePageAfterLogin,
  numberOfVisitedPages,
  setIsAuthenticated,
  setCommonError,
  setBadRequestError,
} = slice.actions;

export const selectDraftId = (state: State) =>
  isSome(state.app.draftId) ? state.app.draftId.value : "";
export const selectLoginId = (state: State) =>
  isSome(state.app.loginId) ? state.app.loginId.value : "";
export const selectCurrentScreen = (state: State) => state.app.currentScreen;
export const selectLoading = (state: State) => state.app.loading;
export const selectGoToPageAfterLogin = (state: State) =>
  state.app.goToPageAfterLogin;

export const selectVisitedPages = (state: State): number[] =>
  state.app.visitedPages;

export const selectIsAuthenticated = (state: State) =>
  state.app.isAuthenticated;

export const selectCommonError = (state: State): TCommonError =>
  state.app.commonError;

export const selectBadRequestError = (state: State): TBadRequestError => {
  return state.app.badRequestError;
};

export default slice.reducer;
