import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUserInfo } from "../../api/employee/model/adminUserInfo";
import { State, store } from "../../state/store";

export interface UserState {
  data: AdminUserInfo | null;
  approvedSuperAdmin: boolean;
}

const initialState: UserState = {
  data: null,
  approvedSuperAdmin: false,
};

const slice = createSlice({
  name: "sa-profile",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setProfile: (state, { payload }: PayloadAction<AdminUserInfo | null>) => {
      state.data = payload;
    },
    setApprovedSuperAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.approvedSuperAdmin = payload;
    },
    resetProfile: (state) => {
      state.data = null;
    },
  },
});

export const { setProfile, resetProfile, setApprovedSuperAdmin } =
  slice.actions;
export const selectProfile = (state: State) => state.profile.data;
export const selectApprovedSuperAdmin = (state: State) =>
  state.profile.approvedSuperAdmin;

export default slice.reducer;

export namespace ProfileDispatch {
  export const setApprovedSuperAdmin = (payload: boolean) =>
    store.dispatch(slice.actions.setApprovedSuperAdmin(payload));
}

export namespace ProfileSelect {
  export const selectApprovedSuperAdmin = (state: State) =>
    state.profile.approvedSuperAdmin;
}
