import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishMembershipBatchApiService } from "../api/detax/api/ncaishMembershipApi.service";
import { MembershipBatchCountApiResponse } from "../api/detax/model/membershipBatchCountApiResponse";
import {
  TAdminMembershipBatchListApiResponse,
  TAdminMembershipBatchListInfo,
  TMembershipAdminBatchDetailApiResponse,
  TMembershipBatchCountApiResponse,
  TMembershipSubscriptionListApiResponse,
  TMembershipTypeCountApiResponse,
} from "../models/membership.models";
import { fromNullable, isSome, none, Option } from "fp-ts/lib/Option";
import { AdminMembershipBatchListApiResponse } from "../api/detax/model/membershipBatchListApiResponse";
import { AdminMembershipBatchListInfo } from "../api/admin/model/membership/adminMembershipBatchListInfo";
import { MembershipAdminBatchDetailApiResponse } from "../api/detax/model/MembershipAdminBatchDetailApiResponse";
import { MembershipTypeCountApiResponse } from "../api/detax/model/membershipTypeCountApiResponse";
import { AdminMembershipBatchInfo } from "../api/admin/model/membership/adminMembershipBatchInfo";
import { option } from "fp-ts";
import { MembershipSubscriptionListApiResponse } from "../api/detax/model/MembershipSubscriptionListApiResponse";

export namespace MembershipService {
  export const getMembershipBatchCounts = async () => {
    const result =
      await NcaishMembershipBatchApiService.getMembershipBatchCounts();

    return remoteData.map(
      result,
      (
        response: MembershipBatchCountApiResponse
      ): TMembershipBatchCountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getMembershipBatchByStatus = async ({
    batchStatus = "All",
  }: {
    batchStatus?: string;
  }) => {
    const status = batchStatus.toLowerCase() === "all" ? "" : batchStatus;
    const result =
      await NcaishMembershipBatchApiService.getMembershipBatchByStatus(
        status,
        "",
        "",
        ""
      );

    return remoteData.map(
      result,
      (
        response: AdminMembershipBatchListApiResponse
      ): TAdminMembershipBatchListApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getMembershipBatchDetailByBatchAndCorpId = async (
    cId: string,
    batchId: string
  ) => {
    const result =
      await NcaishMembershipBatchApiService.getMembershipBatchDetailByBatchAndCorpId(
        cId,
        batchId
      );
    return remoteData.map(
      result,
      (
        response: MembershipAdminBatchDetailApiResponse
      ): TMembershipAdminBatchDetailApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const updateMembershipBatchDetailByBatchAndCorpId = async (
    cId: String,
    batchId: String,
    status: String,
    amtRecieved?: Number,
    transId?: String,
    transDate?: String,
    paymentMadeDate?: String
  ) => {
    const result =
      await NcaishMembershipBatchApiService.updateMembershipBatchDetailByBatchAndCorpId(
        cId,
        batchId,
        status,
        amtRecieved,
        transId,
        transDate,
        paymentMadeDate
      );
    return remoteData.map(
      result,
      (
        response: MembershipAdminBatchDetailApiResponse
      ): TMembershipAdminBatchDetailApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const getMembershipCountWithCId = async (
    cId: string | String,
    type: string | String
  ) => {
    const result =
      await NcaishMembershipBatchApiService.getMembershipCountWithCId(
        cId,
        type
      );
    return remoteData.map(
      result,
      (
        response: MembershipTypeCountApiResponse
      ): TMembershipTypeCountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          count: fromNullable(response.count),
        };
      }
    );
  };

  export const getMembershipSubcriptionListWithCId = async (cId: String) => {
    const result =
      await NcaishMembershipBatchApiService.getMembershipSubcriptionListWithCId(
        cId
      );
    return remoteData.map(
      result,
      (
        response: MembershipSubscriptionListApiResponse
      ): TMembershipSubscriptionListApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  export const parseAdminMembershipBatchInfo = (
    data: Option<AdminMembershipBatchListInfo[]>
  ): TAdminMembershipBatchListInfo[] => {
    const parseData: TAdminMembershipBatchListInfo[] = isSome(data)
      ? data.value.map((d) => ({
          cid: fromNullable(d.cid),
          batchCode: fromNullable(d.batchCode),
          users: fromNullable(d.users),
          batchStatus: fromNullable(d.batchStatus),
          transactionDate: fromNullable(d.transactionDate),
          batchAmount: fromNullable(d.batchAmount),
          amountRecieved: fromNullable(d.amountRecieved),
          id: fromNullable(d.id),
          corpName: fromNullable(d.corpName),
          objectId: fromNullable(d.objectId),
          deletedAt: fromNullable(d.deletedAt),
          updatedAt: fromNullable(d.updatedAt),
          createdAt: fromNullable(d.createdAt),
          archive: fromNullable(d.archive),
        }))
      : [];
    return parseData;
  };
}
