import { isSuccess } from "@devexperts/remote-data-ts";
import { DashoardService } from "../services/dashboard.service";
import {
  endDashboardLoad,
  setDashboardData,
} from "../state/slice/dashboard.slice";
import { store } from "../state/store";
import { option } from "fp-ts";
import { TOnBoardingApplicationCountInfo } from "../types/admin/onboarding-application-count.type";

export async function loadDashboardData() {
  const result = await DashoardService.getDashboardData();
  if (isSuccess(result)) {
    store.dispatch(setDashboardData(result.value));
    store.dispatch(endDashboardLoad());
  } else {
    store.dispatch(endDashboardLoad());
  }
}

export function addAllDashboardCount(data: TOnBoardingApplicationCountInfo[]) {
  const allCount: TOnBoardingApplicationCountInfo = {
    state: option.some("ALL"),
    label: option.some("All"),
    count: option.some(
      data.reduce((a, c) => a + option.getOrElse(() => 0)(c.count), 0)
    ),
  };
  const counts: TOnBoardingApplicationCountInfo[] = [allCount, ...data];
  return counts;
}
