import { isSuccess } from "@devexperts/remote-data-ts";
import { none, some, Option, isNone } from "fp-ts/lib/Option";
import React, { useEffect } from "react";
import { MembershipService } from "../../../../services/membership.service";
import { unWrapOption } from "../../../../utils/option.utils";

const DetaxPlusSubscription: React.FC<{
  cId?: String;
}> = ({ cId }) => {
  const [count, setCount] = React.useState<Option<Option<string>>>(none);

  useEffect(() => {
    const run = async () => {
      if (cId) {
        const result = await MembershipService.getMembershipCountWithCId(
          cId,
          "DETAX_PLUS"
        );
        if (isSuccess(result)) {
          if (result.value.successful) {
            // dispatch(setCompanyInfo(result.value.data));
            setCount(some(result.value.count || 0));
          } else {
            //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
          }
        } else {
          //setBalance(some(0));
        }
      }
    };
    run();
  }, [cId]);
  if (isNone(count)) {
    return <>loading...</>;
  }
  return (
    <h5 className="fs-small fw-bold mb-0">
      {unWrapOption(count.value, () => "0")}
    </h5>
  );
};

export default DetaxPlusSubscription;
