import React, { useEffect, useState } from "react";
import { MembershipCountInfo } from "../../../api/admin/model/membershipCountInfo";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import MembershipStatusCount from "./membership-status-count";
import MembershipTable from "./memberShip-Table";

const Membership: React.FC = () => {
  return (
    <>
      <PageTitle title="Membership" />
      <Heading title="Membership" />
      <div className="mt-4"></div>
      <MembershipStatusCount />
      <div className="mt-2"></div>
      <MembershipTable />
    </>
  );
};

export default Membership;
