/**
 * Ncaish Corporate apis
 * Api to manage corporate onboarding ,application and employee management
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { DeliveryUploadRequest } from "../../../model/deliveryUploadRequest";
import { DeliveryUploadResponse } from "../../../model/deliveryUploadResponse";
import { AdminApplicationListResponse } from "../model/adminApplicationListResponse";
import { ApplicationApprovalRequest } from "../model/applicationApprovalRequest";
import { ApplicationCountApiResponse } from "../model/applicationCountApiResponse";
import { ApplicationDetailApiResponse } from "../model/applicationDetailApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishCorporateApplicationApiService {
  /**
   * Api to fetch status wise applications count
   */
  export const applicationCounts1 = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationCountApiResponse>> => {
    const path = `/secure/corporates/applications/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get application details
   * @param id -
   */
  export const detail1 = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/corporates/applications/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to fetch status wise applications list
   * @param state -
   */
  export const fetchByState1 = async (
    state?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/corporates/applications/list`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "state",
        value: state,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const fetchByStateSearch = async (
    keyword?: string,
    state?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path =
      `/secure/corporates/applications/search/all/corporates/application`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "status",
        value: state,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  // KIT MANAGEMENT
  /**
   * Api to fetch status wise applications list
   * @param state -
   */
  export const fetchByState1Kit = async (
    state?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/detax/card/kits`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "state",
        value: state,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  // THIS IS FOR EMPLOYEE API
  export const fetchByTypeEmpKit = async (
    state?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/detax/card/kits/`.replace(/^\//, "");
    // const path = `/secure/detax/card/kits/${encodeURIComponent(
    //   String(state)
    // )}`
    const allQueryParams: TQueryParam[] = [
      {
        key: "state",
        value: state,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    // const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    const apiUrl = path.concat(`?type=${state}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  // LIST BY STATUS
  export const fetchByStatus = async (
    state?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/detax/card/kits/`.replace(/^\//, "");
    // const path = `/secure/detax/card/kits/${encodeURIComponent(
    //   String(state)
    // )}`
    const allQueryParams: TQueryParam[] = [
      {
        key: "state",
        value: state,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    // const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    const apiUrl = path.concat(`?status=${state}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  export const createEmp = async (
    type?: string,
    kitNumbers?: string[],
    provider?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminApplicationListResponse>> => {
    const path = `/secure/detax/card/kits`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;

    // const data = new URLSearchParams();
    const data = { type, kitNumbers, provider };
    // data.append("type", body.username);
    // data.append("kitNumbers", body.password);
    // data.append("grant_type", "password");

    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      url: apiUrl,
      method: "post",
      data: data,
      headers: { ...headers },
    };
    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get application details
   * @param body -
   * @param id -
   */
  export const handleApproval = async (
    body: ApplicationApprovalRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ApplicationDetailApiResponse>> => {
    const path = `/secure/corporates/applications/approval/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const deliveryUpload = async (
    body: Array<DeliveryUploadRequest>,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DeliveryUploadResponse>> => {
    const path = `/secure/detax/cards/addTrackingNo`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
