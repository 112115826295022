//trialCounts

import { remoteData } from "@devexperts/remote-data-ts";
import { NCaishAdminTrialApiService } from "../api/admin/api/nCaishAdminTrialApi.service";
import { AdminTrialCountApiResponse } from "../api/admin/model/adminTrialCountApiResponse";
import { AdminTrialDetailApiResponse } from "../api/admin/model/adminTrialDetailApiResponse";
import { AdminTrialsApiResponse } from "../api/admin/model/adminTrialsApiResponse";
import { LeadRequestFilter } from "../api/admin/model/leadRequestFilter";

export namespace TrialService {
  export const trialCounts = async () => {
    const result = await NCaishAdminTrialApiService.trialCounts();
    return remoteData.map(
      result,
      (response: AdminTrialCountApiResponse) => response
    );
  };
  export const fetchByState = async ({
    type,
    mobile,
    from,
    to,
    state,
  }: LeadRequestFilter) => {
    const paylaod = { type, mobile, from, to };

    const requestPayload = state === "ALL" ? paylaod : { ...paylaod, state };

    const result = await NCaishAdminTrialApiService.fetchByState(
      requestPayload
    );
    return remoteData.map(
      result,
      (response: AdminTrialsApiResponse) => response
    );
  };
  export const detail = async (id: string) => {
    const result = await NCaishAdminTrialApiService.detail(id);
    return remoteData.map(
      result,
      (response: AdminTrialDetailApiResponse) => response
    );
  };
}
