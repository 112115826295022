/**
 * Ncaish Lead/Trial API
 * Api to manage lead onboarding and trials
 *
 * OpenAPI spec version: @springdoc.version@
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { CreateLeadApiRequest } from "../model/createLeadApiRequest";
import { NcaishMyLeadApiResponse } from "../model/ncaishMyLeadApiResponse";
import { NcaishRegisterLeadApiResponse } from "../model/ncaishRegisterLeadApiResponse";
import { UserVerificationResponse } from "../model/userVerificationResponse";
import { VerifyLeadRequest } from "../model/verifyLeadRequest";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NCaishCaptureLeadApiService {
  /**
   * Api to create lead applicant user
   */
  export const goLive = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UserVerificationResponse>> => {
    const path = `/secure/leads/live`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to create new lead
   * @param body -
   */
  export const registerLead = async (
    body: CreateLeadApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NcaishRegisterLeadApiResponse>> => {
    const path = `/secure/leads/register`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to review the lead
   */
  export const reviewLead = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NcaishMyLeadApiResponse>> => {
    const path = `/secure/leads/review`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to verify lead
   * @param body -
   * @param id -
   */
  export const verifyLead = async (
    body: VerifyLeadRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UserVerificationResponse>> => {
    const path = `/secure/leads/verify/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NCaishCaptureLeadApiService()
