import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../state/store";
import { TFxDashboardResponse } from "../../types/fx/fx-dashboard-response.type";
import { CalculateTokenExpiryTime } from "../../utils/auth.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { isSome, none, Option, some } from "fp-ts/lib/Option";
import { TFxCalculatorData } from "../../types/fx/fx-calculator-data.type";
import { TCurrencyType } from "../../types/fx/currency.type";
import { TRateTypeEnum } from "../../types/fx/fx-saving-request.type";

import { GetFromAndToCurrencyByRateType } from "../../utils/fx.utils";
import { ICurrencyData } from "../../models/currency-data";
import { RealTimeCurrencyPrice } from "../../api/lead/model/realTimeCurrencyPrice";

export type TConversionRequest = {
  from: string;
  to: string;
  amount: Option<number>;
  conversionAmount: Option<number>;
};

export type TSavingRequest = {
  rateType: string;
  fromCurrency: string;
  toCurrency: string;
  date: Option<string>;
  amount: Option<number>;
  savingAmount: Option<number>;
  dayRange: Option<string>;
  bankCredited: Option<number>;
};

export interface FxState {
  fxLoading: boolean;
  dashboard: TFxDashboardResponse;
  conversionRequest: TConversionRequest;
  savingRequest: TSavingRequest;
  savingRequestResultMessage: Option<string>;
  selectedPriceCurrency: string;
  selectedPrice: RealTimeCurrencyPrice;
  rateTypeCurrency: { from: ICurrencyData[]; to: ICurrencyData[] };
}

const initialState: FxState = {
  fxLoading: true,
  dashboard: {
    successful: false,
    message: "",
    savingCalData: {} as TFxCalculatorData,
    prices: {},
    currencies: [],
  },
  conversionRequest: {
    from: "USD",
    to: "INR",
    amount: none,
    conversionAmount: none,
  },
  savingRequest: {
    rateType: "EXPORTER",
    fromCurrency: "",
    toCurrency: "",
    date: none,
    amount: none,
    savingAmount: none,
    dayRange: none,
    bankCredited: none,
  },
  savingRequestResultMessage: none,
  selectedPriceCurrency: "USD_INR",
  selectedPrice: {},
  rateTypeCurrency: { from: [], to: [] },
};

const slice = createSlice({
  name: "sa-fx",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setFxDashboardData: (
      state,
      { payload: dashboardData }: PayloadAction<TFxDashboardResponse>
    ) => {
      const rateTypeCurrency = GetFromAndToCurrencyByRateType(
        dashboardData.savingCalData,
        state.savingRequest.rateType
      );
      state.savingRequest.fromCurrency = rateTypeCurrency
        ? rateTypeCurrency.from[0].code
        : "";
      state.savingRequest.toCurrency = rateTypeCurrency
        ? rateTypeCurrency.to[0].code
        : "";
      state.rateTypeCurrency = rateTypeCurrency || { from: [], to: [] };
      state.dashboard = dashboardData;
    },
    setConversionRequestData: (
      state,
      { payload: conversionData }: PayloadAction<TConversionRequest>
    ) => {
      state.conversionRequest = conversionData;
    },
    setSavingRequestData: (
      state,
      { payload: savingRequestData }: PayloadAction<TSavingRequest>
    ) => {
      const rateTypeCurrency = GetFromAndToCurrencyByRateType(
        state.dashboard.savingCalData,
        savingRequestData.rateType
      );
      state.rateTypeCurrency = rateTypeCurrency || {
        from: state.rateTypeCurrency.from,
        to: state.rateTypeCurrency.to,
      };

      state.savingRequest = savingRequestData;
    },
    updateSavingResponseInStore: (
      state,
      {
        payload: { savingAmount, dayRange, message },
      }: PayloadAction<{
        savingAmount: Option<number>;
        dayRange: Option<string>;
        message: Option<string>;
      }>
    ) => {
      state.savingRequest.savingAmount = savingAmount;
      state.savingRequest.dayRange = dayRange;
      state.savingRequestResultMessage = message;
    },
    updateSelectedPriceCurrencyInStore: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.selectedPriceCurrency = payload;
    },
    updateSelectedPriceInStore: (
      state,
      { payload }: PayloadAction<RealTimeCurrencyPrice>
    ) => {
      state.selectedPrice = payload;
    },
    updatePriceInStore: (
      state,
      {
        payload: { currency, price },
      }: PayloadAction<{ currency: string; price: RealTimeCurrencyPrice }>
    ) => {
      state.selectedPrice = price;
      state.selectedPriceCurrency = currency;
    },
    endFxDataLoad: (state) => {
      state.fxLoading = false;
    },
  },
});

export const {
  setFxDashboardData,
  endFxDataLoad,
  setConversionRequestData,
  setSavingRequestData,
  updateSavingResponseInStore,
  updateSelectedPriceCurrencyInStore,
  updateSelectedPriceInStore,
  updatePriceInStore,
} = slice.actions;

export const selectDashboardData = (state: State) => state.fx.dashboard;
export const selectConversionRequest = (state: State): TConversionRequest =>
  state.fx.conversionRequest;
export const selectSavingRequest = (state: State): TSavingRequest =>
  state.fx.savingRequest;
export const selectSelectedPriceData = (state: State): RealTimeCurrencyPrice =>
  state.fx.selectedPrice;

export const selectSelectedPriceCurrency = (state: State): string =>
  state.fx.selectedPriceCurrency;

export const selectRateTypeCurrency = (state: State) =>
  state.fx.rateTypeCurrency;

export const selectSavingRequestResultMessage = (
  state: State
): Option<string> => state.fx.savingRequestResultMessage;

export const selectFxLoader = (state: State): boolean => state.fx.fxLoading;

export default slice.reducer;
