import classNames from "classnames";
import React from "react";
//import "./status-text.scss";
import "./heading.scss";

const StatusText: React.FC<{
  text?: string;
  className?: string;
}> = ({ text = "", className = "" }) => {
  // return <span className={classNames("status-text", className)}>{text}</span>;
  return (
    <span className={classNames("status-dot-card", className)}>
      <span
        className="status-dot p-2 rounded-1 position-relative text-black"
        style={{ right: "0" }}
      >
        {text === "Service Selection completed" ? "Services selected" : text}
        {/* {text} */}
      </span>
    </span>
  );
};

export default StatusText;
