import React, { useState } from "react";
import { Option, isNone, isSome, none, fold } from "fp-ts/lib/Option";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { useAppSelector } from "../../../../../hooks/hooks";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../../state/slice/detax.slice";
import "../../../dashboard/common/heading.scss";
import {
  fetchBatchDetail,
  fetchBatchNpsDetail,
  getModeOfPayment,
} from "../txn.utils";
import { BatchesService } from "../../../../../services/batches.service";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../../../utils/route.utils";
import InitiateTrasactionPopup, {
  initiateRequest,
} from "./initiate-transaction-popup";
import {
  useInitiateTransactionMutation,
  useInitiateTransactionMutationNps,
} from "../hooks/transaction.hooks.";
import { isSuccess } from "@devexperts/remote-data-ts";
import { InitiateDetaxBatchApiRequest } from "../../../../../api/detax/model/initiateDetaxBatchApiRequest";
import { unWrapOption } from "../../../../../utils/option.utils";
import RejectPopup from "../../common/reject-popup";
import ApprovePopup from "../../common/approve-popup";
import CancelPopup from "../../../../common/popups/cancel-popup";
import ExecutePopup from "../../../../common/popups/execute-popup";
import { Dispatch } from "../../../../../state/dispatch";

const TransactionDetailHeading: React.FC<{
  text: string;
  activeTab: string;
  reportEnableBtn: boolean;
}> = (props) => {
  const batchInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectBatchInfo
  );
  const batchNpsInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectNpsBatchInfo
  );
  const [show, setShowPopup] = useState(false);
  const [showReject, setShowRejectPopup] = useState(false);
  const [showApprove, setShowApprovePopup] = useState(false);
  const [showTransactionCancel, setShowTransactionCancelPopup] =
    useState(false);
  const [showTransactionExecute, setShowTransactionExecutePopup] =
    useState(false);

  const [cancelActionState, setCancelActionState] = React.useState(false);
  const [rejectActionState, setRejectActionState] = React.useState(false);
  const [approveActionState, setApproveActionState] = React.useState(false);
  const [initiateActionState, setInitiateActionState] = React.useState(false);
  const [executeActionState, setExecuteActionState] = React.useState(false);

  const {
    mutateAsync: executeTransactionBatch,
    isLoading: isinitiateTransactionLoading,
  } = useInitiateTransactionMutation();

  const {
    mutateAsync: executeTransactionBatchNps,
    isLoading: isinitiateTransactionLoadingNps,
  } = useInitiateTransactionMutationNps();

  const handleCancelBatch = async (batchId: string, remarks: string) => {
    if (batchId) {
      setCancelActionState(true);
      const result = await BatchesService.rejectBatch(batchId, remarks);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          DetaxDispatch.setBatchInfo(result.value.data);
        } else {
          Dispatch.App.setCommonError({
            showError: true,
            errorText: unWrapOption(result.value.message, () => ""),
          });
        }

        setShowRejectPopup(false);
        setShowTransactionCancelPopup(false);
        setCancelActionState(false);
      } else {
        setCancelActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };
  const handleCancelNpsBatch = async (batchId: string, remarks: string) => {
    if (batchId) {
      setCancelActionState(true);
      const result = await BatchesService.rejectNpsBatch(batchId, remarks);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          DetaxDispatch.setNpsBatchInfo(result.value.data);
        } else {
          Dispatch.App.setCommonError({
            showError: true,
            errorText: unWrapOption(result.value.message, () => ""),
          });
        }

        setShowRejectPopup(false);
        setShowTransactionCancelPopup(false);
        setCancelActionState(false);
      } else {
        setCancelActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };
  const handleApproveBatch = async (batchId: string) => {
    if (batchId) {
      setApproveActionState(true);
      const result = await BatchesService.approveBatch(batchId);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          DetaxDispatch.setBatchInfo(result.value.data);
        } else {
          Dispatch.App.setCommonError({
            showError: true,
            errorText: unWrapOption(result.value.message, () => ""),
          });
        }
        setShowApprovePopup(false);
        setApproveActionState(false);
      } else {
        setApproveActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };

  const handleApproveNpsBatch = async (batchId: string) => {
    if (batchId) {
      setApproveActionState(true);
      const result = await BatchesService.approveNpsBatch(batchId);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          DetaxDispatch.setNpsBatchInfo(result.value.data);
        } else {
          Dispatch.App.setCommonError({
            showError: true,
            errorText: unWrapOption(result.value.message, () => ""),
          });
        }
        setShowApprovePopup(false);
        setApproveActionState(false);
      } else {
        setApproveActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };

  const handleExecuteTransaction = async () => {
    // const dt = new Date(req.date);
    const batchId = isSome(batchInfoOpt)
      ? batchInfoOpt.value.batchId || ""
      : "";

    const requestPayload = {
      // amount: parseInt(req.amount),
      // mode: getModeOfPayment(req.modeOfPayment),
      // time: dt.getTime(),
      // refId: req.referenceId,
      batchId,
    };
    setExecuteActionState(true);
    const result = await executeTransactionBatch(requestPayload);
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        if (batchId) {
          await fetchBatchDetail(batchId);
        }
      } else {
        Dispatch.App.setCommonError({
          showError: true,
          errorText: unWrapOption(result.value.message, () => ""),
        });
      }
      setShowTransactionExecutePopup(false);
      setExecuteActionState(false);
    } else {
      setExecuteActionState(false);
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
    }
  };

  const handleExecuteTransactionNps = async () => {
    // const dt = new Date(req.date);
    const batchId = isSome(batchNpsInfoOpt)
      ? batchNpsInfoOpt.value.batchId || ""
      : "";
    const requestPayload = {
      // amount: parseInt(req.amount),
      // mode: getModeOfPayment(req.modeOfPayment),
      // time: dt.getTime(),
      // refId: req.referenceId,
      batchId,
    };
    setExecuteActionState(true);
    const result = await executeTransactionBatchNps(requestPayload);
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        if (batchId) {
          await fetchBatchNpsDetail(batchId);
        }
      } else {
        Dispatch.App.setCommonError({
          showError: true,
          errorText: unWrapOption(result.value.message, () => ""),
        });
      }
      setShowTransactionExecutePopup(false);
      setExecuteActionState(false);
    } else {
      setExecuteActionState(false);
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h1 className="page-heading">
          <Link to={RoutePaths.DashboardTransaction}>
            <span className="title">Batch</span>
          </Link>{" "}
          {props.text}
        </h1>
        {props.activeTab !== "NPS" &&
          isSome(batchInfoOpt) &&
          batchInfoOpt.value.status === "PAYMENT_CONFIRMED" && (
            <div className="mb-3">
              <button
                className="btn btn-danger text-white px-3 py-2"
                onClick={() => setShowTransactionCancelPopup(true)}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Cancel Batch
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-success text-white px-3 py-2"
                onClick={() => setShowTransactionExecutePopup(true)}
              >
                {executeActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Execute Transaction
              </button>
            </div>
          )}
        {props.activeTab === "NPS" &&
          isSome(batchNpsInfoOpt) &&
          batchNpsInfoOpt.value.status === "PAYMENT_CONFIRMED" && (
            <div className="mb-3">
              <button
                className="btn btn-danger text-white px-3 py-2"
                onClick={() => setShowTransactionCancelPopup(true)}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Cancel Batch
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-success text-white px-3 py-2"
                onClick={() => setShowTransactionExecutePopup(true)}
              >
                {executeActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Execute Transaction
              </button>
            </div>
          )}
        {props.activeTab !== "NPS" &&
          isSome(batchInfoOpt) &&
          batchInfoOpt.value.status === "SUBMITTED" && (
            <div className="mb-3">
              <button
                className="btn btn-danger text-white px-3 py-2"
                onClick={() => setShowRejectPopup(true)}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Reject
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-success text-white px-3 py-2"
                onClick={() => setShowApprovePopup(true)}
                disabled={
                  props.activeTab === "NPS" ? props.reportEnableBtn : false
                }
              >
                {approveActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Approve
              </button>
            </div>
          )}

        {props.activeTab === "NPS" &&
          isSome(batchNpsInfoOpt) &&
          batchNpsInfoOpt.value.status === "SUBMITTED" && (
            <div className="mb-3">
              <button
                className="btn btn-danger text-white px-3 py-2"
                onClick={() => setShowRejectPopup(true)}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Reject
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn btn-success text-white px-3 py-2"
                onClick={() => setShowApprovePopup(true)}
                disabled={
                  props.activeTab === "NPS" ? props.reportEnableBtn : false
                }
              >
                {approveActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Approve
              </button>
            </div>
          )}
      </div>

      <InitiateTrasactionPopup
        show={show}
        closeModal={() => setShowPopup(false)}
        onSubmit={
          props.activeTab === "NPS"
            ? handleExecuteTransactionNps
            : handleExecuteTransaction
        }
      />

      <RejectPopup
        show={showReject}
        onHide={() => setShowRejectPopup(false)}
        onReject={(remark) =>
          props.activeTab === "NPS"
            ? isSome(batchNpsInfoOpt) &&
              handleCancelNpsBatch(batchNpsInfoOpt.value.batchId || "", remark)
            : isSome(batchInfoOpt) &&
              handleCancelBatch(batchInfoOpt.value.batchId || "", remark)
        }
      />
      <ApprovePopup
        show={showApprove}
        onHide={() => setShowApprovePopup(false)}
        onApprove={() =>
          props.activeTab === "NPS"
            ? isSome(batchNpsInfoOpt) &&
              handleApproveNpsBatch(batchNpsInfoOpt.value.batchId || "")
            : isSome(batchInfoOpt) &&
              handleApproveBatch(batchInfoOpt.value.batchId || "")
        }
      />
      <RejectPopup
        show={showTransactionCancel}
        onHide={() => setShowTransactionCancelPopup(false)}
        onReject={(remark) =>
          props.activeTab === "NPS"
            ? isSome(batchNpsInfoOpt) &&
              handleCancelNpsBatch(batchNpsInfoOpt.value.batchId || "", remark)
            : isSome(batchInfoOpt) &&
              handleCancelBatch(batchInfoOpt.value.batchId || "", remark)
        }
      />
      {/* Execute transaction */}
      <ExecutePopup
        show={showTransactionExecute}
        onHide={() => setShowTransactionExecutePopup(false)}
        onApprove={() =>
          props.activeTab === "NPS"
            ? handleExecuteTransactionNps()
            : handleExecuteTransaction()
        }
      />
    </>
  );
};

export default TransactionDetailHeading;
