/**
 * Ncaish Corporate apis
 * Api to manage corporate onboarding ,application and employee management
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CorporateCardWalletResponse {
  successful?: boolean;
  message?: string;
  accountNumber?: string;
  kitNumber?: string;
  status?: CorporateCardWalletResponse.StatusEnum;
}
export namespace CorporateCardWalletResponse {
  export type StatusEnum = "PENDING" | "ACTIVATED" | "BLOCKED";
  export const StatusEnum = {
    PENDING: "PENDING" as StatusEnum,
    ACTIVATED: "ACTIVATED" as StatusEnum,
    BLOCKED: "BLOCKED" as StatusEnum,
  };
}
