import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { useLoginWithToken } from "../../hooks/use-login-with-password";
import { Dispatch } from "../../state/dispatch";
import { setToken } from "../../state/slice/token.slice";
import { setLoginUser } from "../../state/slice/user.slice";
import { unWrapOption } from "../../utils/option.utils";
import { getNextScreenFromReview } from "../../utils/review.utils";
import { RoutePaths } from "../../utils/route.utils";
import { setIsAuthenticated } from "../app/app.slice";
import { allowedUids } from "../../app";
import {
  ProfileDispatch,
  setApprovedSuperAdmin,
} from "../../state/slice/profile.slice";
import { toast } from "react-toastify";

const LoginUsingToken: React.FC = () => {
  const { switchToken } = useParams<{ switchToken: string }>();
  const dispatch = useAppDispatch();
  const { mutateAsync: loginWithToken, isLoading } = useLoginWithToken();
  const navigate = useNavigate();

  const [error, setError] = useState<String>("");

  useEffect(() => {
    const run = async () => {
      const result = await loginWithToken({
        token: switchToken,
      });
      console.log(result);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          Dispatch.App.setLoginId(result.value.id);

          const allow = allowedUids.includes(
            unWrapOption(result.value.id, () => "")
          );
          ProfileDispatch.setApprovedSuperAdmin(allow);

          dispatch(
            setLoginUser({ mobile: unWrapOption(result.value.email, () => "") })
          );
          dispatch(
            setToken({
              accessToken: unWrapOption(result.value.access_token, () => ""),
              refreshToken: unWrapOption(result.value.refresh_token, () => ""),
              tokenExpiry: unWrapOption(result.value.expires_in, () => 0),
              scope: unWrapOption(result.value.scope, () => ""),
            })
          );
          dispatch(setIsAuthenticated(true));

          const roles = unWrapOption(result.value.roles, () => [""]);

          if (roles[0] === "ROLE_CORPORATE_APPLICANT") {
            await getNextScreenFromReview(navigate);
          } else if (roles[0] === "ROLE_CORPORATE_ADMIN") {
            navigate(RoutePaths.Onboarding);
          }
          navigate(RoutePaths.NavigateToOnboarding); //FORCE
          // const path = pipe(
          //   result.value.nextScreen,
          //   getOrElse(() => "/")
          // );
          // dispatch(setCurrentScreen(path));
        } else {
          toast.error("Login Failed");
          setError(unWrapOption(result.value.message, () => ""));
        }
      } else if (isFailure(result)) {
        setError("Login Failed");
      }
    };
    // as per praveen don't call token validation
    // run();
  }, []);

  if (error) {
    return <>{error}</>;
  }

  return <>Switching account....</>;
};

export default LoginUsingToken;
