import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../common/page-title";
import TableLoader from "../common/table-loader";
import MembershipDetailCard from "./membership-detail-card";
import MembershipDetailHeading from "./membership-detail-heading";

const MembershipDetails: React.FC = () => {
  const { cId, membershipId } = useParams<{
    cId: string;
    membershipId: string;
  }>();

  const detailLabel = ` > ${membershipId}`;
  const title = `Membership Details | ${membershipId}`;

  return (
    <div>
      <PageTitle title={title} />
      <div>
        {membershipId ? (
          <>
            <MembershipDetailHeading text={detailLabel} />
            <MembershipDetailCard membershipId={membershipId} cId={cId || ""} />
          </>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default MembershipDetails;
