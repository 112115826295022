import React, { useState } from "react";
import { TrialCountInfo } from "../../../api/admin/model/trialCountInfo";
import PageTitle from "../../common/page-title";
import Heading from "../heading";
import TrialSearch from "./trial-search";
import TrialStatusCount from "./trial-status-count";
import TrialTable from "./trial-table";

const Trial: React.FC = () => {
  const [curTbl, setCurTbl] = useState<TrialCountInfo | null>(null);

  return (
    <>
      <PageTitle title="Trials" />
      <Heading title="Trials" />
      <TrialSearch />
      <TrialStatusCount setCurTbl={setCurTbl} curTbl={curTbl} />
      {curTbl && <TrialTable curTbl={curTbl} />}
    </>
  );
};

export default Trial;
