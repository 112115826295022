import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap";

const DetaxPlusPopup: React.FC<{
  show?: boolean;
  onHide: (val: boolean) => void;
  showUser: () => void;
  addUser: () => void;
}> = ({ show, onHide, showUser, addUser }) => {
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [startDate1, setStartDate1] = useState<Date | null>(null);
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <ModalHeader closeButton>
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>Select Option</h4>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row row-cols-1 gy-3">
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 w-75"
                onClick={() => addUser()}
              >
                Add New Subscriptions
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary px-3 py-2 w-75"
                onClick={() => showUser()}
              >
                View Existing Subscriptions
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DetaxPlusPopup;
