import BrandLogo from "./components/brand-logo/brand-logo";
import { fromPublic } from "./utils/common.utils";
import logoLoader from "./output_Idbo5p.gif";

const NcashLogo = fromPublic("/images/icons/Brand-logo.svg");

const PageLoader = () => {
  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      {/* <BrandLogo
                src={NcashLogo}
                width="100"
                height="150"
                classname=" my-4"
                name="Ncash Logo"
            /> */}

      <div
        // className="spinner-border"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <img src={logoLoader} alt="Loading.." width="100" height="100" />
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default PageLoader;
