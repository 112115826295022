import { Field } from "formik";
import React from "react";

interface Props {
  devicetype: string;
}
const FormikCheckbox = ({ devicetype = "" }) => {
  return (
    <div
      className={
        devicetype === "desktop" ? "col-md-1 col-lg-1 p-0" : "col-1 p-0"
      }
    >
      <Field
        className="form-check-input  cardCheckbox"
        type="checkbox"
        value="One"
        aria-label="..."
        name="checked"
      />

      {/* <button type="submit">Submit</button> */}
    </div>
  );
};

export default FormikCheckbox;
